Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.apiMethodTypePost = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.reviewAPIEndpoint="bx_block_landingpage2/user_reviews/review";
exports.keyBenefitsAPIEndPoint="bx_block_landingpage2/key_benefits/benefit";
exports.myCommunityAPIEndPoint="bx_block_landingpage2/key_benefits/landing_page_content";
exports.carouselAPIEndPoint="bx_block_landingpage2/key_benefits/community_for_landing";
exports.descriptionsAPIEndPoint="bx_block_landingpage2/key_benefits/landing_benifits";
exports.numbersAPIEndPoint="bx_block_landingpage2/key_benefits/about_us";
exports.btnExampleTitle = "CLICK ME";
exports.apiMethodTypeGet="GET";
exports.getFeaturedContentEndPoint="bx_block_communityforum/without_token_all_communities";
exports.getIndividualContentEndPoint="bx_block_communityforum/without_token_show_communities";
exports.getZilionInfoBoxDetailsEndPoint="bx_block_landingpage2/about_users/main_users?sub_type=main&content_type=main";
exports.mainCarouselAPIEndPoint="bx_block_landingpage2/key_benefits/landing_page_video?position=main";

// Customizable Area End