
import React, { Component, Suspense } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'



import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from '../../components/src/HomeScreen';
const TopNav = React.lazy(() => import("../../components/src/TopNav"));
const InfoPage = React.lazy(() => import("../../blocks/info-page/src/InfoPageBlock"));
const AlertBlock = React.lazy(() => import("../../blocks/alert/src/AlertBlock.web"));
const Googlepayintegration = React.lazy(() => import("../../blocks/googlepayintegration/src/Googlepayintegration"));
const Likeapost2 = React.lazy(() => import("../../blocks/likeapost2/src/Likeapost2"));
const Splashscreen = React.lazy(() => import("../../blocks/splashscreen/src/Splashscreen"));
const Filteritems = React.lazy(() => import("../../blocks/filteritems/src/Filteritems"));
const Filteroptions = React.lazy(() => import("../../blocks/filteritems/src/Filteroptions"));
const Cfmessagingboard = React.lazy(() => import("../../blocks/cfmessagingboard/src/Cfmessagingboard"));
const Notificationsettings = React.lazy(() => import("../../blocks/notificationsettings/src/Notificationsettings"));
const NavigationMenu = React.lazy(() => import("../../blocks/navigationmenu/src/NavigationMenu"));
const PhoneNumberInput = React.lazy(() => import("../../blocks/mobile-account-registration/src/PhoneNumberInput"));
const AdditionalDetailForm = React.lazy(() => import("../../blocks/mobile-account-registration/src/AdditionalDetailForm"));
const Datasaver = React.lazy(() => import("../../blocks/datasaver/src/Datasaver"));
const EducationalUserProfile = React.lazy(() => import("../../blocks/educational-user-profile/src/EducationalUserProfile"));
const Groups = React.lazy(() => import("../../blocks/groups/src/Groups"));
const AdvancedSearch = React.lazy(() => import("../../blocks/advancedsearch/src/AdvancedSearch"));
const HelpCentre = React.lazy(() => import("../../blocks/helpcentre/src/HelpCentre"));
const HelpCentreQA = React.lazy(() => import("../../blocks/helpcentre/src/HelpCentreQA"));
const HelpCentreSub = React.lazy(() => import("../../blocks/helpcentre/src/HelpCentreSub"));
const Emailnotifications2 = React.lazy(() => import("../../blocks/emailnotifications2/src/Emailnotifications2"));
const Livestreaming = React.lazy(() => import("../../blocks/livestreaming/src/Livestreaming"));
const CameraAccess = React.lazy(() => import("../../blocks/cameraaccess/src/CameraAccess"));
const OTPInputAuth = React.lazy(() => import("../../blocks/otp-input-confirmation/src/OTPInputAuth"));
const Applepayintegration2 = React.lazy(() => import("../../blocks/applepayintegration2/src/Applepayintegration2"));
const DesktopNotifications = React.lazy(() => import("../../blocks/desktopnotifications/src/DesktopNotifications"));
const Contentmanagement3 = React.lazy(() => import("../../blocks/contentmanagement3/src/Contentmanagement3"));
const Nearbyfriends = React.lazy(() => import("../../blocks/nearbyfriends/src/Nearbyfriends"));
const Contentflag = React.lazy(() => import("../../blocks/contentflag/src/Contentflag"));
const Admanager = React.lazy(() => import("../../blocks/admanager/src/Admanager"));
const VisualAnalytics = React.lazy(() => import("../../blocks/visualanalytics/src/VisualAnalytics"));
const Privacysettings2 = React.lazy(() => import("../../blocks/privacysettings2/src/Privacysettings2"));
const Cfwhatsappintegration6 = React.lazy(() => import("../../blocks/cfwhatsappintegration6/src/Cfwhatsappintegration6"));
const Notifications = React.lazy(() => import("../../blocks/notifications/src/Notifications"));
const Location = React.lazy(() => import("../../blocks/location/src/Location"));
const Friendlist2 = React.lazy(() => import("../../blocks/friendlist2/src/Friendlist2"));
const Onlinebookingintegration = React.lazy(() => import("../../blocks/onlinebookingintegration/src/Onlinebookingintegration"));
const UserStatus = React.lazy(() => import("../../blocks/userstatus/src/UserStatus"));
const Abusedetector = React.lazy(() => import("../../blocks/abusedetector/src/Abusedetector"));
const Dataencryption = React.lazy(() => import("../../blocks/dataencryption/src/Dataencryption"));
const Customisableusersubscriptions = React.lazy(() => import("../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions"));
const SubscriptionDetails = React.lazy(() => import("../../blocks/customisableusersubscriptions/src/SubscriptionDetails"));
const CountryCodeSelector = React.lazy(() => import("../../blocks/country-code-selector/src/CountryCodeSelector"));
const Catalogue = React.lazy(() => import("../../blocks/catalogue/src/Catalogue"));
const UserProfileBasicBlock = React.lazy(() => import("../../blocks/user-profile-basic/src/UserProfileBasicBlock"));
const Pushnotifications = React.lazy(() => import("../../blocks/pushnotifications/src/Pushnotifications"));
const Scheduling = React.lazy(() => import("../../blocks/scheduling/src/Scheduling"));
const Communityforum3 = React.lazy(() => import("../../blocks/communityforum3/src/Communityforum3.web"));
const Settings2 = React.lazy(() => import("../../blocks/settings2/src/Settings2"));
const Share = React.lazy(() => import("../../blocks/share/src/Share"));
const Rolesandpermissions = React.lazy(() => import("../../blocks/rolesandpermissions/src/Rolesandpermissions"));
const Hashtags = React.lazy(() => import("../../blocks/hashtags/src/Hashtags"));
const ActivityFeed = React.lazy(() => import("../../blocks/activityfeed/src/ActivityFeed"));
const Favourites = React.lazy(() => import("../../blocks/favourites/src/Favourites"));
const AddFavourites = React.lazy(() => import("../../blocks/favourites/src/AddFavourites"));
const Followers = React.lazy(() => import("../../blocks/followers/src/Followers"));
const CreateComment = React.lazy(() => import("../../blocks/comments/src/CreateComment"));
const Sorting = React.lazy(() => import("../../blocks/sorting/src/Sorting"));
const Blockedusers = React.lazy(() => import("../../blocks/blockedusers/src/Blockedusers"));
const AddBlockeduser = React.lazy(() => import("../../blocks/blockedusers/src/AddBlockeduser"));
const PostCreation = React.lazy(() => import("../../blocks/postcreation/src/PostCreation"));
const Posts = React.lazy(() => import("../../blocks/postcreation/src/Posts"));
const PostDetails = React.lazy(() => import("../../blocks/postcreation/src/PostDetails"));
const SocialMediaAccountLoginScreen = React.lazy(() => import("../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen"));
const Cfintegrationwithxicommtechnology = React.lazy(() => import("../../blocks/cfintegrationwithxicommtechnology/src/Cfintegrationwithxicommtechnology"));
const Reviews = React.lazy(() => import("../../blocks/reviews/src/Reviews"));
const AddReview = React.lazy(() => import("../../blocks/reviews/src/AddReview"));
const SocialMediaAccountRegistrationScreen = React.lazy(() => import("../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen"));
const ForgotPassword = React.lazy(() => import("../../blocks/forgot-password/src/ForgotPassword"));
const ForgotPasswordOTP = React.lazy(() => import("../../blocks/forgot-password/src/ForgotPasswordOTP"));
const NewPassword = React.lazy(() => import("../../blocks/forgot-password/src/NewPassword"));
const ContactList = React.lazy(() => import("../../blocks/contactlist/src/ContactList"));
const Dashboard = React.lazy(() => import("../../blocks/dashboard/src/Dashboard"));
const Chat = React.lazy(() => import("../../blocks/chat/src/Chat"));
const ChatView = React.lazy(() => import("../../blocks/chat/src/ChatView"));
const StripeIntegration = React.lazy(() => import("../../blocks/stripepayments/src/StripeIntegration"));
const Invitefriends = React.lazy(() => import("../../blocks/invitefriends/src/Invitefriends"));
const TermsConditions = React.lazy(() => import("../../blocks/termsconditions/src/TermsConditions"));
const TermsConditionsDetail = React.lazy(() => import("../../blocks/termsconditions/src/TermsConditionsDetail"));
const TermsConditionsUsers = React.lazy(() => import("../../blocks/termsconditions/src/TermsConditionsUsers"));
const Contentmoderation = React.lazy(() => import("../../blocks/contentmoderation/src/Contentmoderation"));
const Projecttemplates = React.lazy(() => import("../../blocks/projecttemplates/src/Projecttemplates"));
const Searchhistory = React.lazy(() => import("../../blocks/searchhistory/src/Searchhistory"));
const BulkUploading = React.lazy(() => import("../../blocks/bulkuploading/src/BulkUploading"));
const GoogleCalendarSync = React.lazy(() => import("../../blocks/googlecalendarsync/src/GoogleCalendarSync"));
const Trending2 = React.lazy(() => import("../../blocks/trending2/src/Trending2"));
const MobileAccountLoginBlock = React.lazy(() => import("../../blocks/mobile-account-login/src/MobileAccountLoginBlock"));
const LandingPage = React.lazy(() => import("../../blocks/landingpage/src/LandingPage.web"));
const LandingPageCreator = React.lazy(() => import("../../blocks/landingpage/src/LandingPageCreator.web"));
const LandingPageMember = React.lazy(() => import("../../blocks/landingpage/src/LandingPageMember.web"));
const Analytics = React.lazy(() => import("../../blocks/analytics/src/Analytics"));
const PhotoLibrary = React.lazy(() => import("../../blocks/photolibrary/src/PhotoLibrary"));
const Customadvertisements = React.lazy(() => import("../../blocks/customadvertisements/src/Customadvertisements"));
const Categoriessubcategories = React.lazy(() => import("../../blocks/categoriessubcategories/src/Categoriessubcategories"));
const Languageoptions = React.lazy(() => import("../../blocks/languageoptions/src/Languageoptions"));
const Cftroubleticket = React.lazy(() => import("../../blocks/cftroubleticket/src/Cftroubleticket"));
const Maps = React.lazy(() => import("../../blocks/maps/src/Maps"));
const Carousel = React.lazy(() => import('../../blocks/emailnotifications2/src/Carousel.web'))
const WelcomeScreen = React.lazy(() => import('../../blocks/emailnotifications2/src/WelcomeScreen'))
const CommunityPreview = React.lazy(() => import('../../blocks/communityforum3/src/CommunityPreview.web'))
import LogIn from '../../blocks/emailnotifications2/src/LogIn.web';
import SignUp from '../../blocks/emailnotifications2/src/SignUp.web'
import {ResetPasswordWithStyles} from '../../blocks/forgot-password/src/ResetPassword/ResetPassword.web'
import {ForgotPasswordWebWithStyles} from '../../blocks/forgot-password/src/ForgotPassword/ForgotPassword.web'
import Communitylandingpage from '../../blocks/contentmanagement3/src/Communitylandingpage.web'
import Setup from "../../blocks/emailnotifications2/src/Setup.web"
import {AboutUsWithStyles} from '../../blocks/landingpage/src/AboutUs.web'
import HomePageMember from '../../blocks/landingpage/src/HomePageMember.web'
import DiscoverPageMember from '../../blocks/landingpage/src/DiscoverPageMember.web'
const routeMap = {
  WelcomeScreen: {
    component: LandingPage,
    path: "/",
    exact: true
  },
  SignUp: {
    component: SignUp,
    path: "/SignUp/:pathParam?"
  },
  SignUpMember: {
    component: SignUp,
    path: "/SignUp?type=member"
  },
  SignUpCreator: {
    component: SignUp,
    path: "/SignUp?type=creator"
  },
  ResetPassword: {
    component: ResetPasswordWithStyles,
    path: "/ResetPassword/:resetToken"
  },
  Setup:{
    component: Setup,
    path:"/Setup"
  },
  LogIn: {
    component: LogIn,
    path: "/LogIn"
  },
  Carousel: {
    component: Carousel,
    path: "/Carousel"
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWebWithStyles,
    path: "/forgotPasswordWeb"
  },

  Googlepayintegration: {
    component: Googlepayintegration,
    path: "/Googlepayintegration"
  },
  Likeapost2: {
    component: Likeapost2,
    path: "/Likeapost2"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Cfmessagingboard: {
    component: Cfmessagingboard,
    path: "/Cfmessagingboard"
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: "/Notificationsettings"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Datasaver: {
    component: Datasaver,
    path: "/Datasaver"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Groups: {
    component: Groups,
    path: "/Groups"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  HelpCentre: {
    component: HelpCentre,
    path: "/HelpCentre"
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: "/HelpCentreQA"
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: "/HelpCentreSub"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Livestreaming: {
    component: Livestreaming,
    path: "/Livestreaming"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Applepayintegration2: {
    component: Applepayintegration2,
    path: "/Applepayintegration2"
  },
  DesktopNotifications: {
    component: DesktopNotifications,
    path: "/DesktopNotifications"
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  Nearbyfriends: {
    component: Nearbyfriends,
    path: "/Nearbyfriends"
  },
  Contentflag: {
    component: Contentflag,
    path: "/Contentflag"
  },
  Admanager: {
    component: Admanager,
    path: "/Admanager"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Privacysettings2: {
    component: Privacysettings2,
    path: "/Privacysettings2"
  },
  Cfwhatsappintegration6: {
    component: Cfwhatsappintegration6,
    path: "/Cfwhatsappintegration6"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  Friendlist2: {
    component: Friendlist2,
    path: "/Friendlist2"
  },
  Onlinebookingintegration: {
    component: Onlinebookingintegration,
    path: "/Onlinebookingintegration"
  },
  UserStatus: {
    component: UserStatus,
    path: "/UserStatus"
  },
  Abusedetector: {
    component: Abusedetector,
    path: "/Abusedetector"
  },
  Dataencryption: {
    component: Dataencryption,
    path: "/Dataencryption"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Communityforum3: {
    component: Communityforum3,
    path: "/Communityforum3"
  },
  CommunityPreview: {
    component: CommunityPreview,
    path: "/CommunityPreview"
  },
  Communitylandingpage : {
    component: Communitylandingpage,
    path:"/Communitylandingpage"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Hashtags: {
    component: Hashtags,
    path: "/Hashtags"
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: "/ActivityFeed"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  Followers: {
    component: Followers,
    path: "/Followers"
  },
  Comment: {
    component: Comment,
    path: "/Comment"
  },
  CreateComment: {
    component: CreateComment,
    path: "/CreateComment"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Blockedusers: {
    component: Blockedusers,
    path: "/Blockedusers"
  },
  AddBlockeduser: {
    component: AddBlockeduser,
    path: "/AddBlockeduser"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Cfintegrationwithxicommtechnology: {
    component: Cfintegrationwithxicommtechnology,
    path: "/Cfintegrationwithxicommtechnology"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  ContactList: {
    component: ContactList,
    path: "/ContactList"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ChatView: {
    component: ChatView,
    path: "/ChatView"
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: "/StripeIntegration"
  },
  Invitefriends: {
    component: Invitefriends,
    path: "/Invitefriends"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Contentmoderation: {
    component: Contentmoderation,
    path: "/Contentmoderation"
  },
  Projecttemplates: {
    component: Projecttemplates,
    path: "/Projecttemplates"
  },
  Searchhistory: {
    component: Searchhistory,
    path: "/Searchhistory"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  GoogleCalendarSync: {
    component: GoogleCalendarSync,
    path: "/GoogleCalendarSync"
  },
  Trending2: {
    component: Trending2,
    path: "/Trending2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  LandingPageCreator: {
    component: LandingPageCreator,
    path: "/LandingPageCreator"
  },
  LandingPageMember: {
    component: LandingPageMember,
    path: "/LandingPageMember"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  Customadvertisements: {
    component: Customadvertisements,
    path: "/Customadvertisements"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Languageoptions: {
    component: Languageoptions,
    path: "/Languageoptions"
  },
  Cftroubleticket: {
    component: Cftroubleticket,
    path: "/Cftroubleticket"
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  AboutUs: {
    component: AboutUsWithStyles,
    path: "/aboutUs",
  },
  HomePageMember:{
    component:HomePageMember,
    path:"/Member/Home"
  },
  DiscoverPageMember:{
    component:DiscoverPageMember,
    path:"/Member/Discover"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <Suspense fallback='Loading...'>
        <View style={{ height: '100%' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </Suspense>
    );
  }
}

export default App;
