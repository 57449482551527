const { baseURL } = require("../../../framework/src/config");
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emailnotifications2";
exports.labelBodyText = "emailnotifications2 Body";
exports.apiMethodTypeGet="GET";
exports.btnExampleTitle = "CLICK ME";
exports.loginRegisterAPIEndPoint ="bx_block_login/login";
exports.AddaccountDetails="";
exports.GetAccountDetails=""
exports.AccountDetails =""
exports.LanguagesApi ="bx_block_language_options/languages_list"
exports.accountsAPiEndPointWeb="account_block/accounts";
exports.apiMethodTypeAddDetail="PUT";
exports.getCountryListWeb="account_block/accounts/country_code_and_flags";
exports.APIUrl = baseURL
exports.apiMethodPost = "POST";
exports.apiContentType = "application/json";
exports.termsConditionsAPIEndPoint="bx_block_privacy_settings/terms_and_conditions";
exports.privacyAPIEndPoint="bx_block_privacy_settings/privacy_policies";
// Customizable Area End