import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleFooterNavigation = (type: 'aboutUs' | 'faq' | 'forCreator' | 'contactUs') => {
    const navigateTo = {
      'aboutUs': 'AboutUs',
      'faq': 'Faq',
      'forCreator': 'LandingPageCreator',
      'contactUs': 'ContactUs'
    }

    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), navigateTo[type] ? navigateTo[type] : navigateTo.aboutUs);

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }
  // Customizable Area End
}
