import React from "react";
import {
    // Customizable Area Start
    Box, Typography, withStyles, createStyles
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { zilion } from "../assets";
// Customizable Area End

// Customizable Area Start
interface Props {
    cardName?: string;
    category?: string;
    description?: boolean;
    classes?: any;
    image: string;
    serialNo: number;
}
// Customizable Area End

class Card extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box className={this.props.classes.cardParent}>
                <img
                    src={this.props.image}
                    alt="community"
                    className={this.props.classes.cardImg}
                />
                {this.props.description && (
                    <Box className={this.props.classes.cardContent}>
                        <Typography className={this.props.classes.cardNumber}>{this.props.serialNo}</Typography>
                        <img
                            src={zilion}
                            alt="community logo"
                            className={this.props.classes.cardLogo}
                        />
                        <Box className={this.props.classes.cardDesc}>
                            <Typography className={this.props.classes.cardTitle}>
                                {this.props.cardName || ""}
                            </Typography>
                            <Typography className={this.props.classes.cardSubTitle}>
                                {this.props.category || ""}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            // Customizable Area End
        );
    }
}

const styles = () =>
    createStyles({
        cardParent: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#000",
            padding:"0 4px",
            width: '100%',
            "&:hover": {
                cursor: "pointer",
            },
        },
        cardImg: {
            width: 'auto',
            height: "130px",
            borderRadius: "16px",
            objectFit:'cover'
        },
        cardContent: {
            display: "flex",
            marginTop: "13px",
            alignItems: "center",
        },
        cardNumber: {
            color: "#868686",
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "32px",
            marginLeft: "5px",
        },
        cardLogo: {
            width: "40px",
            height: "40px",
            marginLeft: "16px",
            borderRadius: "88px",
            border: "1px solid rgba(0, 0, 0, 0.16)",
        },
        cardDesc: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "8px",
        },
        cardTitle: {
            color: "#fff",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 500,
        },
        cardSubTitle: {
            color: "#6D6D6D",
        },
    });

const CardWithStyles = withStyles(styles)(Card);
export default CardWithStyles;
