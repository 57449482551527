//@ts-nocheck
import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    IconButton,
    createStyles,
    Theme,
    withStyles,
    Modal,
    createTheme,
    ThemeProvider
    // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import RestPasswordController, {
    Props,
} from "./RestPasswordController.web";
import { Logo, Email_Success,Close,activeTick,inactiveTick } from "../assets";
import Carousel from "../../../emailnotifications2/src/Carousel.web";
import Visibility from "@material-ui/icons/Visibility";
import {ArrowBackIos,Error} from '@material-ui/icons';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loader from "../../../../components/src/Loader.web"
export const configJSON = require("../config");
const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
    },
  });
// Customizable Area End

export default class ResetPassword extends RestPasswordController {

    constructor(props: Props) {

        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    onResetPassword () {
        return (
          <Formik
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            data-test-id="FormikID"
            validationSchema={Yup.object({
              newPassword: Yup.string()
                .min(8, " ")
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/, " ")
                .required(" "),
              confirmPassword: Yup.string()
                .oneOf(
                  [Yup.ref("newPassword"), null],
                  configJSON.passwordsMustMatch
                )
                .required(configJSON.pleaseConfirmYourPassword),
            })}
            onSubmit={(values) => {
              this.resetPasswordData(values);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography className={this.props.classes?.label}>
                      New Password
                    </Typography>

                    <Field
                      data-test-id="newPassword"
                      type={this.state.showPassword ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      variant="filled"
                      as={TextField}
                      fullWidth
                      onChange={(e) => {
                        formik.setFieldValue("newPassword", e.target.value);
                        this.onInputChange();
                      }}
                      placeholder="Type Strong Password"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                    <Typography className={this.props.classes?.password}>
                      Password must contain at least:
                    </Typography>
                    <Box className={this.props.classes?.passwordCheckParent}>
                      <Box className={this.props.classes?.passwordCheckMiddle}>
                        <Box className={this.props.classes?.passwordCheckChild}>
                          <img
                            className={this.props.classes?.tickImage}
                            src={
                              RegExp(/[A-Z]/).test(formik.values?.newPassword)
                                ? activeTick
                                : inactiveTick
                            }
                            alt="check fail/pass icon"
                          />
                          <Typography className={this.props.classes?.subLabel}>
                            1 capital letter
                          </Typography>
                        </Box>

                        <Box className={this.props.classes?.passwordCheckChild}>
                          <img
                            className={this.props.classes?.tickImage}
                            src={
                              RegExp(/[a-z]/).test(formik.values?.newPassword)
                                ? activeTick
                                : inactiveTick
                            }
                            alt="check fail/pass icon"
                          />
                          <Typography className={this.props.classes?.subLabel}>
                            1 lowercase letter
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={this.props.classes?.passwordCheckMiddle}>
                        <Box className={this.props.classes?.passwordCheckChild}>
                          <img
                            className={this.props.classes?.tickImage}
                            src={
                              RegExp(/\d/).test(formik.values?.newPassword)
                                ? activeTick
                                : inactiveTick
                            }
                            alt="check fail/pass icon"
                          />
                          <Typography className={this.props.classes?.subLabel}>
                            1 number
                          </Typography>
                        </Box>
                        <Box className={this.props.classes?.passwordCheckChild}>
                          <img
                            src={
                              formik.values?.newPassword.length >= 8
                                ? activeTick
                                : inactiveTick
                            }
                            alt="check fail/pass icon"
                            className={this.props.classes?.tickImage}
                          />
                          <Typography className={this.props.classes?.subLabel}>
                            Minimum 8 character length
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={this.props.classes?.label}>
                      Confirm Password
                    </Typography>

                    <Field
                      data-test-id="confirmPassword"
                      type={this.state.confirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Type Strong Password"
                      variant="filled"
                      as={TextField}
                      fullWidth
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <Typography className={this.props.classes?.required}>
                            <Error className={this.props.classes?.errorImg} />
                            <span className={this.props.classes?.errMsg}>
                              {formik.errors.confirmPassword}
                            </span>
                          </Typography>
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                          >
                            {this.state.confirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {this.state.err && (
                      <Typography
                        data-test-id="errMsg"
                        className={this.props.classes?.required}
                      >
                        <Error className={this.props.classes?.errorImg} />
                        <span className={this.props.classes?.errMsg}>
                          {this.state.errMsg}
                        </span>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      data-test-id="updateBtn"
                      className={
                        Object.keys(formik.errors).length > 0 ||
                        formik.isSubmitting ||
                        !formik.touched.newPassword ||
                        !formik.touched.confirmPassword
                          ? this.props.classes?.disableBtn
                          : this.props.classes?.updateBtn
                      }
                      size="large"
                    >
                      Update Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        );
    }

    // Customizable Area End

    render() {

        return (

            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container style={{ height: "100%" }}>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className={this.props.classes?.carousel}
                >
                    <Carousel navigation={undefined} id={""} />

                </Grid>


                <Grid
                    item xs={12} sm={6} md={6} style={{ paddingBottom: "30px" }}>

                    <Box className={this.props.classes?.resetBlock} >
                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"} p={3} >
                            <img src={Logo} alt="" style={{ width: "64px" }} />
                        </Box>
                        <Box className={this.props.classes?.mainSec}>  
                                <ArrowBackIos onClick={this.Back} style={{ cursor: "pointer"}} />
                                <Typography variant="h4" className={this.props.classes?.title}>Reset Password</Typography>
                                <Typography variant="body2" className={this.props.classes?.desc}>Create a strong password. Must contain an Uppercase, lower case and 1 number.</Typography>
                            <Box mt={5}>
                                {this.onResetPassword()}

                            </Box>

                        </Box>
                    </Box>

                </Grid>
                <Modal
                    open={this.state.open}
                    onClose={this.onHandleClose}
                >
                    <Box className={this.props.classes?.container}>
                    <Box style={{ textAlign: "right" }}>
                        <img
                            src={Close}
                            onClick={this.onHandleClose}
                            className={this.props.classes?.close}
                            alt="close"
                        ></img>
                    </Box>
                    <Box className={this.props.classes?.modalContent}>
                            <img
                                src={Email_Success}
                                alt="success icon"
                                className={this.props.classes?.emailImg}
                            ></img>
                            <Typography className={this.props.classes?.modTitle}>
                                Password Reset Successfully
                            </Typography>
                            <Typography className={this.props.classes?.modalDesc}>
                                You will be redirected to the Login page in a few seconds..
                            </Typography>
                            <Box className={this.props.classes?.loaderParent}>
                                <Loader loading={true} />
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    resetBlock: {
      display: "flex",
      flexDirection: "column",
    },
    mainSec: {
      width: "61%",
      margin: "auto",
      marginTop: "30px",
    },
    updateBtn: {
      marginTop: "46px",
      background: "#3E01B9",
      borderRadius: "10px",
      width: "100%",
      height: "64px",
      color: "#E8E8E8",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      textTransform: "capitalize",
    },
    input: {
      marginTop: "10px",
      width: "100%",
    },
    title: {
      lineHeight: "2",
      fontSize: "36px",
      color: "#323548",
      fontWeight: 700,
      marginTop: "40px",
    },
    disableBtn: {
      marginTop: "46px",
      background: "#E8E8E8",
      borderRadius: "10px",
      width: "100%",
      height: "64px",
      color: "#C7C7C7",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      textTransform: "capitalize",
    },
    label: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      color: "#323548",
      lineHeight: "35px",
    },
    password: {
      color: "#585858",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0.123px",
      marginTop:"8px"
    },
    subLabel: {
      color: "#848484",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0.123px",
    },
    desc: {
      color: "#323548",
      fontWeight: 400,
      fontSize: "18px",
      marginTop: "24px",
    },
    required: {
        marginTop: "10px",
        display:"flex",
        alignItems:"center",
        gap:"5px",
    },
    errorImg:{
        color:"#EC554D",
        fontSize:"18px"
    },
    passwordCheckParent: {
      display: "flex",
      gap: "10px",
      marginTop: "8px",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    passwordCheckMiddle: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "16px",
    },
    passwordCheckChild: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    tickImage: {
      width: "24px",
      height: "24px",
    },
    container: {
      background: "#E8E8E8",
      color: "#323548",
      width: "646px",
      border: "none",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
    },
    close: {
      width: "40px",
      marginTop: "20px",
      marginRight: "20px",
      cursor: "pointer",
    },
    emailImg: { width: "167px" },
    errIcon: {
      color: "red",
      fontSize: "80px",
    },
    errMsg:{
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: "14px",
        color:"#000",
        '&:first-letter':{
            textTransform:"capitalize"
        }
    },
    modalContent: {
      marginBottom: "64px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px 111px 0px 111px",
      textAlign: "center",
    },
    modTitle: {
      fontSize: "32px",
      fontWeight: 600,
    },
    modalDesc: {
      fontSize: "20px",
      fontWeight: 400,
      marginTop: "20px",
      color: "#8D8F98",
    },
    loaderParent: {
      marginTop: "30px",
    },
    "@media (max-width: 900px)": {
      container: {
        width: "60%",
      },
    },

    "@media (max-width: 700px)": {
      title: {
        fontSize: "24px",
      },
      desc: {
        fontSize: "16px",
      },
      label: {
        fontSize: "14px",
      },
      container: {
        width: "70%",
      },
      modTitle: {
        fontSize: "24px",
        fontWeight: 600,
      },
      modalDesc: {
        fontSize: "16px",
      },
      modalContent: {
        padding: "0px 12px 0px 12px",
      },
    },
    "@media (max-width: 400px)": {
      carousel: {
        display: "none",
      },
      container: {
        width: "100%",
        height: "100%",
        top: "40%",
        left: "0%",
        borderRadius: "20px 20px 0 0",
        transform: "none",
      },
    },
  });
const ResetPasswordWithStyles = withStyles(styles)(ResetPassword);
export { ResetPasswordWithStyles };
// Customizable Area End
