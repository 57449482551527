export const userProfile = require("../assets/user-profile.png");
export const Logo = require("../assets/Zilion.png");
export const Srarch = require("../assets/Search.png")
export const Communities = require("../assets/Communities.png");
export const Events = require("../assets/Events.png");
export const Home = require("../assets/Home.png");
export const Messages = require("../assets/Messages.png");
export const SettingsImg = require("../assets/SettingsImg.png");
export const Dropdown = require("../assets/Dropdown.png")
export const Analytics = require("../assets/Analytics.png")
export const Vector = require("../assets/Vector.png");
export const notificationIcon = require("../assets/notificationIcon.png");
export const noNotificationIcon = require("../assets/noNotificationIcon.png");




