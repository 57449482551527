import React from "react";
import {
    // Customizable Area Start
    Grid, Typography, Box
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardWithStyles from "./Card";
// Customizable Area End

// Customizable Area Start
const responsive = {
    largeDesktop: {
        breakpoint: { max: 3600, min: 2000 },
        items: 8,
        slidesToSlide: 7, // optional, default to 1.
    },
    largeDesktop1: {
        breakpoint: { max: 1999, min: 1470 },
        items: 6,
        slidesToSlide: 5, // optional, default to 1.
    },
    desktop: {
        breakpoint: { max: 1469, min: 1210 },
        items: 5,
        slidesToSlide: 4, // optional, default to 1.1360
    },
    smallDesktop: {
        breakpoint: { max: 1209, min: 1024 },
        items: 4,
        slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
    },
};



interface Props {
    ContentHeader: string,
    classes?: any
    carouselData: []
}

// Customizable Area End

export default class ContentCarousel extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.Slider = this.Slider.bind(this)
        // Customizable Area End
    }
    // Customizable Area Start

    Slider = ({ description }: { description: boolean }) => {
        return (
            <Box className="parent" >
                <Carousel
                    responsive={responsive}
                    autoPlay={false}
                    containerClass="carousel-container"
                    sliderClass={this.props.classes?.slider}
                    itemClass={this.props.classes?.itemClass}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    partialVisible={true}
                    infinite={false}
                    dotListClass="custom-dot-list-style"
                >
                    {this.props.carouselData.map((data: any, index) => {
                        return (
                            <Box
                                key={index}
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CardWithStyles
                                    cardName={data?.attributes?.name}
                                    category={data?.attributes?.category_id}
                                    image={((data?.attributes?.cover_pic) ?? '').toString()}
                                    description={description}
                                    serialNo={index + 1}
                                />
                            </Box>
                        );
                    })}
                </Carousel>
            </Box>
        );
    };
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Grid
                container
                style={{
                    paddingBottom: "56px",
                    paddingLeft: "60px",
                }}
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#fff",
                        borderTop: "1px solid #1A1A1A",
                        paddingTop: "24px",
                    }}
                >
                    <Typography
                        variant="h5"
                        component="h5"
                        style={{
                            fontSize: "18px",
                            lineHeight: "24px",
                            letterSpacing: "0.123px",
                            textTransform: "capitalize",
                            fontWeight: 600,
                            fontStyle: 'normal'
                        }}
                    >
                        {this.props.ContentHeader}
                    </Typography>
                    <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: "4px" }} />
                </Grid>
                <Grid item xs={12} style={{ height: "204px", marginTop: "24px" }}>
                    <this.Slider description={this.props.ContentHeader === "Featured Community"} />
                </Grid>
            </Grid>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const webStyle = {
    slider: {
        gap: "16px",
    },
    itemClass: {
        maxWidth: "fit-content",
    },
};
// Customizable Area End
