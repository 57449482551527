
import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    Grid,
    FormHelperText,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SetupController, { Props } from "./SetupController.web";
// import Carousel from "./Carouselcomponent/Carousel.web";
import Carousel from "./Carousel.web";
import {
    Logo,
    CrossIcon,
    GroupProfileAdd,
    GroupIcon,
    CheckedIcon,
    UncheckedIcon,
    ErrorSymbol

} from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Modal from "@material-ui/core/Modal";
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import './Carousel.css'

// Customizable Area End

export default class Setup extends SetupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    onSetup() {
        return (
            <div >
                <Grid className="InputFields" container>
                    {
                        this.state.phonestatus ?
                            (
                                <Grid item xs={12}>

                                    <label style={webStyle.label} htmlFor="Phone">
                                        Your Phone Number
                                    </label>
                                    <PhoneInput
                                        country={"us"}
                                        //   value={value}
                                        // name="phonenumber"
                                        // autoFormat={true}
                                        disabled={this.state.phonestatus}
                                        inputClass="react-tel"
                                        value={this.state.phonenumber}
                                        test-Id="phonenumber"
                                        onChange={(e: any) => this.handlePhonenumber(e)}
                                    // style={webStyle.Inputfields}
                                    // inputClass="w"
                                    />
                                    {
                                        this.state.phonenumberError &&
                                        <FormHelperText style={webStyle.helperText}><span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span>  {this.state.phonenumberErrorMsg}</FormHelperText>
                                    }

                                </Grid>
                            )
                            :
                            (
                                <Grid item xs={12}>
                                    <label style={webStyle.label} htmlFor="email">
                                        Your Email Address
                                    </label>
                                    <Input disabled={this.state.emailStatus} test-Id="emailinput" value={this.state.email} onChange={(e) => this.handlefieldschange(e)} fullWidth style={webStyle.emailInputField} placeholder="Write your email here" name="email" />
                                    {
                                        this.state.emailError &&
                                        <FormHelperText style={webStyle.helperText}><span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span> {this.state.emailErrorMsg}</FormHelperText>
                                    }
                                </Grid>
                            )

                    }


                    <Grid item xs={12}>
                        <Box>
                            <label style={webStyle.label}>Add Profile Picture</label>
                            <Box style={webStyle.imgBlock}>

                                <Button disableRipple component="label">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            margin: "0px 0px",
                                        }}
                                    >
                                        <Box >
                                            {
                                                this.state.selectedCoverphoto !== "" ?
                                                    <img src={URL.createObjectURL(this.state.selectedCoverphoto.Img)} style={webStyle.iconstyle} />
                                                    :
                                                    <PersonOutlineRoundedIcon style={webStyle.iconstyle} color="primary" />
                                            }

                                        </Box>
                                        <Button
                                            style={webStyle.uploadphotobtn}
                                            onClick={this.handleOpen}
                                            test-Id="handleImage"
                                        >
                                            {this.state.selectedCoverphoto !== "" ? (<div style={{ display: "flex", alignItems: "center" }} >Edit <EditOutlinedIcon style={{ width: "16px", marginLeft: "5px" }} /></div>) : "Upload +"}

                                        </Button>
                                    </div>
                                </Button>
                                <Box>

                                </Box>
                            </Box>
                        </Box>
                        {this.state.ImageError &&

                            <FormHelperText style={webStyle.helperText}> <span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span>  {this.state.ImageErrorMsg}</FormHelperText>
                        }
                    </Grid>


                    {
                        this.state.emailStatus ?
                            (
                                <Grid item xs={12}>

                                    <label style={webStyle.label} htmlFor="Phone">
                                        Your Phone Number
                                    </label>
                                    <PhoneInput
                                        country={"us"}
                                        //   value={value}
                                        // name="phonenumber"
                                        // autoFormat={true}
                                        disabled={this.state.phonestatus}
                                        inputClass="react-tel"
                                        value={this.state.phonenumber}
                                        test-Id="phonenumber"
                                        onChange={(e: any) => this.handlePhonenumber(e)}
                                    // style={webStyle.Inputfields}
                                    // inputClass="w"
                                    />
                                    {
                                        this.state.phonenumberError &&
                                        <FormHelperText style={webStyle.helperText}><span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span>  {this.state.phonenumberErrorMsg}</FormHelperText>
                                    }

                                </Grid>
                            )
                            : (
                                <Grid item xs={12}>
                                    <label style={webStyle.label} htmlFor="email">
                                        Your Email Address
                                    </label>
                                    <Input disabled={this.state.emailStatus} test-Id="emailinput" value={this.state.email} onChange={(e) => this.handlefieldschange(e)} fullWidth style={webStyle.emailInputField} placeholder="Write your email here" name="email" />
                                    {
                                        this.state.emailError &&
                                        <FormHelperText style={webStyle.helperText}><span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span> {this.state.emailErrorMsg}</FormHelperText>
                                    }
                                </Grid>
                            )
                    }





                    <Grid item xs={12}>

                        <FormControl fullWidth>
                            <label style={webStyle.label} htmlFor="Gender">
                                Gender
                            </label>
                            <NativeSelect
                                style={!this.state.genderError ? webStyle.Inputfields : webStyle.Inputfieldswithoutvalue}
                                value={this.state.gender}
                                onChange={(e: any) => this.handlefieldschange(e)}
                                name="gender"
                                test-Id="genderselect"

                            >
                                <option value="" disabled selected hidden>Select gender</option>
                                <option value="Male" style={webStyle.optionStyle}>Male</option>
                                <option value="Female" style={webStyle.optionStyle}>Female</option>
                                <option value="Prefernottosay" style={webStyle.optionStyle}>Prefer not to say</option>
                            </NativeSelect>
                        </FormControl>

                        {this.state.genderError &&

                            <FormHelperText style={webStyle.helperText}> <span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span>  {this.state.genderErrorMsg}</FormHelperText>
                        }


                    </Grid>
                    <Grid item xs={12}>

                        <FormControl fullWidth>
                            <label style={webStyle.label} htmlFor="age_group">
                                Age Group
                            </label>
                            <NativeSelect
                                style={!this.state.age_groupError ? webStyle.Inputfields : webStyle.Inputfieldswithoutvalue}
                                value={this.state.age_group}
                                onChange={(e: any) => this.handlefieldschange(e)}
                                name="age_group"
                                test-Id="age_group"
                            >
                                <option value="" disabled selected hidden>Select age group</option>
                                {this.state.Age_Group_values.map((data: any, index: any) => {
                                    return (
                                        <option key={index} value={data.age_show} style={webStyle.optionStyle}>{data.age_show}</option>
                                    )
                                })}

                            </NativeSelect>

                            {this.state.age_groupError &&
                                <FormHelperText style={webStyle.helperText}> <span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span> {this.state.age_groupErrorMsg}</FormHelperText>

                            }
                        </FormControl >


                    </Grid>
                    <Grid item xs={12}>

                        <FormControl fullWidth>
                            <label style={webStyle.label} htmlFor="language">
                                Language
                            </label>
                            <NativeSelect
                                style={!this.state.languageError ? webStyle.Inputfields : webStyle.Inputfieldswithoutvalue}
                                value={this.state.language}
                                onChange={(e: any) => this.handlefieldschange(e)}
                                name="language"
                                test-Id="languageselect"
                            >
                                <option value="" disabled selected hidden>Select language</option>
                                {this.state.languageList.map((data: any, index: any) => {
                                    return (
                                        <option value={data} style={webStyle.optionStyle}>{data}</option>
                                    )
                                })}

                            </NativeSelect>
                        </FormControl>
                        {this.state.languageError &&

                            <FormHelperText style={webStyle.helperText}><span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span>  {this.state.languageErrorMsg}</FormHelperText>
                        }


                    </Grid>
                    <Grid item xs={12}>

                        <FormControl fullWidth>
                            <label style={webStyle.label} htmlFor="Country">
                                Country
                            </label>
                            <NativeSelect
                                style={!this.state.CountryError ? webStyle.Inputfields : webStyle.Inputfieldswithoutvalue}
                                value={this.state.Country}
                                onChange={(e: any) => this.handlefieldschange(e)}
                                name="Country"
                                test-Id="Countryselect"
                            >
                                <option value="" disabled selected hidden>Select country</option>
                                {this.state.countryList.map((data: any, index: any) => {
                                    return (
                                        <option key={index} value={data.attributes.name} style={webStyle.optionStyle}>{data.attributes.name}</option>
                                    )
                                })}

                            </NativeSelect>
                        </FormControl>
                        {this.state.CountryError &&

                            <FormHelperText style={webStyle.helperText}> <span><img src={ErrorSymbol} width={"18px"} height={"18px"} /></span> {this.state.CountryErrorMsg}</FormHelperText>
                        }


                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "50px" }}>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            style={{ gap: "50px" }}
                        >
                            <Button

                                style={
                                    this.state.buttonstatus === false ? webStyle.disable : webStyle.signbtn
                                }
                                size="large"
                                onClick={this.postData}
                                disabled={!this.state.buttonstatus}
                            >
                                {this.state.buttonstatus === true ? "Finish Setup" : "Continue"}
                            </Button>
                        </Box>
                        <FormHelperText style={{ color: "red", fontSize: "12px" }}>{this.state.errorResponse}</FormHelperText>

                    </Grid>
                </Grid>


            </div>


        )
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Grid container style={{ height: "100%" }}>
                <Grid item xs={6}>
                    <Carousel navigation={undefined} id={""} accountType={this.state.accountType} />
                </Grid>
                <Grid item xs={6} style={{ paddingBottom: "20px" }}>
                    <Box style={webStyle.Loginblock}>
                        <Box style={webStyle.LoginblockLogo} >
                            <img src={Logo} alt="" style={{ width: "72px", height: "85px" }} />
                        </Box>


                        <Box width="80%" margin="auto">

                            <>
                                <Box

                                >
                                    <ArrowBackIosIcon
                                        onClick={this.Back}
                                        style={{ cursor: "pointer", alignSelf: "end" }}
                                    />
                                    {/* <img src={Logo} alt="" style={{ width: "64px" }} /> */}
                                </Box>
                                <Box >
                                    <Typography variant="h4" style={webStyle.title}>
                                        Almost there
                                    </Typography>
                                    <Typography style={webStyle.description}>
                                        Let’s get to know you
                                    </Typography>
                                </Box>
                                <Box mt={5}>
                                    <Box>{this.onSetup()}</Box>
                                </Box>
                            </>
                        </Box>
                    </Box>
                </Grid>



                <Modal
                    open={this.state.Open}
                    onClose={this.handleClose}
                    disableScrollLock={false}
                    style={{ overflowY: "scroll" }}
                >
                    <div>
                        <div style={webStyle.SelectCoverImage}>
                            <div
                                style={{
                                    backgroundColor: "#3618B1",
                                    borderRadius: "10px",
                                    padding: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "10px",
                                        alignItems: "center"

                                    }}
                                >
                                    <Typography style={webStyle.heading}>
                                        Select Profile Picture
                                    </Typography>
                                    <img
                                        src={CrossIcon}
                                        alt="cross"
                                        onClick={this.handleClose}
                                        style={{ width: "40px", height: "40px" }}
                                    ></img>
                                </div>
                                <div style={webStyle.uploadBase}>
                                    {
                                        this.state.selectedCoverphoto !== "" ?
                                            <div style={webStyle.ModalSelectedCoverPhoto}>
                                                <img src={URL.createObjectURL(this.state.selectedCoverphoto.Img)} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} />
                                            </div>
                                            :
                                            <div style={webStyle.ModalContentImg}>
                                                <img
                                                    src={GroupIcon}
                                                    style={{
                                                        height: "60px",
                                                        width: "60px",
                                                    }}
                                                    alt="profile"
                                                />
                                            </div>
                                    }


                                </div>

                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {this.state.coverPhotos.map((data: any, index: any) => {
                                        return (
                                            <div
                                                test-Id="updcover"
                                                onClick={(e: any) =>
                                                    this.handleSelectImage(data)}
                                                key={index}
                                                style={{
                                                    width: "31%",
                                                    height: "200px",
                                                    margin: "10px 1%",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    src={
                                                        data.Img instanceof Blob
                                                            ? URL.createObjectURL(data.Img)
                                                            : ""
                                                    }
                                                    width={"100%"}
                                                    height={"100%"}
                                                    style={{ borderRadius: "10px", position: "relative" }}
                                                ></img>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "80%",
                                                        right: "10%",
                                                    }}
                                                >

                                                    {this.state.selectedCoverphoto.Img == data.Img ? (
                                                        <img src={CheckedIcon} width={28} height={28}></img>
                                                    ) : (
                                                        <img
                                                            src={UncheckedIcon}
                                                            width={28}
                                                            height={28}
                                                        ></img>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <Box >
                                        <Box
                                            display="flex"
                                            style={{ width: "230px", height: "200px" }}
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            gridGap="5px"
                                        >
                                            <Button
                                                disableRipple
                                                component="label"

                                                test-Id="updcoverimage"
                                            // onClick={() => this.handleUpdateCoverimage}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        margin: "0px 0px",
                                                        width: "100%",
                                                    }}
                                                >

                                                    <div>
                                                        <img
                                                            src={GroupProfileAdd}
                                                            alt="Group"
                                                            width={84}
                                                            height={84}
                                                        ></img>
                                                    </div>


                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        <input
                                                            accept="image/png, image/jpg, image/jpeg,"
                                                            type="file"
                                                            hidden
                                                            test-Id="btn4"
                                                            name="getimage"


                                                            onChange={(e: any) => this.handleAddCoverImage(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                            </div>

                            {/* <div style={{ textAlign: "center" }}>
                                <Button
                                    test-Id="setcover-Img"
                                    variant="contained"
                                    title="Set Cover Image"
                                    color="primary"
                                    onClick={() =>
                                        this.setState({ Open: false, coverPhotos: [] })
                                    }
                                    disableElevation
                                    style={{
                                        margin: "20px 0px",
                                        padding: "10px 20px",
                                        backgroundColor: "#3E01B9",
                                    }}
                                >
                                    Set Cover Image
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </Modal>

            </Grid>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    menulist: {
        borderRadius: "10px"
    },
    LoginblockLogo: {
        display: "flex",
        justifyContent: "end",
        marginTop: "42px",
        marginRight: "42px"
    },
    helperText: {
        color: "#000000",
        fontSize: "12px",
        display: "flex",
        alignItems: "center"
    },
    ModalSelectedCoverPhoto: {
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        margin: "30px 0px"
    },
    ModalContentImg: {
        height: "200px",
        width: "200px",
        backgroundColor: "#FFF",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px 0px"

    },
    imgBlock: {
        background: "#E8E8E8",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        padding: "20px",
        borderRadius: "5px",
    } as React.CSSProperties,
    iconstyle: {
        background: "rgba(62, 1, 185, 0.06)",
        borderRadius: "50px",
        padding: "5px",
        width: "60px",
        height: "60px",
        margin: "20px",

    },
    uploadBase: {
        backgroundColor: "#210063",
        borderRadius: "5px",
        width: "auto",
        height: "20%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    } as const,
    heading: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
    } as React.CSSProperties,
    uploadbtn: {
        background: "#3E01B9",
        borderRadius: "10px",
        width: "100%",
        height: "40px",
        color: "#E8E8E8",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "capitalize",
    } as React.CSSProperties,
    condition: {
        textDecoration: "underline",
        color: "#3E01B9",
        cursor: "pointer",
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "14px",
    },
    closeError: {
        color: "red",
        fontSize: "50px",
    },
    disable: {
        background: "#E8E8E8",
        borderRadius: "10px",
        width: "100%",
        height: "64px",
        color: "#C7C7C7",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "capitalize",
    } as const,
    screen: {
        display: "flex",
    },
    remember: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
    },
    accountLink: {
        textAlign: "center",
        color: " rgba(50, 53, 72, 0.5)",
        fontSize: "16px",
        lineHeight: "30px",
    } as React.CSSProperties,

    Loginblock: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    } as React.CSSProperties,
    signbtn: {
        background: "#3E01B9",
        borderRadius: "10px",
        width: "100%",
        height: "64px",
        color: "#E8E8E8",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "capitalize",
    } as React.CSSProperties,
    label: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        marginTop: "10px",
        fontWeight: 400,
        fontSize: "16px",
        color: "#6C707C",
        lineHeight: "35px",
    },
    uploadphotobtn: {
        backgroundColor: "#3E01B9",
        color: "#FFFFFF",
        borderRadius: "5px",
        padding: "5px 10px",
        fontWeight: 700,
        width: "22vw",
        gap: "8px",
        textTransform: "capitalize",
        fontFamily: "Montserrat",
    } as const,

    SetupLogo: {
        position: "absolute",
        top: "38px",
        right: "63px",
        height: "64px",
        width: "54px",
    } as React.CSSProperties,
    SetupHeading: {
        lineHeight: "2",
        fontSize: "35px",
        fontWeight: 700,
    },

    required: {
        color: "red",
        marginTop: "10px",
    } as React.CSSProperties,
    input: {
        marginTop: "10px",
    },
    title: {
        lineHeight: "2",
        fontSize: "36px",
        fontFamily: "Montserrat",
        color: "#323548",
        fontWeight: 700,
    },
    description: {
        color: "#323548",
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "18px",
        opacity: "50%"
    },
    emailInputField: {
        backgroundColor: "#FFF",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        height: "64px",
        paddingLeft: "10px",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "0px"
    },
    Inputfields: {

        backgroundColor: "#FFF",
        // border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "0px"
    } as React.CSSProperties,
    Inputfieldswithoutvalue: {
        opacity: "50%",
        border: "1px solid red",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: 400,
        marginTop: "0px",
        backgroundColor: "#F7F7F7"
    },
    SelectCoverImage: {
        top: "20%",
        position: 'absolute',
        color: "#FFFFFF",
        width: "60%",
        margin: "0 20%",

        borderRadius: "10px",
        padding: "20px",
    } as const,
    optionStyle: {
        color: "#050505",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "100px",
    } as React.CSSProperties,
};



// Customizable Area End 