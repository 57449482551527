import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  showPassword: boolean,
  confirmPassword: boolean,
  open: boolean,
  err:boolean,
  errMsg:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

class RestPasswordController extends BlockComponent<Props, S, SS
> {
  // Customizable Area Start
  resetPasswordId:any;
  timer:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start 
    this.subScribedMessages = [

      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)

      // Customizable Area End
    ];
    // Customizable Area End
    this.state = {

      // Customizable Area Start
      showPassword: false,
      confirmPassword: false,
      open: false,
      err:false,
      errMsg:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



  // Customizable Area Start

  onHandleOpen = (startTimer:boolean) => {
    this.setState({ open: true });
    if(startTimer){
      this.timer=setTimeout(()=>{
        this.props.navigation.navigate('LogIn')
      },3000)
    }
   
  }
  
  onInputChange=()=>{
    this.setState({err:false,errMsg:""})
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ confirmPassword: !this.state.confirmPassword });
  };

  onHandleClose = () => {
    if(this.timer){
      clearTimeout(this.timer)
    }
    this.setState({ open: false});
   
  }

  Back = () => {
    this.props.navigation.goBack()
  };

  resetPasswordData = async (values: any) => {
    
    const header = {
      'Content-Type': configJSON.forgotPasswordAPiContentType
    }
    const token=this.props.navigation.getParam("resetToken")
    const httpBody={
      newpassword: values.newPassword,
      token:token
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordByEmailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.resetPasswordId) {
          if(responseJson.error){
            this.setState({err:true,errMsg:responseJson.error});
          }else{
            this.onHandleOpen(true)
          }
          
        }

      }
    }
  }
  // Customizable Area End
}

export default RestPasswordController