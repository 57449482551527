import React from "react";

import {
    // Customizable Area Start
    Typography,
    Grid,
    Box
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import KeyBenefitsController, { Props } from "./KeytBenefitsController.web";

// Customizable Area End
interface IndividualI {
    id: number, heading: string, description: string, image: string
}
// Customizable Area Start
const theme = createTheme({
    typography: {
        fontFamily: ["Montserrat"].join(",")
    }
});




// Customizable Area End

export default class KeyBenefits extends KeyBenefitsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.IndividualUI = this.IndividualUI.bind(this);

        // Customizable Area End
    }

    // Customizable Area Start

    IndividualUI({ id, heading, description, image }: IndividualI) {
        return <Grid item xs={12} sm={6} md={3} key={id}>
            <img style={webStyle.benefitsImage} src={image} alt={heading} />
            <Typography variant="h5" style={webStyle.benefitsHeading}>
                {heading}
            </Typography>
            <Typography variant="body1" style={webStyle.benefitsBody}>
                {description}
            </Typography>
        </Grid>
    }


    // Customizable Area End

    render() {

        return (
            <Box style={webStyle.wrapper}>
                <ThemeProvider theme={theme}>
                    <Grid container
                        style={{
                            ...webStyle.benefitsSection,
                            padding: this.props.padding ? this.props.padding : "46px 88px 50px 88px"
                        }}>
                        <Grid item xs={12} style={{ textAlign: this.props.isCenter ? 'center' : 'left' }}>
                            <Typography variant="h3" style={webStyle.heading}  >Key benefits for Joining</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={4} style={{ paddingTop: "52px" }}>
                            {this.state.keyBenefits.map((data: IndividualI, index: number) => {
                                return <this.IndividualUI id={data.id} heading={data.heading} image={data.image} description={data.description} key={index} />
                            })}
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Box>
        );
    }

}


// Customizable Area Startheading
const webStyle = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        backgroundColor: "#1C1C1C",
    },
    heading: {
        color: "#E8E8E8",
        fontFamily: "Montserrat",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0.123px",
        textTransform: "capitalize"
    } as React.CSSProperties,
    benefitsSection: {
        color: "#fff",
        maxWidth: '1280px'
    },
    benefitsImage: {
        width: "88px",
        height: "88px",
        border: "0.852px solid rgba(0, 0, 0, 0.16)"
    },
    benefitsHeading: {
        fontSize: "18px",
        marginTop: "18px",
        marginBottom: "18px"
    },
    benefitsBody: {
        fontSize: "14px",
        opacity: "0.5"
    },
};
// Customizable Area End
