import React from "react";

import {

  // Customizable Area Start
  Box,
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import MobileStepper from '@material-ui/core/MobileStepper';
import CarouselController, {
  Props,
} from "./CarouselController.web";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {
  Carousel1,
  Carousel2,
  Carousel3,
  Carousel4,
  Carousel5,
  Carousel6,
  creatorImage1,
  creatorImage2,
  creatorImage3,
  creatorTitle1,
  creatorTitle2,
  creatorTitle3
} from './assets'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// @ts-ignore
import './Carousel.css'
// @ts-ignore 
import ReactCardCarousel from "react-card-carousel";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },

  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const tutorialSteps = [

  {
    imgPath: Carousel1,
    key: 'image1'
  },
  {
    imgPath: Carousel2,
    key: 'image2'
  },
  {
    imgPath: Carousel3,
    key: 'image3'
  },

];

const tutorialSteps2 = [
  { imgPath: Carousel4 },
  { imgPath: Carousel5 },
  { imgPath: Carousel6 }
]


const creatorTutorialSteps = [
  {
    imgPath: creatorImage1,
    key: 'image1'
  },
  {
    imgPath: creatorImage2,
    key: 'image2'
  },
  {
    imgPath: creatorImage3,
    key: 'image3'
  }

];

const creatorTutorialSteps2 = [
  { imgPath: creatorTitle1, },
  { imgPath: creatorTitle2, },
  { imgPath: creatorTitle3, },

]


// Customizable Area End





export default class Carousel extends CarouselController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  // Customizable Area End

  render() {
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
    const accountTypeCreator: boolean = this.props.accountType === 'creator';
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box style={webStyle.carouselBox2}>


            <Box style={webStyle.firstcarousel}>
              <Box style={{ marginTop: "5%" }}>
                <AutoPlaySwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={this.state.activeStep}
                  onChangeIndex={this.handleStepChange}
                  enableMouseEvents
                  interval={3000}
                >
                  {(accountTypeCreator ? creatorTutorialSteps2 : tutorialSteps2).map((step, index) => (
                    <Box key={index}>
                      <img style={webStyle.img2} src={step.imgPath} />
                    </Box>
                  ))}

                </AutoPlaySwipeableViews>

              </Box>

              <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: "column", marginTop: "4px", width: "100%", height: "340px", position: "relative" }}>
                <ReactCardCarousel
                  autoplay={true}
                  autoplay_speed={3000}
                  spread={"wide"}
                  disable_box_shadow={true}
                >
                  {(accountTypeCreator ? creatorTutorialSteps : tutorialSteps).map((step, index) => (
                    <Box style={{ marginLeft: "-420px" }} key={step.key}>
                      {(
                        <div key={index} style={{ margin: "0px 680px", width: "100%" }}>
                          <img
                            style={
                              index === this.state.activeStep
                                ? webStyle.img1
                                : { ...webStyle.img1, ...webStyle.blurredImage }
                            }
                            src={step.imgPath}
                          />
                        </div>
                      )}
                    </Box>
                  ))}

                </ReactCardCarousel>
                <MobileStepper
                  style={webStyle.stepper2}
                  variant="dots"
                  steps={3}
                  position="static"
                  activeStep={this.state.activeStep} backButton={undefined} nextButton={undefined}
                />
              </Box>
            </Box>

          </Box>

        </ThemeProvider>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  carouselcard: {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,


  carouselimage: {
    position: "relative",
    marginTop: "10%"
  } as React.CSSProperties,


  carouselBox: {
    display: 'flex',
  } as React.CSSProperties,



  blurredImage: {
    filter: "blur(5px)",
  },

  img1: {
    marginLeft: "0%",
    width: '100%',
    maxWidth: "400px",
    objectFit: "cover",
    borderRadius: '16px'
  } as React.CSSProperties,

  firstcarousel: {
    overflow: "hidden",

  } as React.CSSProperties,

  carouselBox2: {
    background: 'linear-gradient(180deg, #3F02B9 -77.76%, #000 99.72%)',
    height: "100%",
    position: "relative",

  } as React.CSSProperties,
  img2: {

    marginLeft: "12%",
    width: '75%',
    objectFit: "cover"
  } as React.CSSProperties,



  stepper2: {
    background: "transparent",
    justifyContent: 'center',
    alignItems: "center",
    overflow: "hidden",
    left: "45%",
    marginTop: "50px"

  } as React.CSSProperties,
  carouseldataStyle2: {
    marginTop: "30%",
    width: "100%",
  } as React.CSSProperties,
  dataStyle2: {

    display: "flex",
    width: "500px",
    justifyContent: "center",
    alignSelf: "center",
  } as React.CSSProperties,

};
// Customizable Area End




