//@ts-nocheck
import React from "react";

import {
    // Customizable Area Start
    Modal, Box,
    Button,
    Typography,
    TextField,
    Grid,
    createStyles,
    Theme,
    IconButton,
    withStyles,
    // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ForgotPasswordController, {
    Props,
} from './ForgotController.web';
import Carousel from "../../../emailnotifications2/src/Carousel.web";
import { Logo,Close,Email,Edit } from '../assets'
import 'react-phone-input-2/lib/style.css';
import {ArrowBackIos,Error} from '@material-ui/icons';
// Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {


    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <Grid
                container style={{ height: "100%" }}>

                <Grid item xs={12} sm={6} md={6} className={this.props.classes?.carouselParent}>
                    <Carousel
                        navigation={undefined}
                        id={""}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ paddingBottom: "20px" }}>

                    <Box className={this.props.classes?.forgotBlock} >
                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"} p={3} >
                            <img src={Logo} alt="" style={{ width: "64px" }} />
                        </Box>
                        <Box className={this.props.classes?.mainSection}>
                                 <ArrowBackIos onClick={this.Back} style={{ cursor: "pointer"}} />
                                 <Typography variant="h4" className={this.props.classes?.title}>Forgot Password</Typography>
                                 <Typography variant="body2" className={this.props.classes?.description}>Enter your email for the verification process</Typography>
                                <Box mt={5}>
                                    <Formik
                                    data-test-id="FormikID"
                                    initialValues={{
                                        email: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email("Invalid email").required("Email is required"),
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        this.forgetData(values, setSubmitting);
                                    }}
                                    >
                                    {(formik) => (
                                        <form onSubmit={formik.handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                                style={{ gap: "10px" }}
                                            >
                                                <label className={this.props.classes?.label} htmlFor="email">
                                                Email Address
                                                </label>
                                                <Field
                                                data-test-id="inputEmail"
                                                type="email"
                                                name="email"
                                                variant="filled"
                                                placeholder="Write your Email Here"
                                                as={TextField}
                                                fullWidth
                                                onChange={(e) => {
                                                    formik.setFieldValue("email", e.target.value);
                                                    this.onHandleChange();
                                                }}
                                                helperText={
                                                    formik.errors.email && (
                                                    <Typography className={this.props.classes?.required}>
                                                        <Error className={this.props.classes?.errorImg} />
                                                        <span className={this.props.classes?.errorText}>
                                                        {formik.errors.email}
                                                        </span>
                                                    </Typography>
                                                    )
                                                }
                                                InputProps={{ disableUnderline: true }}
                                                />
                                                {this.state.error && (
                                                <Typography
                                                    data-test-id="errorMsg"
                                                    className={this.props.classes?.required}
                                                >
                                                    <Error className={this.props.classes?.errorImg} />
                                                    <span className={this.props.classes?.errorText}>
                                                    {this.state.errorMessage}
                                                    </span>
                                                </Typography>
                                                )}
                                            </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                            <Box
                                                display={"flex"}
                                                style={{ gap: "10px", textAlign: "center" }}
                                                flexDirection={"column"}
                                                justifyContent={"center"}
                                            >
                                                <Button
                                                type="submit"
                                                className={
                                                    Object.keys(formik.errors).length > 0 ||
                                                    formik.isSubmitting ||
                                                    !formik.touched.email
                                                    ? this.props.classes?.disable
                                                    : this.props.classes?.sendEmailButton
                                                }
                                                data-test-id="sendEmailBtn"
                                                size="large"
                                                >
                                                Send Email
                                                </Button>
                                            </Box>
                                            </Grid>
                                        </Grid>
                                        </form>
                                    )}
                                    </Formik>
                                </Box>
                        </Box>
                    </Box>

                </Grid>
                <Modal
                    open={this.state.open}
                    onClose={this.onHandleClose}
                    data-test-id="verifyPopup"
                >
                    <Box className={this.props.classes?.modalContainer}>
                        <Box style={{ textAlign: "right" }}>
                            <img
                                src={Close}
                                onClick={this.onHandleClose}
                                className={this.props.classes?.crossIcon}
                                alt="close"
                            ></img></Box>
                        <Box className={this.props.classes?.content}>
                            <img
                                src={Email}
                                alt="email icon"
                                className={this.props.classes?.emailIcon}
                            ></img>
                            <Typography className={this.props.classes?.modalTitle}>
                                Check your email address
                            </Typography>
                            <Typography className={this.props.classes?.modalDescription}>
                                An email has been sent. To reset your password, an email has been
                                sent. Please verify your email
                            </Typography>
                            <Box className={this.props.classes?.emailContainer}>
                                <Typography className={this.props.classes?.emailAddress}>
                                    {this.state.maskedEmail}
                                </Typography>
                                <IconButton onClick={this.onHandleClose}>
                                    <img src={Edit} alt="edit icon"></img>
                                </IconButton>
                            </Box>
                            <Typography className={this.props.classes?.modalFooter}>
                                Problem receving mail?. Make sure spelling is correct or check
                                spam mails.
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            </Grid>
            

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        screen: {
            display: "flex",
        },

        forgotBlock: {
            display: "flex",
            flexDirection: "column",
        },
        mainSection:{
            width:"61%",
            margin:"auto",
            marginTop:"30px"
        },
        sendEmailButton: {
            marginTop: "264px",
            background: "#3E01B9",
            color: "#E8E8E8",
            fontFamily: 'Montserrat',
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            textTransform: "capitalize",
            borderRadius: "10px",
            width: "100%",
            height: "64px",

        },
        label: {
            fontFamily: 'Montserrat',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            color: "#323548"

        },
        disable: {
            background: "#E8E8E8",
            borderRadius: "10px",
            marginTop: "264px",
            width: "100%",
            height: "64px",
            color: "#C7C7C7",
            fontFamily: 'Montserrat',
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            textTransform: "capitalize"
        },
        required: {
            marginTop: "10px",
            display:"flex",
            alignItems:"center",
            gap:"5px",
        },
        errorText:{
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: "14px",
            color:"#000",
            '&:first-letter':{
                textTransform:"capitalize"
            }
        },
        errorImg:{
            color:"#EC554D",
            fontSize:"18px"
         },
        input: {
            marginTop: '10px',
            width: "100%"
        },
        title: {
            fontSize: "36px",
            fontFamily: 'Montserrat',
            color: "#323548",
            fontWeight: 700,
            marginTop:"40px"
        },
        description: {
            color: "#323548",
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize: "18px",
            marginTop: "24px"

        },
        modalContainer: {
            background: "#E8E8E8",
            color: "#323548",
            fontFamily: "Montserrat",
            width: "670px",
            border: "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform:"translate(-50%,-50%)",
            borderRadius: "24px",
            display:"flex",
            flexDirection:"column"
        },
        crossIcon: {
            width: "40px",
            marginTop: "20px",
            marginRight: "20px",
            cursor:"pointer"
        },
        emailIcon: { width: "106px" },
        errorIcon: {
            color: "red",
            fontSize: "80px"
        },
        errorMsg:{
            color: "red",
            marginTop: "10px",
            '&:first-letter':{
                textTransform:"capitalize"
            }
        },
        content: {
            marginBottom: "70px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "0px 90px 0px 90px",
        },
        modalTitle: {
            fontSize: "32px",
            fontWeight: 700,
        },
        modalDescription: {
            fontSize: "20px",
            fontWeight: 400,
            marginTop: "20px",
            opacity: 0.5,
        },
        emailContainer: {
            display: "flex",
            padding: "8px",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            flexShrink: 0,
            marginTop: "30px",
            borderRadius: "12px",
            background: "#F0F0F0",
        },
        emailAddress: {
            color: "#989AA3",
            textAlign: "right",
            fontSize: "20px",
            fontWeight: 400,
        },
        modalFooter: {
            marginTop: "28px",
            fontSize: "18px",
            fontWeight: 400,
        },
        "@media (max-width: 900px)": {
            modalContainer: {
                width: "60%",
            },
            sendEmailButton: {
                marginTop: "150px",

            },
            disable: {
                marginTop: "150px",

            },
        },

        "@media (max-width: 700px)": {
            title: {
                fontSize: "24px"
            },
            description: {
                fontSize: "16px"

            },
            label: {
                fontSize: "14px"

            },
            modalContainer: {
                width: "70%",
            },
            modalTitle: {
                fontSize: "24px",
                fontWeight: 600,
            },
            modalDescription: {
                fontSize: "16px",
            },
            content: {
                padding: "0px 12px 0px 12px",
            },
            emailContainer: {
                padding: "2px",
                gap: "0.5px",
            },
            emailAddress: {
                fontSize: "14px",
            },
            modalFooter: {
                fontSize: "14px",
            },
        },
        "@media (max-width: 400px)": {
            carouselParent: {
                display: "none"
            },
            modalContainer: {
                width: "100%",
                height: "100%",
                top: "40%",
                left: "0%",
                borderRadius: "20px 20px 0 0",
                transform:"none"
            },
        },
    });
export const ForgotPasswordWebWithStyles = withStyles(styles)(ForgotPasswordWeb);
// Customizable Area End
