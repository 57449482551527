import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export const formatNumber=(n:number)=>{
  let formattedNum="";let text=""
  if(!n){
    return {
      formattedNum: '5',
      text: formattedNum+'+'
    }
  }
  switch(true){
    case n < 1e3:
      formattedNum=n.toString();
      text=formattedNum+"+"
      break;
    case n >= 1e3 && n < 1e6:
      formattedNum=+(n / 1e3).toFixed(1) + "k";
      text=formattedNum+"+"
      break;
    case n >= 1e6 && n < 1e9:
      formattedNum=+(n / 1e6).toFixed(1) + "M"
      text="millions of"
      break;
    case n >= 1e9 && n < 1e12:
      formattedNum=+(n / 1e9).toFixed(1) + "B";
      text="billions of"
      break;
    case n >= 1e12:
      formattedNum=+(n / 1e12).toFixed(1) + "T";
      text="trillions of"
      break;
    default:
      break;
  }
  return {
    formattedNum,
    text
  }
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  moneySec:[],
  connectSec:[],
  fansSec:[],
  mainSec:[],
  members:number,
  communities:number,
  views:number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state={
      moneySec:[],
      connectSec:[],
      fansSec:[],
      mainSec:[],
      members:0,
      communities:0,
      views:0
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.descriptionsApiId) {
          this.handleDescResp(responseJson);
        }

        if (apiRequestCallId === this.numbersApiId) {
          this.handleNumResp(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  descriptionsApiId:any
  numbersApiId:any
  async componentDidMount(): Promise<void> {
    this.getDescriptions();
    this.getNumbersData();
  }

  getDescriptions = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.descriptionsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.descriptionsAPIEndPoint}?landing_type=about`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNumbersData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.numbersApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.numbersAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDescResp = (responseJson: any) => {
    if (responseJson.data) {
      const aboutUs=responseJson.data;
      const money=aboutUs.filter((item:any)=>item.attributes.sub_type==="creators_make_money")
      const connect=aboutUs.filter((item:any)=>item.attributes.sub_type==="like_minded")
      const fans=aboutUs.filter((item:any)=>item.attributes.sub_type==="creators_and_fans")
      const mainSection=aboutUs.filter((item:any)=>item.attributes.sub_type==="easy_to_create")

      this.setState({
        moneySec:money,
        connectSec:connect,
        fansSec:fans,
        mainSec:mainSection
      })
    }
  };

  handleNumResp = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        members:responseJson.members,
        communities:responseJson.communities,
        views:responseJson.views
      })
      
    }
  };

  OnSignUp = (type?: 'creator' | 'member') => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), type === 'creator' ? "SignUpCreator" : "SignUpMember");

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  } 

  // Customizable Area End
}
