export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const ornament = require("../assets/ornament.png");
export const Z = require("../assets/View_union.png");
export const linearDown = require("../assets/LinearDown.png");
export const welcome  = require("../assets/welcome.mp4")
export const active_check =  require("../assets/active_check.png")
export const unactive_check =  require("../assets/unactive_check.png")
export const GroupProfileAdd = require("../assets/groupProfileIcon.png")
export const CrossIcon = require("../assets/Cross.png")
export const Logo = require('../assets/Zilion.png')
export const Success = require('../assets/Vector.png')
export const Carousel1 =require("../assets/carousel1.jpg")
export const Carousel2 =require("../assets/carousel2.jpg")
export const Carousel3 =require("../assets/carousel3.jpg")
export const Carousel4 =require("../assets/carousel_4.png")
export const Carousel5 =require("../assets/carousel_5.png")
export const Carousel6 =require("../assets/carousel_6.png")
export const GroupIcon = require("../assets/GroupIcon.png")
export const CheckedIcon=require("../assets/CheckedIcon.png")
export const UncheckedIcon = require("../assets/UncheckedIcon.png")
export const ErrorSymbol = require("../assets/Errorsymbol.png")

export const Email = require("../assets/email_icon.png");
export const Close = require("../assets/cross_icon.png");
export const Edit = require("../assets/edit_icon.png");
export const ErrorInfoIcon = require("../assets/errorInfoIcon.png");
export const EmailVerifiedIcon = require("../assets/EmailVerified.png");
export const creatorImage1 =require("../assets/creatorImage1.jpg")
export const creatorImage2 =require("../assets/creatorImage2.jpg")
export const creatorImage3 =require("../assets/creatorImage3.jpg")
export const creatorTitle1 =require("../assets/creatorTitle1.png")
export const creatorTitle2 =require("../assets/creatorTitle2.png")
export const creatorTitle3 =require("../assets/creatorTitle3.png")