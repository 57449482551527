import {
    Cardimage1,
    Cardimage2,
    Cardimage3,
    Cardimage4,
    Cardimage5,
    Cardimage6,
    Cardimage7,
    Cardimage8,
    Cardimage9,
    FavIcon1,
    FavIcon2,
    FavIcon3,
    FavIcon4,
    FavIcon5,
    FavIcon6,
    FavIcon7,
    FavIcon8,
    FavIcon9,
    anime1,
    anime2,
    anime3,
    anime4,
    anime5,
    anime6,
    anime7,
    anime8,
    anime9,
    cover1,
    cover2,
    cover3,
    cover4,
    cover5,
    cover6,
    cover7,
    cover8,
} from '../src/assets'

export const PrimaryData = [
    {
        title: "Mika Finance",
        type: "Comedy",
        image: Cardimage1,
        favicon: FavIcon1
    },
    {
        title: "Mika Finance2",
        type: "Comedy1",
        image: Cardimage2,
        favicon: FavIcon2
    },
    {
        title: "Mika Finance3",
        type: "Comedy2",
        image: Cardimage3,
        favicon: FavIcon3
    },
    {
        title: "Mika Finance4",
        type: "Comedy3",
        image: Cardimage4,
        favicon: FavIcon1
    },
    {
        title: "Mika Finance5",
        type: "Comedy4",
        image: Cardimage5,
        favicon: FavIcon5
    },
    {
        title: "Mika Finance6",
        type: "Comedy5",
        image: Cardimage6,
        favicon: FavIcon6
    },
    {
        title: "Mika Finance7",
        type: "Comedy6",
        image: Cardimage7,
        favicon: FavIcon7
    },
    {
        title: "Mika Finance8",
        type: "Comedy7",
        image: Cardimage8,
        favicon: FavIcon8
    },
    {
        title: "Mika Finance9",
        type: "Comedy8",
        image: Cardimage9,
        favicon: FavIcon9
    },
]


export const animeData = [
    {
        title: "Mika Finance1",
        image: anime1,
    },
    {
        title: "Mika Finance2",
        image: anime2,
    },
    {
        title: "Mika Finance3",
        image: anime3,
    },
    {
        title: "Mika Finance4",
        image: anime4,
    },
    {
        title: "Mika Finance5",
        image: anime5,
    },
    {
        title: "Mika Finance6",
        image: anime6,
    },
    {
        title: "Mika Finance7",
        image: anime7,
    },
    {
        title: "Mika Finance8",
        image: anime8,
    },
    {
        title: "Mika Finance9",
        image: anime9,
    },
]
export const secondaryData = [
    {
        title: "How Yoga Can Hwertwertth How Yoga Can Help with Low... ",
        image: cover1,
        favicon: FavIcon2,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Yoga Yoga wertewertw with How Can Help with Low... ",
        image: cover2,
        favicon: FavIcon2,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Ywetwert wert Help Yoga Can with How Help with Low... ",
        image: cover3,
        favicon: FavIcon3,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Yoga Can Help with How Yogatw ertw ert with Low... ",
        image: cover4,
        favicon: FavIcon4,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Yoga Can Help with How Yoga Can Hwertw th Low... ",
        image: cover5,
        favicon: FavIcon5,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Yoga Can Help asd asdf Yoga Can Help with ewr... ",
        image: cover6,
        favicon: FavIcon6,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Yoga Can Help wertwert wertYoga Can Help erte Low... ",
        image: cover7,
        favicon: FavIcon7,
        user: 'Hosted By Mika Finance'
    },
    {
        title: "How Yoga Can Help with How Yoga sdf Help with Low... ",
        image: cover8,
        favicon: FavIcon8,
        user: 'Hosted By Mika Finance'
    }
]