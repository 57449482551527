import React from "react";

import {
  // Customizable Area Start
  Typography,
  Box,
  createStyles,
  withStyles,
  Grid,
  createTheme,
  ThemeProvider,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ReviewsController, { Props } from "./ReviewsController";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
      h2: {
        color: "#E8E8E8",
        textAlign: "center",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0.123px",
        textTransform: "capitalize",
      },
      h4: {
        fontSize: "13px",
        color: "#E8E8E8",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "18px",
        letterSpacing: "0.092px",
        textTransform: "capitalize",
      },
      subtitle1: {
        color: "#E8E8E8",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.123px",
        opacity: 0.5,
      },
      body1: {
        color: "#E8E8E8",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0.092px",
      },
      body2: {
        color: "#E8E8E8",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "15px",
        letterSpacing: "0.092px",
        opacity: 0.7,
      },
    },
});

const responsive = {
  largeDesktop: {
    breakpoint: { max: 3600, min: 1990 },
    items: 4,
  },
  largeDesktop1: {
    breakpoint: { max: 1989, min: 1470 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1469, min: 1210 },
    items: 2,
  },
  smallDesktop: {
    breakpoint: { max: 1209, min: 972 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 971, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
  },
};

interface Review{
  id: number,
  name: string,
  username: string,
  review_type: string,
  review: string,
  rating: any,
  created_at: string,
  image:string    
}

interface CarouselCardProps{
  classes:any;
  data:Review
}

const CarouselCard: React.FunctionComponent<CarouselCardProps> = (props) =>{
  const { classes,data } = props;
  return (
    <Box className={classes.carouselCard}>
          <Box className={classes.cardHeadingParent}>
            <img
              src={data.image}
              className={classes.cardProfileImg}
              alt=""
            />
            <Box className={classes.cardHeading}>
              <Typography variant="h4">{data.name}</Typography>
              <Typography variant="body2">@{data.username}</Typography>
            </Box>
          </Box>
          <Tooltip title={data.review} arrow>
          <Typography variant="body1" className={classes.cardBody}>
            {data.review}
          </Typography>
          </Tooltip>
          <Typography variant="h4">{data.created_at}</Typography>
        </Box>
  )
}
// Customizable Area End

export default class Reviews extends ReviewsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
    // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box className={this.props?.classes.reviewsParent}>
          <Box className={this.props?.classes.reviewsHeading}>
            <Typography variant="h2" style={{ textAlign: "left" }}>
              What do they say about Zilion
            </Typography>
            <Typography variant="subtitle1">
              we have Creators who have long experience in their field. so very
              reliable when making materials and guiding you
            </Typography>
          </Box>
          <Box mt={4}>
            <Grid item xs={12} style={{ height: "222px" }}>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                containerClass="carousel-container"
                sliderClass={this.props?.classes.slider}
                itemClass={this.props?.classes.itemClass}
                swipeable={true}
                arrows={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                infinite={true}
                partialVisible={true}
                centerMode={false}
                dotListClass="custom-dot-list-style"
              >
                {this.state.reviews.map((data: any) => (
                  <CarouselCard
                    classes={this.props?.classes}
                    data={data.attributes}
                    key={data.id}
                  />
                ))}
              </Carousel>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = () =>
  createStyles({
    slider: {
      gap: "10px",
    },
    itemClass: {
      maxWidth: "fit-content",
    },
    carouselCard: {
      borderRadius: "18px",
      background: "#2D2D2D",
      padding: "18px",
      display: "flex",
      flexDirection: "column",
      gap: "18px",
      width: "419px",
      height:"186px"
    },
    cardHeadingParent: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    cardHeading: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
    cardProfileImg: {
      width: "54px",
      height: "54px",
      borderRadius: "27px",
    },
    cardBody: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    reviewsParent: {
      background: "#1C1C1C",
      padding: "80px 0px 60px 102px",
    },
    reviewsHeading: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "50%",
    },
    "@media (max-width: 700px)": {
      carouselCard: {
        maxWidth: "fit-content",
      },
    },
  });
const ReviewsWithStyles = withStyles(styles)(Reviews);
export { ReviewsWithStyles };
// Customizable Area End
