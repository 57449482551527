export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Logo = require("../assets/Zilion.png");
export const Success = require("../assets/Vector.png");
export const Email = require("../assets/email_icon.png");
export const Close = require("../assets/cross_icon.png");
export const Edit = require("../assets/edit_icon.png");
export const Email_Success = require("../assets/email_sucess_icon.png");
export const activeTick = require("../assets/active_tick.png");
export const inactiveTick = require("../assets/inactive_tick.png");
