import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open:any
  token: any,
  communityList: any[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCommunityListId: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpen = this.handleOpen.bind(this)
    this.handleCloseSideBar = this.handleCloseSideBar.bind(this)
    this.getCommunityData = this.getCommunityData.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      communityList: [],
      open:"-295px"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ); if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiCommunityListId) {
          this.setState({
            communityList: responseJson.data
          })
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCommunityData()

  }

  handleNavigatetoDiscover = () => {
    this.props.navigation.navigate('DiscoverPageMember');
  }
  handleOpen(){
    this.setState({
      open:"0px"
    })
  }
  handleCloseSideBar(){
    this.setState({
      open:"-295px"
    })
  }
  // Customizable Area End


  async getCommunityData() {
    const token = await getStorageData("authToken")

    const endPoint = `/bx_block_communityforum/joined_communities`;

    const header = {
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCommunityListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
