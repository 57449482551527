import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    createStyles,
    withStyles,
    Theme,
    createTheme,
    ThemeProvider
    // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start

import OTPVerificationController, {
    Props,
} from './OTPVerificationController.web';
import {ArrowBackIos,Error} from '@material-ui/icons';
import OtpInput from 'react-otp-input'

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});
// Customizable Area End


export default class OTPVerification extends OTPVerificationController {


    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    btnClassCondition=this.state.timerActive
    ? this.props.classes?.resendBtnDisabled
    : this.props.classes?.resendBtnActive

    btnClass= this.state.error
    ? this.props.classes?.resendBtnActive
    : this.btnClassCondition
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
          <ThemeProvider theme={theme}>
          <Box className={this.props.classes?.OTPblock}>
          <Box className={this.props.classes?.container}>
            <ArrowBackIos onClick={this.props.handleBack} style={{ cursor: "pointer" }} />
            <Box mt={5}>
              <Typography variant="h4" className={this.props.classes?.title}>
                OTP
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body2"
                  className={this.props.classes?.description}
                >
                  Enter the 6 digit OTP sent via your Phone number{" "}
                  <span className={this.props.classes?.phoneNum}>
                    {this.props.phone}
                  </span>
                </Typography>
              </Box>
            </Box>

            <Box
              mt={6}
              display={"flex"}
              flexDirection={"column"}
              style={{ gap: "14px" }}
            >
              <OtpInput
                data-test-id="otpInput"
                value={this.state.otp}
                onChange={this.setOtp}
                numInputs={6}
                inputStyle={
                  this.state.error
                    ? this.props.classes?.inputStyleError
                    : this.props.classes?.inputStyle
                }
                inputType="password"
                renderInput={(props) => <input {...props} />}
              />
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className={this.props.classes?.errorSection}>
                  {this.state.error && this.state.otpError && (
                    <>
                      <Error className={this.props.classes?.errorIcon} />
                      <Typography className={this.props.classes?.errorText}>
                        {this.state.otpError}
                      </Typography>
                    </>
                  )}
                  {!this.state.error && this.state.timerActive && (
                    <Typography className={this.props.classes?.resendText}>
                      <span style={{ color: "#989AA3" }}>Resend after 00:</span>
                      <span style={{ color: "#3E01B9" }}>
                        {this.state.timerSeconds} sec
                      </span>
                    </Typography>
                  )}

                  {!this.state.error && !this.state.timerActive && (
                    <Typography className={this.props.classes?.timeUpTxt}>
                      Time's up 00:00 sec
                    </Typography>
                  )}
                </div>
                <Button
                  data-test-id="resendButton"
                  color="primary"
                  className={this.btnClass}
                  onClick={this.handleResendOTP}
                  disabled={this.state.error ? false : this.state.timerActive}
                >
                  Resend OTP
                </Button>
              </Box>
              <Box mt={"238px"}>
                <Button
                  data-test-id="confirmBtn"
                  className={
                    this.state.otp.length < 6
                      ? this.props.classes?.otpverifyDisabledBtn
                      : this.props.classes?.otpverifyBtn
                  }
                  variant="contained"
                  color="primary"
                  onClick={this.handleOTPSubmit}
                  disabled={this.state.otp.length < 6}
                >
                  Confirm OTP
                </Button>
              </Box>
            </Box>
          </Box>
          </Box>
          </ThemeProvider> 
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const styles = (theme: Theme) =>
    createStyles({
        OTPblock: {
            display: "flex",
            flexDirection: "column",
            margin:"auto"
        },
        container:{
          maxWidth:"439px",
          margin:"auto"
        },
        title: {
            fontSize: "36px",
            color: "#323548",
            fontWeight: 700,
            lineHeight:"30px"
        },
        description: {
            color: "#323548",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight:"30px",
            opacity: 0.5
        },
        phoneNum:{
            fontWeight: 700,
        },
        inputStyle:{
            height:"62px",
            width:"16.5% !important",
            background: "#F5F5F5",
            border:"1px solid #E4E6EB",
            '&:first-child':{
                borderRadius:"5px 0px 0px 5px"
            },
            '&:last-child':{
                borderRadius:"0px 5px 5px 0px"
            }
        },
        inputStyleError:{
            height:"62px",
            width:"16.5% !important",
            background: "#FFF",
            color:"#EC554D",
            border:"none",
            borderTop: "1px solid #EC554D",
            borderBottom: "1px solid #EC554D",
            borderRight: "1px solid #EC554D",
            '&:first-child':{
                borderLeft:"1px solid #EC554D",
                borderRadius:"5px 0px 0px 5px"
            },
            '&:last-child':{
                borderRadius:"0px 5px 5px 0px"
            }
        },
        errorSection:{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap:"5px",
        },
        resendText:{
            fontWeight:600,
            fontSize:"16px"
        },
        timeUpTxt:{
            fontWeight:600,
            fontSize:"16px",
            color:"#EC554D"
        },
        errorIcon:{
           color:"#EC554D"
        },
        errorText:{
            color: "#000",
            fontWeight: 400,
            fontSize: "14px",
        },
        resendBtnDisabled:{
            fontSize: "16px",
            fontWeight: 600,
            color:"#E8E8E8",
            textDecoration:"none",
            textTransform:"none",
            fontFamily: "Montserrat",
        },
        resendBtnActive:{
            fontSize: "16px",
            fontWeight: 600,
            color:"#3E01B9",
            textDecoration:"underline",
            textTransform:"none",
            fontFamily: "Montserrat",
        },
        otpverifyBtn: {
            background: "#3E01B9",
            borderRadius: "8px",
            width: "100%",
            height: "64px",
            color: "#E8E8E8",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            textTransform:'capitalize',
            fontFamily: "Montserrat",
        },
        otpverifyDisabledBtn: {
            background: "#E8E8E8",
            borderRadius: "8px",
            width: "100%",
            height: "64px",
            color: "#C7C7C7",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            textTransform:'capitalize',
            fontFamily: "Montserrat",
        },
    
    })
export const OTPVerificationWithStyles = withStyles(styles)(OTPVerification);
// Customizable Area End