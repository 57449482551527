import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  padding?: string;
  isCenter: boolean;
  type: 'main' | 'creator' | 'member'
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  keyBenefits: { id: number, heading: string, description: string, image: string }[];
  errors: boolean;
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class KeyBenefitsController extends BlockComponent<
  Props,
  S,
  SS
> {
  KeyBenefitsDataID: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      keyBenefits: [],
      errors: false,
      errorMsg: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ); if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.KeyBenefitsDataID) {
          this.handleKeyBenefitsResponse(responseJson);
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getKeyBenefits()
  }

  getKeyBenefits() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.KeyBenefitsDataID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.keyBenefitsAPIEndPoint}?benefit_type=${this.props.type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleKeyBenefitsResponse = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      const list: [{ id: number, heading: string, descrpition: string, image: string }] | [] = [];
      ((responseJson?.data) || []).slice([0], [4]).forEach((data: any, index: number) => {
        //@ts-ignore
        list.push(data.attributes)
      })
      this.setState({ keyBenefits: [...list] })
    }
  }
  // Customizable Area End
}
