import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Link,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik, Field, Form, ErrorMessage, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import SignupController, { Props } from "./SignUpController.web";
import Carousel from "./Carousel.web";
import { Logo, active_check, unactive_check, ErrorInfoIcon } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialoge from "./Dialoge.web";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { OTPVerificationWithStyles } from "../../forgot-password/src/OTP/ForgotPasswordOTP.web";
// import './Signup.css'

interface FormikInputProps {
  errors: FormikErrors<{
    name: string;
    email: string;
    password: string;
    Cpassword: string;
    termsOfService: boolean;
  }>; touched: FormikTouched<{
    name: string;
    email: string;
    password: string;
    Cpassword: string;
    termsOfService: boolean;
  }>
}
// Customizable Area End

export default class Signup extends SignupController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.onSignUp = this.onSignUp.bind(this);
    this.EmailSignUpComponent = this.EmailSignUpComponent.bind(this);
    this.SignUpBlock = this.SignUpBlock.bind(this);
    this.PhoneSignUpComponent = this.PhoneSignUpComponent.bind(this);

    // Customizable Area End
  }

  // Customizable Area Start
  getErrorColor = (fiedError?: string, touched?: boolean) => {
    return fiedError && touched ? '#EC554D' : ''
  }

  EmailInput = ({ errors, touched }: FormikInputProps) => {
    return <Grid item xs={12}>
      <label style={webStyle.label} htmlFor="email">
        Your Email Address
      </label>

      <Field

        data-test-id="signupInputEmail"
        name="email"
        id="email"
        placeholder="Write your Email Here"
        variant="filled"
        as={TextField}
        fullWidth
        helperText={
          <Typography style={webStyle.required}>
            <ErrorMessage name="email" >
              {msg => (<>
                <img
                  src={ErrorInfoIcon}
                  width="18px"
                  height="18px"
                />&nbsp;{msg}
              </>)
              }
            </ErrorMessage>
          </Typography>
        }
        InputProps={{
          disableUnderline: true,
          style: { color: this.getErrorColor(errors.email, touched.email) }

        }}

      />
      {this.state.errors && <Typography style={webStyle.required}>
        <img
          src={ErrorInfoIcon}
          width="18px"
          height="18px"
        />&nbsp;
        {this.state.errorMsg}
      </Typography>}
    </Grid>
  }


  NameInput = ({ errors, touched }: FormikInputProps) => {
    return <Grid item xs={12}>
      <label style={webStyle.label} htmlFor="name">
        Full  Name
      </label>
      <Field
        data-test-id="signupInputName"
        placeholder="What's your Name"
        type="text"
        name="name"
        fullWidth
        id="name"
        variant="filled"
        as={TextField}
        helperText={
          <Typography style={webStyle.required}>
            <ErrorMessage name="name" >
              {msg => (<>
                <img
                  src={ErrorInfoIcon}
                  width="18px"
                  height="18px"
                />&nbsp;{msg}
              </>)
              }
            </ErrorMessage>
          </Typography>
        }
        InputProps={{
          disableUnderline: true,
          style: { color: this.getErrorColor(errors.name, touched.name) }

        }}
      />
    </Grid>
  }
  submitButton = ({ isValid, dirty, isSubmitting, buttonValue }:
    { isValid: boolean; dirty: boolean; isSubmitting: boolean; buttonValue: string }) => {
    return <Button
      data-test-id="submitBtn"
      type="submit"
      style={
        (isValid && dirty) || isSubmitting
          ? webStyle.signbtn
          : webStyle.disable

      }
      disabled={!isValid && !dirty}
      size="large"
    >
      {buttonValue}
    </Button>
  }

  EmailSignUpComponent() {

    return <Formik
      data-test-id="FormikEmailSignUp"
      initialValues={{
        name: "",
        email: "",
        password: "",
        Cpassword: "",
        termsOfService: false
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Enter your Full Name"),
        email: Yup.string()
          .required("Enter your Email Address")
          .email('Invalid email'),
        password: Yup.string()
          .required("Password is required")
          .matches(/[A-Z]/)
          .matches(/[a-z]/)
          .matches(/\d/)
          .min(8),
        Cpassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
        termsOfService: Yup
          .boolean()
          .required('You must accept the Terms of Service to proceed')
          .oneOf([true], 'Error')
      })}
      onSubmit={(values) => {
        this.postData(values);
      }}
    >
      {({ values, handleBlur, isSubmitting, errors, isValid, dirty, touched, setFieldValue }) => {
        return <>
          {this.state.emailSignupStage === 'edit' && <Form>
            <Grid container style={{ marginBottom: "50px" }}>

              <this.EmailInput errors={errors} touched={touched} />

            </Grid>

            <this.submitButton isSubmitting isValid dirty buttonValue="Update Mail" />


          </Form>}

          {this.state.emailSignupStage === 'initial' && <Form>
            <Grid container style={{ marginBottom: "50px" }}>
              <this.NameInput errors={errors} touched={touched} />
              <this.EmailInput errors={errors} touched={touched} />

              <Grid item xs={12}>
                <label style={webStyle.label} htmlFor="password">
                  Create Password
                </label>

                <Field
                  data-test-id="signupInputPassword"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  variant="filled"
                  value={values.password}
                  as={TextField}
                  fullWidth
                  placeholder="Choose a strong password"
                  InputProps={{
                    disableUnderline: true,
                    style: { color: this.getErrorColor(errors.password, touched.password) },

                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onChange={(value) =>
                          setFieldValue("password", value)
                        }
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
                <Typography style={webStyle.label}>
                  Password must contain atleast
                </Typography>
                <Box style={{ display: "flex", gap: "10px", marginTop: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={
                        RegExp(/[A-Z]/).test(values?.password)
                          ? active_check
                          : unactive_check
                      }
                      width="30px"
                      height="30px"
                    />{" "}
                    <Typography style={webStyle.label}>
                      1 uppercase letter
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={
                        RegExp(/[a-z]/).test(values?.password)
                          ? active_check
                          : unactive_check
                      }
                      width="30px"
                      height="30px"
                    />{" "}
                    <Typography style={webStyle.label}>
                      1 lowercase letter
                    </Typography>
                  </Box>
                </Box>

                <Box style={{ display: "flex", gap: "10px", marginTop: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={
                        RegExp(/[\d]/).test(values?.password)
                          ? active_check
                          : unactive_check
                      }
                      width="30px"
                      height="30px"
                    />{" "}
                    <Typography style={webStyle.label}>1 number</Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={
                        RegExp(/^(?=.*[A-Z]).{8,}$/).test(values?.password)
                          ? active_check
                          : unactive_check
                      }
                      width="30px"
                      height="30px"
                    />{" "}
                    <Typography style={webStyle.label}>
                      Minimum 8 characters length
                    </Typography>{" "}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <label style={webStyle.label} htmlFor="Cpassword">
                  Confirm Password
                </label>
                <Field
                  data-test-id="signupInputCPassword"
                  type={this.state.confirmPassword ? "text" : "password"}
                  id="Cpassword"
                  name="Cpassword"
                  placeholder="Choose a strong password"
                  variant="filled"
                  as={TextField}
                  fullWidth
                  helperText={
                    <Typography style={webStyle.required}>
                      <ErrorMessage name="Cpassword" >{msg => (<>
                        <img
                          src={ErrorInfoIcon}
                          width="18px"
                          height="18px"
                        />&nbsp;{msg}
                      </>)
                      }</ErrorMessage>
                    </Typography>
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: { color: this.getErrorColor(errors.Cpassword, touched.Cpassword) },
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onChange={(value) =>
                          setFieldValue("Cpassword", value)
                        }
                        onClick={this.handleClickShowConfirmPassword}
                      >
                        {this.state.confirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

            </Grid>
            <this.submitButton isSubmitting isValid dirty buttonValue="Sign Up" />

            <Grid item xs={12}>
              <Box display="flex" alignItems={"start"} style={{ gap: "10px" }}>
                <Field
                  type="checkbox"
                  id="termsOfService"
                  style={webStyle.checkbox}
                  name="termsOfService"
                  checked={values.termsOfService}
                />
                <label
                  htmlFor="remember"
                  style={{ ...webStyle.remember, color: this.getErrorColor(errors.termsOfService, touched.termsOfService) }}
                >

                  By procceding, you agree to our

                  <Typography style={webStyle.condition}
                    data-test-id="termsopen"
                    onClick={this.onTermsOpen}>Terms & Conditions
                  </Typography>
                </label>

              </Box>

            </Grid>
            <Typography style={webStyle.accountLink}>
              Do you have already an account ?{" "}
              <Link href="/LogIn">Login Now</Link>
            </Typography>
          </Form>}
        </>
      }}
    </Formik>
  }

  PhoneSignUpComponent() {
    return <Formik
      data-test-id="FormikPhoneSignUp"
      initialValues={{
        name: "",
        Phone: "",
        termsOfService: false
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Enter your Full Name"),
        Phone: Yup.string()
          .matches(/^\d+$/, 'Phone number must be 10 digits')
          .required('Mobile Number is required'),
        termsOfService: Yup
          .boolean()
          .required('You must accept the Terms of Service to proceed')
          .oneOf([true], 'Error')
      })}
      onSubmit={(values) => {
        this.postPhoneData(values);
      }}
    >
      {({ values, handleBlur, isSubmitting, errors, isValid, dirty, touched }) => (
        <Form>
          <Grid container style={{ marginBottom: "150px" }}>
            <this.NameInput errors={errors} touched={touched} />


            <Grid item xs={12}>
              <label style={webStyle.label} htmlFor="Phone" >
                Your Phone  Number
              </label>

              <Field
                data-test-id="signupPhone"
                name="Phone"
                render={({ field, form }: any) => (
                  <PhoneInput
                    {...field}
                    defaultCountry="US"
                    country={"us"}
                    inputComponent={TextField}
                    value={values.Phone}

                    onChange={(value) => form.setFieldValue("Phone", value)}
                    onBlur={handleBlur("Phone")}
                    inputProps={{
                      label: "Phone Number",
                      fullWidth: true,
                      variant: "outlined",
                      error: form.errors.Phone,
                      helperText: form.errors.Phone,
                      style: { color: errors.Phone && touched.Phone ? '#EC554D' : '' }

                    }}

                  />

                )}
              />
              <Typography style={webStyle.required}>
                <ErrorMessage name="Phone" >{msg => (<>
                  <img
                    src={ErrorInfoIcon}
                    width="18px"
                    height="18px"
                  />&nbsp;{msg}
                </>)
                }</ErrorMessage>
              </Typography>
              {this.state.errors && <Typography style={webStyle.required}>
                <img
                  src={ErrorInfoIcon}
                  width="18px"
                  height="18px"
                />&nbsp;
                {this.state.errorMsg}
              </Typography>}
            </Grid>

          </Grid>
          <this.submitButton isSubmitting isValid dirty buttonValue="Sign Up" />
          <Grid item xs={12}>
            <Box display="flex" alignItems={"start"} style={{ gap: "10px" }}>
              <Field
                type="checkbox"
                id="termsOfService"
                style={webStyle.checkbox}
                name="termsOfService"
                checked={values.termsOfService}
              />
              <label
                htmlFor="remember"
                style={{ ...webStyle.remember, color: errors.termsOfService && touched.termsOfService ? '#EC554D' : '' }}
              >

                By procceding, you agree to our

                <Typography style={webStyle.condition}
                  data-test-id="termsopen"
                  onClick={this.onTermsOpen}>Term & Conditions
                </Typography>
              </label>

            </Box>

          </Grid>
          <Typography style={webStyle.accountLink}>
            Do you have already an account ?{" "}
            <Link href="/LogIn">Login Now</Link>
          </Typography>
        </Form>
      )}
    </Formik>
  }


  onSignUp() {
    return <>
      {(this.state.signUpMethod === 'email') && <this.EmailSignUpComponent />}
      {(this.state.signUpMethod === 'phone') && <this.PhoneSignUpComponent />}
    </>
  }

  SignUpBlock() {
    return <Box style={webStyle.SignUpBlock}>
      <Box
        textAlign={'right'}
        p={3}
      >

        <img src={Logo} alt=" " style={{ width: "64px " }} />
      </Box>
      {this.state.signUpMethod === 'phone' && this.state.otpVerification ?
        <Box width="80%" margin="auto">
          <OTPVerificationWithStyles
            onSubmit={() => { }}
            id={"otpverification"}
            handleBack={this.handleOtpBack}
            phone={this.state.phoneNumber}
            method="signup"
            navigation={this.props.navigation} />
        </Box>
        :
        <Box width="80%" margin="auto">


          <ArrowBackIosIcon
            onClick={this.Back}
            style={{ cursor: "pointer", alignSelf: "end" }}
          />


          <Box marginTop={"1%"}>
            <Typography variant="h4" style={webStyle.title}>
              {this.state.emailSignupStage === 'edit' ?
                `Update your email address` :
                `Sign Up`}
            </Typography>
            {this.state.emailSignupStage === 'initial' &&
              <Typography style={webStyle.description}>
                To create an account, enter your details,
              </Typography>}
          </Box>
          <Box mt={5}>
            {this.state.emailSignupStage === 'initial' &&
              <Box display={"flex"} flexDirection={"row"} mb={3}>
                <Button
                  data-test-id="btnEmail"
                  variant="contained"
                  style={{
                    background:
                      this.state.signUpMethod === 'email'
                        ? "#3E01B9"
                        : "#E8E8E8",
                    color:
                      this.state.signUpMethod === 'email' ? "#fff" : "#000",
                    width: "100%",
                    height: "45px",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    borderRadius: "5px 0px 0px 5px",
                  }}
                  onClick={this.handleEmailClick}

                >
                  Using Email
                </Button>
                <Button
                  onClick={this.handleMobileClick}
                  data-test-id="btnPhone"
                  name="UsingPhone"
                  style={{
                    background:
                      this.state.signUpMethod === 'phone'
                        ? "#3E01B9"
                        : "#E8E8E8",
                    color:
                      this.state.signUpMethod === 'phone' ? "#fff" : "#000",
                    width: "100%",
                    height: "45px",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  }}
                  variant="contained"
                >
                  Using Phone
                </Button>
              </Box>}


            <Box>
              < this.onSignUp />
            </Box>
          </Box>


        </Box>}
    </Box>
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={6} >
          <Carousel navigation={undefined} id={""} accountType={this.state.accountType} />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: "20px" }}>
          <this.SignUpBlock />
        </Grid>

        <Dialoge
          onHandleOpen={this.state.termsvisible}
          navigation={undefined}
          id={""}
          onHandleClose={this.onTermsClose}
          btnName="Close"
          description={this.state.termsData}
          title={""}
          icon={undefined}
        />
        <Dialoge
          onHandleOpen={this.state.privacyvisible}
          navigation={undefined}
          id={""}
          btnName="Close"
          onHandleClose={this.onPrivacyClose}
          description={this.state.privacyData}
          title={""}
          icon={undefined}
        />

        <Dialoge
          onHandleOpen={this.state.open}
          icon={''}
          title={''}
          description={''}
          navigation={undefined}
          id={""}
          onHandleClose={this.onHandleClose}
          dialogType="VerifyModal"
          maskedEmail={this.state.maskedEmail}
          handleEditSignupEmail={this.handleEditSignupEmail}
        />

        <Dialoge
          onHandleOpen={this.state.verifiedEmailModal}
          navigation={undefined}
          id={""}
          btnName="Close"
          onHandleClose={() => { }}
          description={''}
          title={""}
          icon={undefined}
          dialogType="EmailVerifiedModal"

        />

      </Grid>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  imgBlock: {
    background: "#E8E8E8",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "20px",
    borderRadius: "5px",
  } as React.CSSProperties,
  iconstyle: {
    background: "rgba(62, 1, 185, 0.06)",
    borderRadius: "50px",
    padding: "10px",
    width: "30px",
    height: "30px",
    margin: "auto",
  },
  checkbox: {
    borderColor: "#3E01B9",
    marginTop: "18px"
  },
  uploadbtn: {
    background: "#3E01B9",
    borderRadius: "10px",
    width: "100%",
    height: "40px",
    color: "#E8E8E8",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "capitalize",
  } as React.CSSProperties,
  condition: {
    textDecoration: "underline",
    color: "#3E01B9",
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "-3px",
    marginLeft: "2px"

  },
  closeError: {
    color: "#EC554D",
    fontSize: "50px",
  },
  disable: {
    background: "#E8E8E8",
    borderRadius: "10px",
    width: "100%",
    height: "64px",
    color: "#C7C7C7",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "capitalize",
  } as React.CSSProperties,
  screen: {
    display: "flex",
  },
  remember: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    display: "flex",
    fontSize: "14px",
    marginTop: "15px",
    paddingRight: "3px"
  },
  accountLink: {
    textAlign: "center",
    color: " rgba(50, 53, 72, 0.5)",
    fontSize: "16px",
    lineHeight: "30px",
    marginTop: "10%"
  } as React.CSSProperties,

  SignUpBlock: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  } as React.CSSProperties,
  signbtn: {
    background: "#3E01B9",
    borderRadius: "10px",
    width: "100%",
    height: "64px",
    color: "#E8E8E8",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "capitalize",
  } as React.CSSProperties,
  label: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#6C707C",
    lineHeight: "35px",
  },

  SignupLogo: {
    position: "absolute",
    top: "38px",
    right: "63px",
    height: "64px",
    width: "54px",
  } as React.CSSProperties,
  SignupHeading: {
    lineHeight: "2",
    fontSize: "35px",
    fontWeight: 700,
  },

  required: {
    color: "#000",
    marginTop: "10px",
    display: 'flex',
    alignItems: 'center'
  } as React.CSSProperties,
  input: {
    marginTop: "10px",
  },
  title: {
    lineHeight: "2",
    fontSize: "36px",
    fontFamily: "Montserrat",
    color: "#323548",
    fontWeight: 700,
  },
  description: {
    color: "#323548",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px",
  },
};
// Customizable Area End