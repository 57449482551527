import React from "react";

import {

  // Customizable Area Start
  Box,
  Typography,
  Grid,
  createStyles,
  withStyles
  // Customizable Area End
} from "@material-ui/core";



// Customizable Area Start
import MobileStepper from '@material-ui/core/MobileStepper';
import CarouselController, {
  Props,
} from "./CarouselController.web";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { ornament, mainCover, coverLogo, zilion } from '../assets'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactPlayer from 'react-player'

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },

  },
  typography: {
    fontFamily: ["Montserrat", "serif"].join(","),
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});



const tutorialSteps = [
  {
    title: "  Find what you are looking forward ",
    label: "thas been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    imgPath: mainCover,
    logoImg: coverLogo
  }
];

// Customizable Area End





export default class Carousel extends CarouselController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.displayBackground = this.displayBackground.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  displayBackground({ attributes }: {
    attributes: {
      id: number;
      heading: string;
      description: string;
      position: string;
      created_at: any;
      video?: string | null;
      image?: string | null;
      logo: string;
    }
  }) {

    if (attributes.video) {
      return <ReactPlayer
        height={'100%'}
        width={'100%'}
        volume={1}
        muted={true}
        playing={true}
        url={attributes.video} />
    }

    if (attributes.image) {
      return <img style={webStyle.img1} width={'100%'} height={'100%'} src={attributes.image} />
    }


    return <img style={webStyle.img1}  width={'100%'} height={'100%'} src={tutorialSteps[0].imgPath} />
  }
  // Customizable Area End

  render() {

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Box style={{ height: '694px', width: '100%', position: 'relative' }}>
          <Box style={{ height: '92%', width: '100%', overflow: 'hidden' }}>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
              interval={5000}
              style={{ width: '100%', height: '100%', overflow: 'hidden' }}
              slideStyle={{ height: '100%', overflow: 'hidden' }}
              containerStyle={{ height: "100%", }}
            >
              {this.state.data.map((step, index) => (
                <Box style={{ height: '100%' }} key={index}>
                  <this.displayBackground attributes={step.attributes} key={index} />
                </Box>
              ))}

            </AutoPlaySwipeableViews>
          </Box>
          <Box style={webStyle.stepperContainer}>
            <MobileStepper
              style={webStyle.stepper}
              variant="dots"
              steps={this.state.data.length}
              position="static"
              activeStep={this.state.activeStep}
              backButton={undefined}
              nextButton={undefined}
              classes={{
                dot: this.props.classes?.dot,
                dotActive: this.props.classes?.dotActive
              }}
            />
          </Box>
          <Box style={webStyle.carouseldataStyle}>
            <Box display={'flex'} alignItems={'center'}>
              <img style={{ width: '54px', height: '54px', borderRadius: '54px' }}
                alt="logo"
                src={this.state.data[this.state.activeStep]?.attributes?.logo}
              />
              <Typography variant="h4" style={webStyle.title}>
                {this.state.data[this.state.activeStep]?.attributes?.heading}
              </Typography>
            </Box>
            <Typography variant="body2" style={webStyle.content} >{this.state.data[this.state.activeStep]?.attributes?.description}</Typography>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  title: {
    color: "#FFF",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "40px",
    letterSpacing: "0.123px",
    textTransform: "capitalize",
    marginLeft: '16px'
  } as React.CSSProperties,
  content: {
    color: "#FFF",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.123px"
  } as React.CSSProperties,
  img1: {
    width: '100%',
    height: '100%',
    objectFit: "cover"
  } as React.CSSProperties,


  stepperContainer: {
    height: '8%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepper: {
    background: "transparent",

  },
  carouseldataStyle: {
    position: 'absolute',
    bottom: "10%",
    left: '56px',
    width: "40%",
    zIndex: 999
  } as React.CSSProperties,

};


const muiStyles = () =>
  createStyles({
    dot: {
      backgroundColor: "#fff"
    },
    dotActive: {
      backgroundColor: "#3E01B9"
    }
  })


const CarouselWithStyles = withStyles(muiStyles)(Carousel);
export { CarouselWithStyles };

// Customizable Area End
