import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config");
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  onSubmit: (values: string[]) => void;
  // Customizable Area Start
  classes?: any;
  phone: string;
  method: "login" | "signup";
  handleBack: () => void;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  otp: string;
  otpError: string;
  error: boolean;
  open: boolean;
  timerSeconds: number;
  timerActive: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

class OTPVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiVerifyotpCallId: any;
  resendOtpId: any;
  timerInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {

      // Customizable Area Start
      otp: "",
      otpError: "",
      error: false,
      open: false,
      timerSeconds: 35,
      timerActive: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



  // Customizable Area Start

  async componentDidMount() {
    if (!this.state.timerActive) {
      this.startTimer();
    }
  }

  startTimer = () => {
    clearInterval(this.timerInterval);
    this.setState({ timerActive: true }, () => {
      this.timerInterval = setInterval(() => {
        if (this.state.timerSeconds === 0) {
          clearInterval(this.timerInterval);
          this.setState({ timerActive: false });
        } else {
          this.setState((prevState) => ({
            timerSeconds: prevState.timerSeconds - 1,
          }));
        }
      }, 1000);
    });
  };

  setOtp = (otp: string) => {
    this.setState({ otp: otp, error: false, otpError: "" });
  };

  handleOTPSubmit = async () => {
    const otpToken = await getStorageData("otpToken");
    const method = this.props.method;
    const endPoint =
      method === "login"
        ? configJSON.verifyLoginOTPEndPoint
        : configJSON.verifySignupOTPEndPoint;
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody = {
      token: otpToken,
      pin: this.state.otp,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyotpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResendOTP = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody = {
      data: {
        attributes: {
          full_phone_number: this.props.phone,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      otp: "",
      error: false,
      otpError: "",
      timerActive: true,
      timerSeconds: 35,
    });
    this.startTimer();
  };


  handleRedirection(responseJson: any) {
    const method = this.props.method;
    setStorageData("authToken", responseJson.meta.token);
    const accountType = (responseJson.meta?.account_type === 'creator') ? 'creator' : 'member';
    setStorageData('accountType', accountType);
    if (method === "login") {
      if (responseJson.meta?.is_profile_completed === true) {
        if (accountType === 'creator') {
          this.props.navigation.navigate('Communityforum3');
        } else {
          this.props.navigation.navigate('HomePageMember');
        }
      }
      else {
        this.props.navigation.navigate('Setup');
      }
    } else {
      this.props.navigation.navigate("Setup");
    }
  }

  handleVerifyOTPResp(responseJson: any) {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ error: true, otpError: err });
    } else {
      this.handleRedirection(responseJson)
    }
  }

  handleResendResp(responseJson: any) {
    if (responseJson.errors) {
      this.setState({ error: true, otpError: responseJson.errors });
    } else {
      setStorageData("otpToken", responseJson.data.meta.otp_token);
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiVerifyotpCallId) {
          this.handleVerifyOTPResp(responseJson);
        }
        if (apiRequestCallId === this.resendOtpId) {
          this.handleResendResp(responseJson);
        }
      }
    }
  }

  // Customizable Area End
}

export default OTPVerificationController

