import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  open:boolean;
  maskedEmail:string,
  error:boolean,
  errorMessage:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

class ForgotPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendMailId:any;
  setSubmitting:any=null
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start 

    this.subScribedMessages = [

      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    this.state = {

      // Customizable Area Start
      open:false,
      maskedEmail:"",
      error:false,
      errorMessage:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }



  // Customizable Area Start

  Back = () => {
    this.props.navigation.goBack()
  };

  onHandleClose = () => {
    this.setState({ open: false });

  }

  onHandleOpen = () => {
    this.setState({ open: true });
}

  onHandleChange=()=>{
      this.setState({error:false,errorMessage:""})
  }

  getMaskedEmail = (email: string) => {
    let str = email.split('');
    let finalArr: string[] = [];
    let len = str.indexOf('@');
    str.forEach((item, pos) => {
      (pos >= 3 && pos < len) ? finalArr.push('*') : finalArr.push(str[pos]);
    })
    return finalArr.join('')
  }

  forgetData = async (values: any, setSubmitting: any) => {
    const maskedEmail = this.getMaskedEmail(values.email)
    this.setSubmitting = setSubmitting;
    this.setState({
      maskedEmail
    })
    const header = {
      'Content-Type': configJSON.forgotPasswordAPiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMailId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.forgotPasswordByEmailEndPoint}?email=${values.email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.sendMailId) {
          if(responseJson.error){
            this.setState({error:true,errorMessage:responseJson.error})
          }
          else{
            this.onHandleOpen();
          }
          
        }

      }
      this.setSubmitting(false)
      this.setSubmitting = null;
    }
  }
  // Customizable Area End
}

export default ForgotPasswordController