import { IBlock } from "../../../framework/src/IBlock";

import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Home, Messages, Notifications, SettingsImg, Analytics, Events, Communities, CrossIcon, ImageDrop, Outlined, CardIcon, imgMessageIcon, imgNewPostIcon, CheckIcon, tick2, dollar, cPicture, cHeading, cLink,downArrow } from './assets';

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { webStyle } from './Communitylandingpage.web'
import Button from '@material-ui/core/Button';
import React from "react";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { isChrome } from 'react-device-detect';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const regexUrl = /^(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{1,2}(?::[0-9]+)?(?:\/[^\/]*)*$/;

interface S {
  txtInputValue: string;
  drawerItems: any;
  webDrawer: boolean,
  Cards: any[],
  Open: boolean,
  switch: number,
  coverPhotos: any[]
  isDragOver: any,
  dataFile: any,
  imageUrl: any,
  files: any,
  Description: any;
  textcard: boolean;
  anchorEl: any;
  anchorEl2: any
  checked: any;
  selectedCoverphoto: any;
  postModal: any;
  PostImage: any;
  postHeading: any;
  postLink: any;
  postDescription: any;
  pricing: boolean;
  Pricingcards: any;
  CardDetails: any;

  PostheadingError: boolean;
  PostheadingErrorMsg: any;
  PostLink: any;
  PostLinkError: boolean;
  PostLinkErrorMsg: any;
  PostDescription: any;
  PosDescError: boolean;
  PostDescErrorMsg: any;
  postImgerror: boolean;
  postImgErrorMsg: any;
  active: any;
  video: boolean;
  // Customizable Area Start
  name: any;
  description: any;
  cover_pic: any;
  category_id: any;
  username: any;
  subscription_type: any;
  status: any;
  communityResponse: any;
  expanded: boolean;
  welcomeNoteText: string;
  videoThumb: any;
  welocomeNoteModalDetails: any;
  cancelResponse: any;
  isloading: boolean;
  src: any;
  isPlaying: any;
  isChrome: any;
  browserThumbnail: any;
  onDemandState: boolean;
  date: {
    day: string;
    month: string;
    year: string;
  };
  selectedTime: string;
  timeOptions: string[];
  isCustomizeTimeChecked: boolean;
  createPostResponse: any;
  memberError: any;
  memberErrorState: boolean;
  ondemandErrorState: boolean;
  ondemanError: any;
  priceDemand: string;
  discountDemand: string;
  neverExpireCheck: string;
  discountError: boolean;
  discountErrorMessage: string;
  publishing: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunitylandingpageController extends BlockComponent<
  Props,
  S,
  SS
> {
  fileInputRef: any;
  // Customizable Area Start
  keyUploadVedio: string = "";
  keyCommunity: string = "";
  keyWelocomeNote: string = "";
  keyCancelVedio: string = "";
  keyCreatePost: string = "";
  keysubscriptionPlans: string = "";
  videoInputRef: any;
  thumbnailCanvasRef: any;
  thumbnailImageRef: any;
  vedioControlRef: any;
  browseRef: any;
  canvasRef: any;
  monthInput: any;
  yearInput: any;
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.fileInputRef = React.createRef();
    this.videoInputRef = React.createRef();
    this.thumbnailCanvasRef = React.createRef();
    this.thumbnailImageRef = React.createRef();
    this.vedioControlRef = React.createRef();
    this.browseRef = React.createRef();
    this.canvasRef = React.createRef();
    this.monthInput = React.createRef();
    this.yearInput = React.createRef();

    this.state = {
      txtInputValue: "",
      drawerItems: [{ name: "Home", Img: Home }, { name: "Message", Img: Messages }, { name: "Events", Img: Events }, { name: "Communities", Img: Communities }, { name: "Analytics", Img: Analytics }, { name: "Notifications", Img: Notifications }, { name: "Settings", Img: SettingsImg }],
      webDrawer: false,

      Cards: [{ id: 1, description: "To publish your community it's mandatory to upload a video showcasing about the community content to Zilion users.", buttoncontent: "Upload a Video", Upload: true, idx: 0, isCompleted: false, img: CardIcon },
      { id: 2, description: "Share a message as soon as a user joins your community", buttoncontent: "Write a Welcome Note", Upload: false, idx: 1, isCompleted: false, img: imgMessageIcon },
      { id: 3, description: "Let's create a new post or upload your 1st video for the members who'll be joining your community shortly!", buttoncontent: "Create a New Post", Upload: false, idx: 2, isCompleted: false, img: imgNewPostIcon },],
      Open: false,
      switch: 0,
      coverPhotos: [],
      Description: "",
      textcard: false,
      isDragOver: "",
      dataFile: {},
      imageUrl: "",
      files: "",
      anchorEl: null,
      anchorEl2: false,
      checked: false,
      selectedCoverphoto: "",
      postModal: false,
      postImgerror: false,
      postImgErrorMsg: "",

      PostheadingError: false,
      PostheadingErrorMsg: "",
      PostLink: "",
      PostLinkError: false,
      PostLinkErrorMsg: "",
      PostDescription: "",
      PosDescError: false,
      PostDescErrorMsg: "",
      PostImage: "",
      postHeading: "",
      postLink: "",
      postDescription: "",
      pricing: false,
      Pricingcards: [{ plans: "Basic", Price: 5, selected: false, planText: "/Y" }, { plans: "Elite", Price: 8, selected: false, planText: "/Y" }, { plans: "Premium", Price: 50, selected: false, planText: "/Y" }, { plans: "Express", Price: 60, selected: false, planText: "/Y" }],
      CardDetails: [],
      active: 1,
      video: false
      // Customizable Area Start
      ,
      name: "",
      description: "",
      cover_pic: "",
      category_id: "",
      username: "",
      subscription_type: "",
      status: "",
      communityResponse: {},
      expanded: false,
      welcomeNoteText: "",
      videoThumb: "",
      welocomeNoteModalDetails: "",
      cancelResponse: "",
      isloading: false,
      src: "",
      isPlaying: true,
      isChrome: true,
      browserThumbnail: "",
      onDemandState: false,
      date: {
        day: '',
        month: '',
        year: '',
      },
      selectedTime: "",
      timeOptions: [],
      isCustomizeTimeChecked: false,
      createPostResponse: {},
      memberError: "",
      ondemanError: "",
      memberErrorState: false,
      ondemandErrorState: false,
      priceDemand: "",
      discountDemand: "",
      neverExpireCheck: "",
      discountError: false,
      discountErrorMessage: "",
      publishing: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }


  // Customizable Area Start
  validationOnDemand = () => {
    return (
      this.state.active == 3 && this.state.onDemandState && this.state.priceDemand == ""
    )
  }

  discountValidation = () => {
    return (
      this.state.active == 3 && this.state.onDemandState && this.state.discountDemand.length !== 0 && this.state.neverExpireCheck == ""
    )
  }

  dateValidation = () => {
    return (
      this.state.active == 3 && this.state.onDemandState && this.state.discountDemand.length !== 0 && this.state.neverExpireCheck == "custom_time"
    )
  }

  async componentDidMount() {
    this.handlApiCommunityGet();
    this.handlApiSubscriptionsGet();
    this.setState({
      isChrome: true
    });
    this.updateTimeOptions();
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {

        const apiRequestCallData = {
          [this.keyWelocomeNote]: (data: any) => this.keyWelcomResponse(data),
          [this.keyCommunity]: (data: any) => this.keyCommunityResponse(data),
          [this.keyUploadVedio]: (data: any) => this.keyUploadVideoResponse(data),
          [this.keyCancelVedio]: (data: any) => this.keyUploadVideoCancelResponse(data),
          [this.keyCreatePost]: (data: any) => this.keyCreatePostResponse(data),
          [this.keysubscriptionPlans]: (data: any) => this.subscriptionPlansResponse(data),
        }

        const callback = apiRequestCallData[apiRequestCallId];

        if (callback !== undefined) {
          callback(responseJson)
        }
      }
    }
  }

  subscriptionPlansResponse = (response: any) => {
    this.setState({
      Pricingcards: response.data
    })
  }

  keyCreatePostResponse = (response: any) => {
    this.setState((state) => ({
      createPostResponse: response.data,
      Cards: [this.createPostHandler(state.Cards[2]), state.Cards[0], state.Cards[1]]
    }))
  }

  createPostHandler = (item: any) => {
    if (item.id === 3) {
      item.isCompleted = true;
    }
    return item
  }


  keyCommunityResponse = (response: any) => {
    this.setState({
      communityResponse: response.data
    });
  }


  keyUploadVideoResponse = (response: any) => {
    this.setState((state) => ({

      isloading: false,
      imageUrl: response.data?.attributes.vedio,
      video: true,
      Cards: state.Cards.map((item) => {
        return this.cardVedioUploadHandler(item)
      })
    }))
  }

  keyUploadVideoCancelResponse = (response: any) => {
    this.setState({
      cancelResponse: response.data
    })
  }

  keyWelcomResponse = (response: any) => {
    console.log("state-->", this.state.Cards)
    const newCards = this.state.Cards.map((item: any) => {
      return this.cardWelcomeHandler(item)
    })
    this.setState((state) => ({
      welcomeNoteText: response.data.attributes.welcome_note,
      welocomeNoteModalDetails: response.data,
      Cards: newCards
    }))
  }

  ///////////////////////////

  cardVedioUploadHandler = (item: any) => {
    if (item.id === 1) {
      item.isCompleted = true;
      item.description = this.vedioDescription();
      item.buttoncontent = "Edit the video";
    }
    return item
  }

  cardWelcomeHandler = (item: any) => {
    if (item.id === 2) {
      item.isCompleted = true;
      item.description = this.welcomeNoteDescription();
      item.buttoncontent = "Edit the Welcome Note";
    }
    return item
  }

  vedioDescription = () => {
    return (
      <Typography>
        Nice! Video uploaded successfully
      </Typography>
    )
  }

  welcomeNoteDescription = () => {
    return (
      <Typography>
        Welcome note looks great!
      </Typography>
    )
  }


  Othercards = (props: any) => {

    return (
      <div>
        {props.video ?

          <div style={webStyle.SelectCoverImage}>
            <div style={{ backgroundColor: "#3618B1", borderRadius: "10px", padding: "10px", minHeight: "300px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 10px" }}>
                <Typography style={{ fontSize: "20px", fontWeight: 500 }} >Uploading Video</Typography>
                <img src={CrossIcon} onClick={this.handleClose} style={{ width: "40px", height: "40px" }} data-test-id="crossBtn"></img>
              </div>
              <div style={{ position: 'relative', borderRadius: "10px", overflow: 'hidden', backgroundColor: "black", maxHeight: "400px", minHeight: "400px" }}>
                {this.state.isloading ? (
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <CircularProgress style={{ color: "#fff" }} />
                  </div>
                ) : (
                  // <CheckCircleOutlinedIcon style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }} />
                  <img src={CheckIcon} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1, width: "4%" }} />
                )}
                {!this.state.isloading && (
                  <>
                    <video
                      width="100%"
                      style={{ borderRadius: "10px", backgroundColor: "black", maxHeight: "400px", objectFit: 'cover' }}
                      src={this.state.src}

                    >
                    </video>
                  </>
                )}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button
                test-Id="clearCoverImg"
                title="Set Cover Image" color="primary"
                onClick={() => {
                  this.handleApiCancelVedio();
                  this.setState({ Open: false, imageUrl: "", video: false })
                }}
                style={{
                  width: '35%',
                  boxShadow: 'none',
                  margin: '20px 0px',
                  textDecoration: 'none !important',
                  backgroundColor: '#3E01B9',
                  color: '#ffffff',
                  border: 'none',
                  padding: "10px 20px",
                  cursor: 'pointer',
                  display: 'inline-block',
                  textAlign: 'center',
                  fontSize: '16px',
                  borderRadius: '4px',
                }}
              >
                Cancel
              </button>
            </div>
          </div>

          :

          <div style={webStyle.modalStyle} >
            <img src={CrossIcon} onClick={this.handleClose} style={{ width: "40px", height: "40px", position: "relative", left: "70%", marginTop: "20px" }}></img>
            <Grid item md={6} lg={6} >

              {
                this.state.switch === 1 && <div>
                  <Typography style={{ fontSize: "28px", fontWeight: 500, marginTop: "20px" }}>Upload Video/Picture</Typography>

                  <Typography style={{
                    ...webStyle.modalcontent,
                  }}>
                    To provide the best possible experience for our Zillion<br />
                    members, the maximum size of the video should not be<br />
                    more than 512MB
                  </Typography>

                  <Box>


                    <div style={props.imageUrl ? webStyle.dsiplayNone : webStyle.divAlign}>
                      <div >

                        <input
                          type="file"
                          id="fileInput"
                          ref={this.videoInputRef}
                          // accept="image/,video/mp4,video/*"
                          accept="image/,video/mp4,video/x-m4v,video/*"

                          style={{ display: 'none', }}
                          onChange={(e) => { this.handleFileInputChange(e) }}
                          data-testId="vedioInputTest"
                        />




                        <label htmlFor="fileInput">
                          <Box
                            onDrop={this.handleDrop}
                            onDragOver={this.handleDragOver}
                            style={{
                              padding: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              border: '1px dashed #ccc',
                              height: '240px',
                              display: 'flex',
                              flexDirection: "column",
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '8px',
                              borderColor: '#32569A',
                              backgroundColor: 'white',
                            }}
                          >
                            <Button

                              style={{ backgroundColor: props.isDragOver ? '#ccc' : 'inherit' }}
                            >
                              <img src={ImageDrop} width={26} height={24}></img>
                            </Button>
                            <Typography style={webStyle.Droptext}>  Drop your image here, or <span style={{ color: "#1C1C1C" }}> browse</span> </Typography>
                          </Box>

                        </label>
                      </div>
                    </div>
                    {props.imageUrl &&
                      this.browerFunction(props.imageUrl)
                    }


                  </Box>

                  <button
                    disabled={!this.state.src}
                    style={{
                      width: '100%',
                      boxShadow: 'none',
                      margin: '20px 0px',
                      textDecoration: 'none !important',
                      backgroundColor: '#1976D2',
                      color: '#ffffff',
                      border: 'none',
                      padding: '12px 24px',
                      cursor: 'pointer',
                      display: 'inline-block',
                      textAlign: 'center',
                      fontSize: '16px',
                      borderRadius: '4px',
                    }}
                    onClick={() => {
                      this.setState({
                        // textcard: true,
                        Open: true,
                        video: true,
                        isloading: true,
                      });
                      setTimeout(() => {
                        this.handleApiVedioUpload()
                      }, 1000)

                    }}
                    data-test-id="TestUploadVideoBtn"
                  >
                    Upload Video
                  </button>
                </div>
              }

              {
                this.state.switch === 2 && <>
                  <Typography style={{ fontSize: "28px", fontWeight: 500, marginTop: "20px" }}>Welcome Message</Typography>

                  <Typography style={webStyle.modalcontent}>
                    1st automated message for new <br />
                    community members.
                  </Typography>


                  <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
                    <Grid item md={1}>
                      <img src={Outlined} ></img>
                    </Grid>
                    <Grid item md={10}>
                      <Typography style={{ color: "#323548", fontSize: "14px", textAlign: "left" }}>Write a welcome note to your members</Typography>
                      <TextField

                        data-test-id="changeDescription"
                        onChange={this.handledescription}
                        fullWidth
                        multiline
                        rows={6}
                        value={this.state.welcomeNoteText}
                        placeholder="Eg:  We are the super fast growing....."
                        style={{ fontFamily: "Poppins,sans-serif" }}

                      />
                    </Grid>
                  </Grid>
                  <button
                    disabled={!this.state.welcomeNoteText}
                    test-Id="save-Message"
                    onClick={this.welcomeFunction}
                    style={{
                      margin: "20px 0px",
                      width: '100%',
                      boxShadow: 'none',
                      textDecoration: 'none !important',
                      backgroundColor: '#1976D2',
                      color: '#ffffff',
                      border: 'none',
                      padding: '12px 24px',
                      cursor: 'pointer',
                      display: 'inline-block',
                      textAlign: 'center',
                      fontSize: '16px',
                      borderRadius: '4px',
                    }}>
                    Save Message
                  </button>
                </>
              }


            </Grid>

          </div>



        }
      </div>


    )
  }

  ForNewPost = (props: any) => {
    return (
      <div style={{ ...webStyle.modalStyle, height: "auto" }} >
        <img src={CrossIcon} onClick={() => props.onClose()} style={{ width: "40px", height: "40px", position: "relative", left: "70%", marginTop: "20px", cursor: "pointer" }} />
        <Grid item md={6} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>

          <>
            <Typography style={{ ...webStyle.fontInter, fontSize: "28px", fontWeight: 500, marginTop: "20px" }}>Create a Post</Typography>

            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto", alignItems: "center" }}>
              <Grid item md={1}>
                <img src={cPicture} style={{ width: "50px", height: "50px", borderRadius: "50%" }}></img>
              </Grid>
              <Grid item md={10}>

                <Typography style={{ ...webStyle.fontInter, color: "#323548", fontSize: "14px", textAlign: "left", fontWeight: "bold", marginLeft: "2%" }}>Add picture or video</Typography>
                {
                  this.state.PostImage !== "" ?
                    <div style={{ display: "flex" }}>
                      <img src={URL.createObjectURL(this.state.PostImage)} style={{ width: "300px", height: "160px", borderRadius: "5px" }}></img>

                      <CloseRoundedIcon test-Id="PostUrl" onClick={() => this.setState({ PostImage: "" })} style={{ color: "#383838", margin: "auto 5px" }} />
                    </div>
                    :

                    <Button
                      disableRipple
                      component="label"
                      style={{ textTransform: "capitalize", fontWeight: "bold" }}
                    >
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0px 0px" }}>

                        <div style={{ ...webStyle.uploadphotobtn, width: "22rem" }}>
                          Upload +
                          <input
                            accept="image/png, image/jpg, image/jpeg,image/,video/mp4,video/x-m4v,video/*"
                            value={this.state.PostImage}
                            type="file"
                            hidden
                            name="getimage"
                            test-Id="getpostimage"
                            onChange={(e: any) => this.updateFieldChanged(e)}
                          />
                        </div>
                      </div>

                    </Button>
                }
                {this.state.postImgerror && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.postImgErrorMsg}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>


            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto", alignItems: "center" }}>
              <Grid item md={1}>
                <img src={cHeading} ></img>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ ...webStyle.fontInter, color: "#323548", fontSize: "14px", textAlign: "left", fontWeight: "bold" }}>Add Heading</Typography>
                <TextField
                  data-test-Id="PostHeading"
                  onChange={(e: any) => this.handlePostdescription(e)}
                  fullWidth
                  value={this.state.postHeading}
                  placeholder="A new world"
                  name="postHeading"
                  style={{ ...webStyle.fontInter, }}
                />
                {this.state.PostheadingError && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PostheadingErrorMsg}
                  </FormHelperText>
                )}
              </Grid>

            </Grid>

            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto", alignItems: "center" }}>
              <Grid item md={1}>
                <img src={cLink} ></img>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ ...webStyle.fontInter, color: "#323548", fontSize: "14px", textAlign: "left", fontWeight: "bold" }}>Add Link</Typography>
                <TextField
                  data-test-Id="PostLink"
                  onChange={(e: any) => this.handlePostdescription(e)}
                  fullWidth
                  value={this.state.postLink}
                  placeholder="Eg: https://www.zilion.com"
                  name="postLink"
                  style={{ ...webStyle.fontInter, }}
                />
                {this.state.PostLinkError && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PostLinkErrorMsg}
                  </FormHelperText>
                )}
              </Grid>

            </Grid>
            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
              <Grid item md={1}>
                <img src={Outlined} ></img>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ ...webStyle.fontInter, color: "#323548", fontSize: "14px", textAlign: "left", fontWeight: "bold" }}>Add Description</Typography>
                <TextField
                  data-test-Id="PostDesc"
                  onChange={(e: any) => this.handlePostdescription(e)}
                  fullWidth

                  multiline
                  rows={4}
                  value={this.state.postDescription}
                  placeholder="Eg: We are the super fast growing....."
                  name="postDescription"
                  style={{ ...webStyle.fontInter, }}
                />
                {this.state.PosDescError && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PostDescErrorMsg}
                  </FormHelperText>
                )}
              </Grid>

            </Grid>

            <Box>
              <Typography variant="subtitle1" style={{ margin: "10px 0px", textAlign: "start" }}>Post type</Typography>
              <Box sx={webStyle.ButtonGroup}>
                <Button variant="outlined" name="free" onClick={this.handleactive} style={{
                  ...webStyle.fontInter,
                  // borderRadius: "20px", 
                  fontWeight: 600, color: "#FFF", backgroundColor: this.state.active === 1 ? "rgba(62, 1, 185, 1)" : "", border: this.state.active === 1 ? "" : "1px solid white", textTransform: "capitalize", fontSize: "20px", borderRadius: "50px"
                }} >Free</Button>


                <Button variant="outlined" test-Id="handlePricing" onClick={this.handleactive} style={{
                  ...webStyle.fontInter,
                  fontWeight: 600, color: "#FFF", backgroundColor: this.state.active === 2 ? "rgba(62, 1, 185, 1)" : "", border: this.state.active === 2 ? "" : "1px solid white", textTransform: "capitalize", fontSize: "20px", borderRadius: "50px"
                }} >Members</Button>

                <Button variant="outlined" test-Id="onDemandTest" onClick={this.handleactive} style={{ ...webStyle.fontInter, fontWeight: 600, color: "#FFF", backgroundColor: this.state.active === 3 ? "rgba(62, 1, 185, 1)" : "", border: this.state.active === 3 ? "" : "1px solid white", textTransform: "capitalize", fontSize: "20px", borderRadius: "50px" }}>On-Demand</Button>
              </Box>
            </Box>
            <Box>
              {
                this.state.pricing && (

                  this.AllCards(this.state.Pricingcards)

                )
              }
            </Box>

            <Box>{this.state.onDemandState && this.onDemandFn()}</Box>
            <button
              test-Id="PostNow"
              color="primary"
              onClick={() => this.handlepostbutton()}
              style={{
                ...webStyle.fontInter,
                // width: '35%',
                boxShadow: 'none',
                margin: '20px 0px',
                textDecoration: 'none !important',
                backgroundColor: '#3E01B9',
                color: '#ffffff',
                border: 'none',
                padding: "10px 20px",
                cursor: 'pointer',
                display: 'inline-block',
                textAlign: 'center',
                fontSize: '16px',
                borderRadius: '4px',
                width: '100%',
                fontWeight: 600,
              }}
            >
              Post Now
            </button>
          </>
        </Grid>

      </div>
    )
  }

  AllCards = (props: any) => {
    return (
      <div
        className="secondMemberCard"
        style={{
          display: "grid",
          gridTemplateColumns: " repeat(2, 1fr)",
          gridColumnGap: "8px",
          height: "30%",
        }}
      >

        {this.state.Pricingcards.map((data: any, index: any) => {
          return (
            <Box
              key={index}
              test-Id="Pricingcards"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: "#3E01B9",
                color: "#FFF",
                borderRadius: "5px",
                marginTop: "8px",
                padding: "25px",
              }}
            >
              <div>
                <Typography style={webStyle.Postcardstypo}>
                  {data.attributes?.name}
                </Typography>
              </div>
              <div>
                <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                  ${data.attributes?.yearly_price}
                  <span style={{ fontWeight: 400, fontSize: "10px" }}>{data.planText}</span>
                </Typography>
              </div>
              <div style={{ alignSelf: "flex-end", }}>
                <input
                  type="checkbox"
                  checked={data.selected}
                  onChange={() => this.handlePostCards(data.selected, index)}
                  style={data.selected ? { accentColor: "#b300b3", color: "white", border: "1px solid #b300b3", borderRadius: "5px" } : { accentColor: "black", color: "", border: "", borderRadius: "5px" }}
                />
              </div>
            </Box>

          );
        })}
      </div>
    );
  };

  onDemandFn = () => {
    return (
      <div style={{ marginTop: "12px", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: "2rem", justifyContent: "start", marginBottom: "2rem" }}>
          <div>
            <img src={dollar} style={{ width: "20px", height: "20px" }}></img>
          </div>
          <div>
            <Typography style={{ textAlign: "left", fontSize: "14px", ...webStyle.fontMontserrat }}>Add Pricing</Typography>
            <input
              type="text"
              placeholder="Add your price value"
              value={this.state.priceDemand}
              style={{ background: "none", color: "white", border: "none", fontSize: "18px", outline: "none", ...webStyle.fontMontserrat }}
              onChange={(e: any) => this.handleOndemandInput(e)}
              data-test-id="onDemandPriceTest"
              name="OndemandPrice"
            />
          </div>
        </div>
        <span
          style={{
            transform: "translateY(-50%)",
            borderTop: "1px solid #ccc",
            width: "100%",
            color: "#a6a6a6",
            marginBottom: "1rem", ...webStyle.fontMontserrat
          }}
        />
        <div style={{ display: "flex", gap: "2rem", justifyContent: "start", marginBottom: "2rem" }}>
          <div>
            <img src={tick2} style={{ width: "20px", height: "20px" }}></img>
          </div>
          <div>
            <Typography style={{ textAlign: "left", fontSize: "14px", ...webStyle.fontMontserrat }}>Add a discount offer</Typography>
            <input type="text" placeholder="Add % discount value" style={{ background: "none", color: "white", border: "none", fontSize: "18px", outline: "none", ...webStyle.fontMontserrat }} value={this.state.discountDemand}
              onChange={(e: any) => this.handleOndemandInput(e)} name="discount" />
          </div>
        </div>
        <div style={{ marginLeft: "12%" }}>
          <Typography style={{ textAlign: "left", fontSize: "14px", marginBottom: "1rem" }}>Offer expiry time</Typography>
          {/* Check Box */}
          <div style={{ display: "flex", gap: "0.5em", alignItems: "baseLine" }}>
            <div>
              <input
                type="checkbox"
                checked={this.state.neverExpireCheck == "never_expire"}
                onChange={() => this.handleCheckboxChange('never_expire')}
                style={{ accentColor: "#D9D9D9", color: "#34009E" }}
                name="neverExpireCheck"
              />
            </div>
            <div><Typography style={{ color: "rgba(255, 255, 255, 0.32)", fontSize: "18px", marginBottom: "1rem", ...webStyle.fontMontserrat }}>Never Expire</Typography></div>
          </div>
          {/* Check Box */}
          <div style={{ display: "flex", gap: "0.5em", alignItems: "baseLine" }}>
            <div>
              <input
                type="checkbox"
                checked={this.state.neverExpireCheck == "custom_time"}
                onChange={() => this.handleCheckboxChange('custom_time')}
                style={{ accentColor: "#D9D9D9", color: "#34009E" }}
                name="customizeTime"
              />

            </div>

            <div><Typography style={{ color: "rgba(255, 255, 255, 0.32)", fontSize: "18px", marginBottom: "1rem", ...webStyle.fontMontserrat }}>Custom Time</Typography></div>

          </div>
          {/* If customise time is checked then show this */}
          {this.state.neverExpireCheck == "custom_time" && (
            <>
              <div>
                <Typography style={{ textAlign: "left", marginBottom: "1rem", fontSize: "14px", ...webStyle.fontMontserrat }}>Discount Expiry Date</Typography>
              </div>
              <div style={{ marginLeft: "-15%" }}>
                {/* Date Input */}
                <div
                  style={{
                    position: "relative",
                    borderRadius: "8px", // Rounded borders
                    display: "inline-block",
                    marginBottom: "1rem",
                    marginLeft: "-10%"
                  }}
                >
                  <input
                    type="text"
                    value={this.state.date.day}
                    name="day"
                    onChange={this.handleInputChange}
                    placeholder="DD"
                    maxLength={2}
                    style={{
                      border: "none",
                      // borderRadius: "8px 0 0 8px",
                      borderRadius: "40px 0 0 40px",
                      padding: "8px",
                      // width: "40px",
                      width: "90px",
                      background: "var(--Off-black, #282828)",
                      color: "white",
                      boxSizing: "border-box",
                      textAlign: "center", ...webStyle.fontMontserrat
                    }}
                  />
                  <input
                    ref={this.monthInput}
                    type="text"
                    value={this.state.date.month}
                    name="month"
                    onChange={this.handleInputChange}
                    placeholder="MM"
                    maxLength={2}
                    style={{
                      border: "none",
                      padding: "8px",
                      // width: "40px",
                      width: "90px",
                      background: "var(--Off-black, #282828)",
                      color: "white",
                      boxSizing: "border-box",
                      textAlign: "center", ...webStyle.fontMontserrat
                    }}
                  />
                  <input
                    ref={this.yearInput}
                    type="text"
                    value={this.state.date.year}
                    name="year"
                    onChange={this.handleInputChange}
                    placeholder="YYYY"
                    maxLength={4}
                    style={{
                      border: "none",
                      // borderRadius: "0 8px 8px 0",
                      borderRadius: "0 40px 40px 0",
                      padding: "8px",
                      // width: "60px",
                      width: "130px",
                      background: "var(--Off-black, #282828)",
                      color: "white",
                      boxSizing: "border-box",
                      textAlign: "center", ...webStyle.fontMontserrat
                    }}
                  />
                </div>
                <div style={{ marginBottom: "1rem", marginLeft: "-60%", ...webStyle.fontMontserrat }}><Typography style={{ fontSize: "14px", }}>End Time</Typography></div>
                {/* select time */}
                <div style={{ marginBottom: "1rem", marginLeft: "-50%", position: 'relative', display: 'inline-block'}}>
                  <select
                    style={{
                      borderRadius: "40px",
                      background: "var(--Off-black, #282828)",
                      color: "white", border: "none",
                      // padding: "8px 16px",
                      padding: "8px 24px 8px 16px", ...webStyle.fontMontserrat,
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                      textIndent: "1px",
                      textOverflow: '',
                      backgroundSize: "1.5em",
                      backgroundPosition: "right center",
                      backgroundRepeat: "no-repeat",
                    }}
                    value={this.state.selectedTime}
                    onChange={this.handleSelectChange}
                  >
                    {this.state.timeOptions.map((option) => (
                      <option key={option} value={option} style={{textAlign: "center",}}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                  <span style={{
                    position: 'absolute',
                    top: '50%',
                    right: '1px',
                    transform: 'translateY(-50%)',
                  }}
                  >
                    <img src={downArrow} />
                  </span>
                </div>
              </div>
            </>
          )
          }
        </div>
      </div>
    )
  }

  updateFieldChanged = (e: any) => {

    this.setState({ PostImage: e.target.files[0], postImgerror: false, postImgErrorMsg: "" }, () => console.log("image", this.state.PostImage))
  }

  handleClosepostmodal = () => {
    this.setState({ postModal: false, Open: false })
  }

  handlePostdescription = (event: any) => {
    let name = event.target.name
    let value = event.target.value

    if (name === "postHeading") {
      this.setState({
        PostheadingError: false,
        PostheadingErrorMsg: "",
        postHeading: value,
      })
    }
    if (name === "postDescription") {
      this.setState({
        PosDescError: false,
        PostDescErrorMsg: "",
        postDescription: value,
      })
    }
    if (name === "postLink") {
      this.setState({
        PostLinkError: false,
        PostLinkErrorMsg: "",
        postLink: value,
      })
    }
    this.Bulkvalidation()
    this.setState((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  handlePostCards = (event: any, index: any) => {
    const updatecards = [...this.state.Pricingcards];
    updatecards[index].selected = !updatecards[index].selected;
    this.setState({ Pricingcards: updatecards })

  }
  handleClose = () => {
    this.setState({
      Open: false, isDragOver: "",
      dataFile: {},
      imageUrl: "",
      files: "",
      video: false
    });

  }

  Bulkvalidation = () => {
    let error = false;
    if (!regexUrl.test(this.state.postLink)) {
      error = true;
      this.setState({
        PostLinkError: true,
        PostLinkErrorMsg: "Please enter valid url",
      })

    }
    if (this.state.PostImage === "") {
      error = true;
      this.setState({
        postImgerror: true,
        postImgErrorMsg: "please select the image"
      })
    }
    if (this.state.postDescription === "") {
      error = true;
      this.setState({
        PosDescError: true,
        PostDescErrorMsg: "please enter the description"
      })
    }
    if (this.state.postLink === "") {
      error = true;
      this.setState({
        PostLinkError: true,
        PostLinkErrorMsg: "please enter the link"
      })
    }
    if (this.state.postHeading === "") {
      error = true;
      this.setState({
        PostheadingError: true,
        PostheadingErrorMsg: "please enter the heading"
      })
    }

    let x = this.state.Pricingcards.reduce(function (a: any, e: any, i: any) {
      if (e.selected)
        a.push(e.Price);
      return a;
    }, []);
    if (this.state.active == 2 && this.state.pricing && x.length <= 0) {
      error = true;
      this.setState({ memberErrorState: true, memberError: "Please select a plan" })
    }

    if (this.validationOnDemand()) {
      error = true;
      this.setState({ ondemandErrorState: true, ondemanError: "Please enter valid details" })
    }
    if (this.discountValidation()) {
      error = true;
      this.setState({
        discountError: true,
        discountErrorMessage: "Please enter a discount",
      })

    }

    if (this.dateValidation()) {
      let { date } = this.state;
      let z = `${date.day}${date.month}${date.year}`

      if (this.state.selectedTime.length == 0 || z.length == 0) {
        error = true;
        this.setState({
          discountError: true,
          discountErrorMessage: "Please enter a discount",
        })
      }
    }

    return error;

  }
  handleactive = (event: any) => {
    let name = event?.target.textContent
    if (name === "Free") {
      this.setState({ active: 1 })
    }

    if (name === "Members") {

      this.setState({ active: 2, pricing: true })
      // this.handlApiSubscriptionsGet()
    } else {
      this.setState({ pricing: false })
    }
    if (name === "On-Demand") {
      this.setState({ active: 3, onDemandState: true })
    } else {
      this.setState({ onDemandState: false })
    }
  }

  handlepostbutton = () => {
    let error = this.Bulkvalidation()

    if (!error) {
      let newdata = {
        Heading: this.state.postHeading,
        Description: this.state.postDescription,
        Postlink: this.state.postLink,
        image: this.state.PostImage
      }
      let updated = [...this.state.CardDetails, newdata]

      this.setState({ CardDetails: updated })
      let x = [];
      if (this.state.active == 2) {
        x = this.state.Pricingcards.reduce(function (a: any, e: any, i: any) {
          if (e.selected)
            a.push(e.Price);
          return a;
        }, []);
      }

      let y = {};
      if (this.state.active == 3) {
        let { date } = this.state;
        y = { priceDemand: this.state.priceDemand, discount: this.state.discountDemand, offer_expiry_time: this.state.neverExpireCheck, discount_expiry_date: `${date.day}/${date.month}/${date.year}`, discount_end_time: this.state.selectedTime }
      }
      let new2data = { ...newdata, plan_id: [...x], active: this.state.active, ...y }

      this.handleApiCreatePost(new2data)
      this.setState({
        postHeading: "", postDescription: "", postLink: "", PostImage: "", priceDemand: "", discountDemand: "", neverExpireCheck: "", selectedTime: "", date: { day: "", month: "", year: "" },
        Pricingcards: this.state.Pricingcards.map((ele: any) => {
          ele.selected = false;
          return ele;
        })
      })
      this.handleClosepostmodal()
    }

  }

  handleOpen = (event: any, index: any, isCompleted: any) => {
    if (index === 0 || isCompleted) {
      this.setState({ Open: true, switch: event, })
    }
  }

  handleUpdateCoverimage = () => {

  }
  handledescription = (e: any) => {
    this.setState({ welcomeNoteText: e.target.value })
  }
  handleAddCoverImage = (e: any) => {

    let newcover = e.target.files[0]


    this.setState({ coverPhotos: [...this.state.coverPhotos, { Img: newcover }] })
  }

  handleFileInputChange = async (event: any) => {
    const file = event.target?.files[0];

    if (file.size <= 530 * 1024000) {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      const objectUrl = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const videoDataUrl = e.target?.result;

        const videob = this.browseRef.current;
        videob.src = videoDataUrl;

        this.canvasRef.current.width = 200;
        this.canvasRef.current.height = 200;

        const context = this.canvasRef.current.getContext('2d');
        context.drawImage(video, 0, 0, this.canvasRef.current.width, this.canvasRef.current.height);

        const thumbDataUrl = this.canvasRef.current.toDataURL('image/png');

        this.setState({ browserThumbnail: thumbDataUrl });

      };

      reader.readAsDataURL(file);

      this.setState({
        imageUrl: file,
        videoThumb: objectUrl,
        src: URL.createObjectURL(file),
      })
    }

  };
  handleDragOver = (event: any) => {
    event.preventDefault();
  };
  handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];

    this.setState({
      imageUrl: file,
      src: URL.createObjectURL(file)
    })
  };




  handleanchorEl = () => {
    this.setState({
      anchorEl: null
    })
  }
  handleanchorElopen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleanchorElOptions = () => {
    this.setState({ anchorEl: null, switch: 2, Open: true })
  }

  postDatapricing = (postData: any) => {
    let result;

    // postData.active == 1 ? "free" : postData.active == 2 ? "members" : "ondemand"


    if (postData.active == 1) {
      result = "free";
    } else if (postData.active == 2) {
      result = "members";
    } else {
      result = "ondemand";
    }

    return result;
  }


  // web events
  ///////////////////////////////////////

  handlePublishClose = () => {
    this.setState({ publishing: false });
  }
  handlePublishNow = () => {
    this.setState({ publishing: true })
  }
  handleApiCreatePost = async (postData: any) => {
    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };

    let data = {
      "heading": postData.Heading,
      "plan_description": postData.Description,
      "link": postData.Postlink,
      "community_forum_id": id,

      "pricing": this.postDatapricing(postData),   //ondemand , members, free
      "posts[]": postData.image,

      // on-demand


    }
    let x = {}
    if (postData.active == 2) {
      //members
      x = { "plan_id": JSON.stringify(postData.plan_id) }
    }
    if (postData.active == 3) {
      x = {
        "price": postData.priceDemand,
        "discount": postData.discount,
        "offer_expiry_time": postData.offer_expiry_time ? postData.offer_expiry_time : "",  //never_expire , custom_time
        "discount_expiry_date": postData.discount_expiry_date,
        "discount_end_time": postData.discount_end_time,
      }
    }

    const formData = new FormData();

    for (let [key, value] of Object.entries({ ...data, ...x })) {
      formData.append(key, value);
    }

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyCreatePost = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.createPostEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleOndemandInput = (e: any) => {

    const { name, value } = e.target

    if (name === "OndemandPrice") {

      const numericValue = value.replace(/\D/g, '');

      this.setState({ priceDemand: numericValue })
    }
    if (name === "discount") {
      const numericValue = value.replace(/\D/g, '');
      this.setState({ discountDemand: numericValue })
    }
  }

  handleCheckboxChange = (value: string) => {
    if (this.state.neverExpireCheck == value) {
      this.setState({
        neverExpireCheck: "",
      })
      if (value == "custom_time") {
        this.setState({
          date: {
            day: "",
            month: "",
            year: "",
          },
          selectedTime: ""
        })
      }
      return;
    }
    if (value == "never_expire") {
      this.setState({
        date: {
          day: "",
          month: "",
          year: "",
        },
        selectedTime: "",
      })
    }
    this.setState({
      neverExpireCheck: value,

    });
  }

  welcomeFunction = () => {
    this.setState({ textcard: true, Open: false });
    this.handleApiWelcomeNote();

  }
  browerFunction = (imageUrl: any) => {
    return (
      <div style={{ ...webStyle.divImg, position: 'relative' }}>
        <div style={{ display: isChrome ? '' : 'none' }}>
          <video ref={this.browseRef} width="300" height="200" />
          <canvas ref={this.canvasRef} style={{ display: 'none' }} />
        </div>
        {this.state.browserThumbnail &&
          <img
            src={URL.createObjectURL(imageUrl)}
            alt="file"
            style={{
              ...webStyle.AlignImg, position: 'relative', top: 0, left: 0,
              display: isChrome ? 'none' : '',
            }} />
        }

      </div>
    )
  }
  handleWelcomeNoteDelete = () => {
    this.setState({ welcomeNoteText: "", textcard: false, anchorEl: false })
  }
  toggleReadMore = () => {
    this.setState({ expanded: !this.state.expanded });
  };


  handlApiCommunityGet = async () => {
    const token = await getStorageData("authToken");
    const id = await getStorageData("CommunityForumId");

    const header = {
      token: token,
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyCommunity = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.communitySetupEndPoint + `/${id}`
    );
    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleApiVedioUpload = async () => {
    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };
    const data = {
      "community_forum_id": id,
      "video": this.state.imageUrl
    }

    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );



    this.keyUploadVedio = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.vedioUploadEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleApiWelcomeNote = async () => {
    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };
    const data = {
      "community_forum_id": id,
      "welcome_note": this.state.welcomeNoteText
    }

    const formData = new FormData();

    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyWelocomeNote = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.welcomeNoteEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleApiCancelVedio = async () => {

    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };
    const formData = new FormData();
    formData.append("id", id)

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyCancelVedio = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.vedioCancelEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleVedioControls = () => {
    const video = this.vedioControlRef?.current
    if (!video) return;
    if (this.state.isPlaying) {
      video.play();
    } else {
      video.pause();
    }

    this.setState({ isPlaying: !this.state.isPlaying });
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;

    // Character limit logic
    const maxLength = name === 'year' ? 4 : 2;
    const truncatedValue = value.slice(0, maxLength);

    this.setState((prevState) => ({
      date: {
        ...prevState.date,
        [name]: truncatedValue,
      },
    }));

    // Automatic field switching logic
    if (truncatedValue.length === maxLength) {
      switch (name) {
        case 'day':
          this.focusInput(this.monthInput);
          break;
        case 'month':
          this.focusInput(this.yearInput);
          break;
        default:
          break;
      }
    }
  };

  focusInput = (inputRef: React.RefObject<HTMLInputElement> | null) => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  updateTimeOptions = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTime = new Date().toLocaleTimeString([], {
      timeZone: userTimeZone,
      hour12: true,
    });

    // Generate time options for every 1 hour in both AM and PM
    const startTimeAM = new Date();
    startTimeAM.setHours(0, 0, 0, 0);

    const startTimePM = new Date();
    startTimePM.setHours(12, 0, 0, 0);

    const timeOptions = ["Select Time"];
    for (let i = 0; i < 12; i++) {
      const timeAM = new Date(startTimeAM.getTime() + i * 60 * 60 * 1000);
      const formattedTimeAM = timeAM.toLocaleTimeString([], {
        timeZone: userTimeZone,
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      timeOptions.push(formattedTimeAM);
    }

    for (let i = 0; i < 12; i++) {
      const timePM = new Date(startTimePM.getTime() + i * 60 * 60 * 1000);
      const formattedTimePM = timePM.toLocaleTimeString([], {
        timeZone: userTimeZone,
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      timeOptions.push(formattedTimePM);
    }

    this.setState({
      selectedTime: currentTime,
      timeOptions,
    });
  };

  handleSelectChange = (e: any) => {
    this.setState({
      selectedTime: e.target.value,
    });
  }

  handlApiSubscriptionsGet = async () => {
    const token = await getStorageData("authToken");
    const id = await getStorageData("CommunityForumId");

    const header = {
      token: token,
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keysubscriptionPlans = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.subscriptionEndPoint + `=${id}`
    );
    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }



  // Customizable Area End
}
