import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled
} from "@material-ui/core";
import {
  Home,
  Messages,
  SettingsImg,
  Events,
  Communities,
  Srarch,
} from "./assets";

const CustomText = styled(ListItemText)({
  '& .MuiTypography-displayBlock': {
    fontSize: 20
  },
  '& .MuiTypography-body1': {
    fontFamily: "Montserrat"
  }
})
const CustomTextDrk = styled(ListItemText)({
  '& .MuiTypography-displayBlock': {
    fontSize: 20,
    fontWeight: 700,
  }
})
const CustomList = styled(List)({
  '& .MuiListItem-root:hover': {
    background: '#3F3F3F3B',
    cursor: 'pointer',
    borderRadius: "10px",
    '& .MuiTypography-displayBlock': {
      fontWeight: 700,
    },
  },
})
const CustomBox = styled(Box)({
  width: "295px",
  '@media (min-width: 600px) and (max-width: 1000px)': {
    width: '72px !important'
  },
  '@media (min-width: 0px) and (max-width: 600px)': {
    width: '295px !important',
    position: "fixed",
    height: "100%",
    zIndex: 100,
  },
})

const BlurBox = styled(Box)({
  display: "none",
  '@media (max-width: 600px)': {
    position: "absolute",
    top: "0px",
    left: "0px",
    backgroundColor: "black",
    width: '100vw',
    height: "100vh",
    opacity: 0.5,
    zIndex: 1,
    display: "block",
  }
})


// Customizable Area End

import NavigationMenuController, {
  Props
} from "./NavigationMenuController";

export default class MemberNavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Box >
        {this.props.open === "0px" && <BlurBox style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundColor: "black",
          width: '100vw',
          height: "100vh",
          opacity: 0.5,
          zIndex: 1
        }}
        onClick={this.props.handleCloseSideBar}
        ></BlurBox>}
        <CustomBox style={{ ...webStyle.menu, left: this.props.open, top: "0px" }} onClick={this.handleOpenSideBar
        }>
          <CustomList style={webStyle.menu_ul}>
            <ListItem
              data-testid="test_home"
              style={window.location.pathname.includes("Home") ? webStyle.menu_li_active : webStyle.menu_li}
              onClick={() => { this.memberNavigatetoHome() }}
            >
              <ListItemIcon style={webStyle.list_icon}>
                <img src={Home} style={webStyle.drawerItemIcon} />
              </ListItemIcon>
              {window.location.pathname.includes("Home")
                ? <CustomTextDrk style={webStyle.drawerItemtext}>Home</CustomTextDrk>
                : <CustomText style={webStyle.drawerItemtext}>Home</CustomText>}
            </ListItem>
            <ListItem
              data-testid="test_community"
              style={window.location.pathname.includes("Communities") ? webStyle.menu_li_active : webStyle.menu_li}
              onClick={() => { this.memberNavigatetoCommunities() }}
            >
              <ListItemIcon style={webStyle.list_icon}>
                <img src={Communities} style={webStyle.drawerItemIcon} />
              </ListItemIcon>
              {window.location.pathname.includes("Communities")
                ? <CustomTextDrk style={webStyle.drawerItemtext}>Communities</CustomTextDrk>
                : <CustomText style={webStyle.drawerItemtext}>Communities</CustomText>}
            </ListItem>
            <ListItem
              data-testid="test_messages"
              style={window.location.pathname.includes("Messages") ? webStyle.menu_li_active : webStyle.menu_li}
              onClick={() => { this.memberNavigatetoMessage() }}
            >
              <ListItemIcon style={webStyle.list_icon}>
                <img src={Messages} style={webStyle.drawerItemIcon} />
              </ListItemIcon>
              {window.location.pathname.includes("Messages")
                ? <CustomTextDrk style={webStyle.drawerItemtext}>Messages</CustomTextDrk>
                : <CustomText style={webStyle.drawerItemtext}>Messages</CustomText>}
            </ListItem>
            <ListItem
              data-testid="test_events"
              style={window.location.pathname.includes("Events") ? webStyle.menu_li_active : webStyle.menu_li}
              onClick={() => { this.memberNavigatetoEvents() }}
            >
              <ListItemIcon style={webStyle.list_icon}>
                <img src={Events} style={webStyle.drawerItemIcon} />
              </ListItemIcon>
              {window.location.pathname.includes("Events")
                ? <CustomTextDrk style={webStyle.drawerItemtext}>Events</CustomTextDrk>
                : <CustomText style={webStyle.drawerItemtext}>Events</CustomText>}
            </ListItem>
            <ListItem
              data-testid="test_discover"
              style={window.location.pathname.includes("Discover") ? webStyle.menu_li_active : webStyle.menu_li}
              onClick={() => { this.memberNavigatetoDiscover() }}
            >
              <ListItemIcon style={webStyle.list_icon}>
                <img src={Srarch} style={webStyle.drawerItemIcon} />
              </ListItemIcon>
              {window.location.pathname.includes("Discover")
                ? <CustomTextDrk style={webStyle.drawerItemtext}>Discover</CustomTextDrk>
                : <CustomText style={webStyle.drawerItemtext}>Discover</CustomText>}
            </ListItem>
            <ListItem
              data-testid="test_settings"
              style={window.location.pathname.includes("Settings") ? webStyle.menu_li_active : webStyle.menu_li}
              onClick={() => { this.memberNavigatetoSettings() }}
            >
              <ListItemIcon style={webStyle.list_icon}>
                <img src={SettingsImg} style={webStyle.drawerItemIcon} />
              </ListItemIcon>
              {window.location.pathname.includes("Settings")
                ? <CustomTextDrk style={webStyle.drawerItemtext}>Settings</CustomTextDrk>
                : <CustomText style={webStyle.drawerItemtext}>Settings</CustomText>}
            </ListItem>
          </CustomList>
        </CustomBox>
      </Box>
    );
  }
}



const webStyle = {
  drawerItemIcon: {
    width: 32,
    height: 32,
    selfAlign: "center",
    marginLeft: 7,
  },
  drawerItemtext: {
    color: "#FFFFFF",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: "0em",
  } as const,
  drawerItemtext_hover: {
    color: "#FFFFFF",
    fontWeight: 700,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: "0em",

  } as const,
  menu: {
    backgroundColor: "#222222",
    width: "295px",
    transition: "left 0.3s"
  },
  menu_ul: {
    display: "flex",
    flexDirection: "column" as any,
    alignItems: "center",
    height: "100%",
    padding: 0
  },
  menu_li: {
    maxWidth: "245px",
    height: "57px",
  },
  menu_li_active: {
    background: '#3F3F3F3B',
    cursor: 'pointer',
    maxWidth: "245px",
    height: "57px",
    borderRadius: "10px",
  },
  list_icon: {
    minWidth: '45px'
  }
};
