import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  // Customizable Area End
}

interface S {
  activeStep: number
  // Customizable Area Start
  errors: boolean;
  errorMsg: string;
  data : {
    id: number,
    type: string,
    attributes: {
        id: number,
        heading: string,
        description: string,
        position: string,
        created_at: any,
        video?: string | null,
        image?: string | null,
        logo : string
    }
}[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  mainCarouselDataID: any;
  // Customizable Area Ends

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      activeStep: 0,
      // Customizable Area Start
      errors: false,
      errorMsg: '',
      data: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.mainCarouselDataID) {
          this.handleMainCarouselResponse(responseJson);
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getMainCarousel()
  }
  handleStepChange = (step: number) => {

    this.setState({ activeStep: step })
  };

  getMainCarousel() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.mainCarouselDataID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mainCarouselAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMainCarouselResponse = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      this.setState({data : responseJson.data})
    }
  }
  // Customizable Area End
}
