import React from "react";

import {
    // Customizable Area Start
    Typography,
    Grid,
    Box,
    Button,
    ButtonProps,
    styled,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import FooterController, { Props } from "./FooterController.web";
import { zilion } from "../assets";

// Customizable Area End
interface IndividualI {
    id: number, heading: string, description: string, image: string
}
// Customizable Area Start
const theme = createTheme({
    typography: {
        fontFamily: ["Montserrat"].join(",")
    }
});


const FooterButtonLinks = styled(Button)<ButtonProps>(() => ({
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: 0
}));






// Customizable Area End

export default class Footer extends FooterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            <Box style={{ ...webStyle.wrapper, backgroundColor: "#282828", }}>
                <ThemeProvider theme={theme}>
                    <Grid container style={webStyle.FooterContainer}>
                        <Grid item xs={12} sm={4} >
                            <Typography
                                variant="h6"
                                component="h6"
                                style={webStyle.FooterLogoSection}
                            >
                                <img width="32px" height="32px" src={zilion} alt={"zillion logo"} />
                                About Zillon
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: "14px"
                                }}
                            >
                                Becoming a creator just takes less than 5 minutes and earning your 1st
                                dollar is just a share away.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Typography
                                variant="h6"
                                component="h6"
                                style={webStyle.FooterLinkSections}
                            >
                                Navigate
                            </Typography>
                            <Box display={"flex"}>
                                <FooterButtonLinks
                                    onClick={() => this.handleFooterNavigation('aboutUs')}
                                >About us</FooterButtonLinks>
                                <FooterButtonLinks style={{ marginLeft: "32px" }}
                                    onClick={() => this.handleFooterNavigation('faq')}
                                >
                                    FAQ
                                </FooterButtonLinks>
                            </Box>
                            <Box display={"flex"} marginTop={"16px"}>
                                <FooterButtonLinks
                                    onClick={() => this.handleFooterNavigation('forCreator')}
                                >For Creator</FooterButtonLinks>
                                <FooterButtonLinks style={{ marginLeft: "32px" }}
                                    onClick={() => this.handleFooterNavigation('contactUs')}
                                >
                                    Contact Us
                                </FooterButtonLinks>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Typography
                                variant="h6"
                                component="h6"
                                style={webStyle.FooterLinkSections}
                            >
                                Social Media
                            </Typography>
                            <Box display={"flex"}>
                                <FooterButtonLinks>Facebook</FooterButtonLinks>
                                <FooterButtonLinks style={{ marginLeft: "32px" }}>
                                    LinkedIn
                                </FooterButtonLinks>
                            </Box>
                            <Box display={"flex"} marginTop={"16px"}>
                                <FooterButtonLinks>Instagram</FooterButtonLinks>
                                <FooterButtonLinks style={{ marginLeft: "32px" }}>
                                    Twitter
                                </FooterButtonLinks>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                paddingTop={"32px"}
                                style={{ opacity: "0.5" }}
                            >
                                <FooterButtonLinks>© Zilion 2023 - 2024</FooterButtonLinks>
                                <FooterButtonLinks style={{ marginLeft: "32px" }}>
                                    Site Map
                                </FooterButtonLinks>
                            </Box>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Box>
        );
    }

}


// Customizable Area Startheading
const webStyle = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        boxSizing: 'border-box'
    } as React.CSSProperties,
    FooterContainer: {
        color: "#fff",
        padding: "16px 128px 16px 128px",
        maxWidth: '1280px'
    },
    FooterLogoSection: {
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        fontSize: "14px"
    },
    FooterLinkSections: {
        opacity: "0.5",
        fontSize: "14px",
        marginBottom: "24px"
    },
};
// Customizable Area End
