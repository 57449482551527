export const image_01 = require("../assets/image_01.png");
export const image_02 = require("../assets/image_02.png");
export const image_03 = require("../assets/image_03.png");
export const image_04 = require("../assets/image_04.png");
export const image_05 = require("../assets/image_05.png");
export const community = require("../assets/community.png");
export const logo1690 = require("../assets/logo1690.png");
export const discount = require("../assets/discount.png");
export const listen = require("../assets/listen.png");
export const zilion = require("../assets/Zilion.png");
export const creativity = require("../assets/creativity.png")
export const mycommunity = require("../assets/mycommunity.png")
export const category = require("../assets/category.png")
export const testimonialProfile = require("../assets/testimonialProfile.png")
export const SignupBannerLeft = require("../assets/Signup-banner-left.png")
export const SignupBannerRight = require("../assets/Signup-banner-right.png")
export const aboutUsArrow = require("../assets/aboutUsArrow.png");
export const aboutZillion = require("../assets/aboutZillion.jpg");
export const about1 = require("../assets/about1.png");
export const about2 = require("../assets/about2.png");
export const about3 = require("../assets/about3.png");
export const connectSection = require("../assets/connectSection.png");
export const fansSec = require("../assets/fansSec.png");
export const profile = require("../assets/profile_icon.png");
export const ornament = require("../assets/ornament.png");
export const mainCover = require("../assets/mainCover.jpg");
export const coverLogo = require("../assets/coverLogo.png");
export const Info = require("../assets/Info.png");
export const background_1 = require("../assets/background_1.png");
export const education = require("../assets/education.png");
export const travel = require("../assets/travel.png");
export const art = require("../assets/art.png");
export const technology = require("../assets/technology.png");
export const fitness = require("../assets/fitness.png");
export const medical = require("../assets/medical.png");
export const leftarrow = require("../assets/leftarrow.png");
export const Cardimage1 = require("../assets/Cardimage1.png");
export const Cardimage2 = require("../assets/Cardimage2.png");
export const Cardimage3 = require("../assets/CardImage3.png");
export const Cardimage4 = require("../assets/Cardimage1.png");
export const Cardimage5 = require("../assets/Cardimage2.png");
export const Cardimage6 = require("../assets/CardImage3.png");
export const Cardimage7 = require("../assets/Cardimage1.png");
export const Cardimage8 = require("../assets/Cardimage2.png");
export const Cardimage9 = require("../assets/CardImage3.png");
export const FavIcon1 = require("../assets/FavIcon1.png");
export const FavIcon2 = require("../assets/FavIcon2.png");
export const FavIcon3 = require("../assets/FavIcon3.png");
export const FavIcon4 = require("../assets/FavIcon1.png");
export const FavIcon5 = require("../assets/FavIcon2.png");
export const FavIcon6 = require("../assets/FavIcon3.png");
export const FavIcon7 = require("../assets/FavIcon1.png");
export const FavIcon8 = require("../assets/FavIcon2.png");
export const FavIcon9 = require("../assets/FavIcon3.png");
export const membersicon = require("../assets/members.png");
export const anime1 = require("../assets/anime1.png");
export const anime2 = require("../assets/anime2.png");
export const anime3 = require("../assets/anime1.png");
export const anime4 = require("../assets/anime2.png");
export const anime5 = require("../assets/anime1.png");
export const anime6 = require("../assets/anime2.png");
export const anime7 = require("../assets/anime1.png");
export const anime8 = require("../assets/anime2.png");
export const anime9 = require("../assets/anime1.png");
export const cover1 = require("../assets/cover1.png");
export const cover2 = require("../assets/cover2.png");
export const cover3 = require("../assets/cover3.png");
export const cover4 = require("../assets/cover1.png");
export const cover5 = require("../assets/cover2.png");
export const cover6 = require("../assets/cover3.png");
export const cover7 = require("../assets/cover1.png");
export const cover8 = require("../assets/cover2.png");
export const cover9 = require("../assets/cover3.png");
export const video = require("../assets/video.png");
export const arrow = require("../assets/arrow.png");
export const SignupBG = require("../assets/SignupBG.png");
export const Hero = require("../assets/hero.png");




