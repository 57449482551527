import React from "react";
import {
    Button,
    Typography,
    IconButton,
    Box,
    Modal,
    Menu,
    MenuItem,
  } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import UserProfileDetailsModalController, { Props } from "./UserProfileDetailsModalController";
import { USAflag, ageGroupIcon, arrowDownIcon, changePassword, earthIcon, editIcon, fullNameUserIcon, profileIcon, profileMessageIcon, rightArrowIcon, userSmileFill } from "./assets";
import { CheckedIcon, CrossIcon, GroupProfileAdd, UncheckedIcon } from "../../contentmanagement3/src/assets";
import { GroupIcon } from "../../emailnotifications2/src/assets";

interface IsEditProfileProps {
    isOpenEditProfileModal: boolean;
    onUpload: () => void;
    onRemove: () => void;
    onCloseEditProfileModal: () => void;
}

interface IUploadProfileImagesProps {
    isOpenUploadProfileModal: boolean;
    onCloseUploadProfileModal: () => void;
    selectedProfilePhoto: any;
    uploadedPhotos: any[];
    handleSelectImage: (data:any) => void;
    handleAddCoverImage: (e:any) => void;
}

const setProfileIcon = (isNewUser?: boolean) => {
    return isNewUser ? webStyle.profileIcon : webStyle.profileIconMember;
  }

const getCountryFlag = (userProfileDetails: any, countryCodeList: any[]) => {
    let flag = USAflag;
    const countryData = countryCodeList.find((item) => 
        item?.attributes?.country_code === userProfileDetails?.attributes?.country_code);
    if (countryData) {
        flag = countryData?.attributes?.emoji_flag;
    }
    return flag;
}

const UploadProfileImageModal = (props: IUploadProfileImagesProps) => {
    return (
        <Modal
        open={props.isOpenUploadProfileModal}
        onClose={props.onCloseUploadProfileModal}
        style={{ overflowY: "scroll" }}
        disableScrollLock={false}
      >
        <div>
          <div style={webStyle.SelectCoverImage}>
            <div
              style={{
                backgroundColor: "#3618B1",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <Typography style={webStyle.heading}>
                  Select Profile Picture
                </Typography>
                <img
                  data-test-id="close-profile-icon"
                  alt="cross"
                  src={CrossIcon}
                  onClick={props.onCloseUploadProfileModal}
                  style={{ width: "40px", height: "40px", cursor: "pointer" }}
                ></img>
              </div>
              <div style={webStyle.uploadBaseWrapper}>
                {props.selectedProfilePhoto !== "" ? (
                  <div style={webStyle.selectedProfilePhoto}>
                    <img
                      src={URL.createObjectURL(props.selectedProfilePhoto)}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                ) : (
                  <div style={webStyle.modalContentImage}>
                    <img
                      src={GroupIcon}
                      style={{
                        height: "60px",
                        width: "60px",
                      }}
                      alt="profile-image"
                    />
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {props.uploadedPhotos.map((data: any, index: any) => {
                  return (
                    <div
                      data-test-id="toggle-image-select"
                      onClick={(e: any) => props.handleSelectImage(data)}
                      key={index}
                      style={{
                        height: "200px",
                        margin: "10px 1%",
                        width: "31%",
                        position: "relative",
                      }}
                    >
                      <img
                        src={
                          data instanceof Blob
                            ? URL.createObjectURL(data)
                            : ""
                        }
                        height={"100%"}
                        width={"100%"}
                        style={{ borderRadius: "10px", position: "relative" }}
                      ></img>
                      <div
                        style={{
                          position: "absolute",
                          right: "10%",
                          top: "80%",
                        }}
                      >
                        {props.selectedProfilePhoto == data ? (
                          <img src={CheckedIcon} width={28} height={28}></img>
                        ) : (
                          <img src={UncheckedIcon} height={28} width={28}></img>
                        )}
                      </div>
                    </div>
                  );
                })}
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="start"
                >
                  <Box
                    display="flex"
                    style={{ height: "175px", width: "297px" }}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gridGap="5px"
                  >
                    <Button
                      component="label"
                      disableRipple
                      data-test-id="updcoverimage"
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          margin: "0px 0px",
                        }}
                      >
                        <div>
                          <img
                            src={GroupProfileAdd}
                            width={84}
                            alt="Group"
                            height={84}
                          />
                        </div>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <input
                            accept="image/png, image/jpg, image/jpeg,"
                            type="file"
                            data-test-id="add-cover-image"
                            hidden
                            name="getimage"
                            onChange={(e: any) => props.handleAddCoverImage(e)}
                          />
                        </div>
                      </div>
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Modal>  
    )
}

const EditProfileModal = (props: IsEditProfileProps) => {
    return (
      <Modal
        open={props.isOpenEditProfileModal}
        data-test-id="close-edit-icon"
        onClose={props.onCloseEditProfileModal}
        style={{ overflowY: "scroll" }}
        disableScrollLock={false}
      >
        <div style={webStyle.SelectCoverImage}>
          <div
            style={{
              backgroundColor: "#3118B5",
              padding: "5px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                margin: "2px",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Profile Picture
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "40px",
                    letterSpacing: "1px",
                    textTransform: "uppercase",
                    color: "#fff",
                  }}
                  onClick={props.onUpload}
                >
                  Upload
                </Button>
                <div
                  style={{
                    width: "4px",
                    height: "62px",
                    backgroundColor: "#291084",
                    margin: "0 38px",
                  }}
                ></div>
                <Button
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "40px",
                    letterSpacing: "1px",
                    textTransform: "uppercase",
                    color: "#fff",
                  }}
                  onClick={props.onRemove}
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

export default class UserProfileDetailsModal extends UserProfileDetailsModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {isNewUser } = this.props;
    const {
        userProfileDetails,
        isOpenProfileModel,
        countryCodeList,
        isEditOpen,
        isOpenUploadProfile,
        selectedProfilePhoto,
        uploadedPhotos,
        ageGroup,
        anchorElAgeGroup, selectedAgeGroup,
    } = this.state;
    return (
        <div>
                <Button data-test-id="profile-icon" onClick={this.toggleProfileModel} >
                    <img src={profileIcon} alt="Profile" style={setProfileIcon(isNewUser)} />
                </Button >
                {isOpenProfileModel && <Modal
                open={isOpenProfileModel}
                onClose={this.toggleProfileModel}
                style={{ overflowY: "scroll" }}
                disableScrollLock={false}
                >
               <div style={webStyle.SelectCoverImage}>
                <div
                style={{
                    backgroundColor: "#222",
                    padding: "5px",
                    borderRadius: "5px",
                }}
            >
                <div
                    style={{
                      margin: "2px",
                      height:"1066px",
                    }}
                >
                  <div style={{display:"flex", marginBottom:"42px"}}>
                  <img src={userSmileFill} alt="personalInfo" style={{marginRight:"20px",}}/>
                    <Typography style={webStyle.personalInfoHeading}>
                        Personal Information
                    </Typography>
                  </div>
                  <div style={webStyle.settingBaseWrapper}>
                      <div style={{ position: 'relative', height: "93px", width: "93px", borderRadius: "50%" }}>
                          <img data-test-id="close-profile-icon" src={profileIcon} width="100%" height="100%" style={{ borderRadius: "50%" }} />
                            <Button
                                data-test-id="edit-icon"
                                onClick={this.toggleEditModel}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    minWidth: '32px',
                                    height: '32px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    borderRadius: '8px',
                                    backgroundColor: '#fff',
                                }}
                                >
                                <img  src={editIcon} alt="Edit" style={{ width: '14px', height: '14px' }} />
                            </Button>
                            {isEditOpen && <EditProfileModal 
                            isOpenEditProfileModal={isEditOpen}
                            onCloseEditProfileModal={this.toggleEditModel}
                            onRemove={() => {}}
                            onUpload={this.toggleUploadProfileImagesModal}
                            />}
                            {isOpenUploadProfile && <UploadProfileImageModal
                            isOpenUploadProfileModal={isOpenUploadProfile}
                            onCloseUploadProfileModal={this.toggleUploadProfileImagesModal}
                            selectedProfilePhoto={selectedProfilePhoto}
                            uploadedPhotos={uploadedPhotos}
                            handleAddCoverImage={this.handleAddImages}       
                            handleSelectImage={this.handleSelectImage}                    
                            />}

                      </div>
                      <Typography style={{ ...webStyle.textStyle, position: 'absolute', top: 'calc(24% + 2px)', left: '50%', transform: 'translateX(-50%)' }}>
                          {userProfileDetails?.attributes?.full_name}
                      </Typography>
                  </div>
                  <Box  style={webStyle.fullNameTextWrapper}>
                    <Box style={{...webStyle.detailsInnerBoxWrapper, flexDirection: 'row'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                      <img  src={fullNameUserIcon} width="24px" height="24px" alt="FullName Icon"  />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px'}}>
                      <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                          Full Name
                        </Typography>
                        <Typography data-test-id="full-name" style={{ fontSize: "16px" }}>
                        {userProfileDetails?.attributes?.full_name}
                        </Typography>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{...webStyle.detailsInnerBoxWrapper, flexDirection: 'row'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                      <img src={profileMessageIcon} width="24px" height="24px" alt="Email"  />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px'}}>
                      <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                          Email
                        </Typography>
                        <Typography style={{ fontSize: "16px" }}>
                        {userProfileDetails?.attributes?.email}
                        </Typography>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{...webStyle.detailsInnerBoxWrapper, flexDirection: 'row'}}>
                      <div style={{...webStyle.settingDetailsWrapper, backgroundColor:"#313131", borderRadius:"10px"}}>
                      <img src={getCountryFlag(userProfileDetails, countryCodeList)} width="24px" height="24px" alt="USA flag"  />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px'}}>
                      <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                          Phone
                        </Typography>
                        <Typography style={{ fontSize: "16px" }}>
                        +{userProfileDetails?.attributes?.country_code} {userProfileDetails?.attributes?.phone_number}
                        </Typography>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px", maxWidth: "760px", height: "54px", display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <div style={webStyle.settingDetailsWrapper}>
                        <img src={ageGroupIcon} width="32px" height="32px" alt="age group" />
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                        <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                            Age Group
                        </Typography>
                        <Typography style={{ fontSize: "16px" }} >
                            {ageGroup}
                        </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton >
                            <img src={arrowDownIcon} width="32px" height="32px" alt="down-arrow" />
                        </IconButton>
                        </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px", maxWidth: "760px", height: "54px", display: 'flex', alignItems: 'center', gap: '20px' }}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={ageGroupIcon} width="32px" height="32px" alt="gender" />
                      </div>
                      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                      <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                          Gender
                        </Typography>
                        <Typography style={{ fontSize: "16px" }}>
                        {userProfileDetails?.attributes?.gender}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconButton >
                          <img src={arrowDownIcon} height="32px" width="32px" alt="down-arrow" />
                        </IconButton>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px", maxWidth: "760px", height: "54px", display: 'flex', alignItems: 'center', gap: '20px' }}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={earthIcon} height="25px" width="25px" alt="language group" />
                      </div>
                      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                        <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                          Language
                        </Typography>
                        <Typography style={{ fontSize: "16px" }}>
                        {userProfileDetails?.attributes?.native_languages}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton >
                          <img src={arrowDownIcon} width="32px" height="32px" alt="down-arrow-icon" />
                        </IconButton>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px", maxWidth: "760px", height: "54px", display: 'flex', alignItems: 'center', gap: '20px' }}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={earthIcon} width="25px" height="25px" alt="country group" />
                      </div>
                      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                        <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                          Country
                        </Typography>
                        <Typography style={{ fontSize: "16px" }}>
                        {userProfileDetails?.attributes?.profile?.data?.attributes?.country}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton >
                          <img src={arrowDownIcon} width="32px" height="32px" alt="arrow-down-icon" />
                        </IconButton>
                      </div>
                    </Box>
                  </Box>
                </div> 
            </div>
            <div style={webStyle.SelectEditProfileCover}>
                <div
                style={{
                    backgroundColor: "#222",
                    padding: "5px",
                    borderRadius: "5px",
                }}
            >
                <div
                    style={{
                      margin: "2px",
                      height:"170px",
                    }}
                >
                  <Box  style={webStyle.fullNameTextWrapper}>
                    <Box style={{ padding: "24px 24px 10px 24px", maxWidth: "760px", height: "45px", display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <div style={webStyle.settingDetailsWrapper}>
                        <img src={userSmileFill} width="25px" height="25px" alt="edit details"/>
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                            <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                            Edit Details
                            </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <IconButton >
                            <img src={rightArrowIcon} style={{ width: "32px", height: "32px", maxWidth: "100%" }} alt="arrow-down-icon"/>
                            </IconButton>
                        </div>
                    </Box>
                    <Box style={{ padding: "10px 24px 24px 24px", maxWidth: "760px", height: "54px", display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <div style={webStyle.settingDetailsWrapper}>
                        <img src={changePassword} width="25px" height="25px" alt="change password"/>
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                            <Typography style={{ color: "#ADB1AD", fontSize: "14px" }}>
                            Change Password
                            </Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <IconButton >
                            <img src={rightArrowIcon} style={{ width: "32px", height: "32px", maxWidth: "100%" }} alt="arrow-down-icon"/>
                            </IconButton>
                        </div>
                    </Box>
                  </Box>
                </div> 
            </div>
                </div>
                </div>
                </Modal>}
        
        </div>
    );
  }
}

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  
  const webStyle = {
    modalContentImage: {
        height: "200px",
        width: "200px",
        backgroundColor: "#FFF",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px 0px"
      },
    selectedProfilePhoto: {
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        margin: "30px 0px"
      },
    uploadBaseWrapper: {
        backgroundColor: "#210063",
        borderRadius: "5px",
        width: "auto",
        height: "20%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      } as const,
    heading: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
      } as React.CSSProperties,
    SelectCoverImage: {
        top: "20%",
        position: 'absolute',
        color: "#FFFFFF",
        width: "54%",
        margin: "0 32%",
        borderRadius: "10px",
        padding: "20px",
    
    } as const,
    SelectEditProfileCover: {
        color: "#FFFFFF",
        width: "100%",
        borderRadius: "10px",
        margin: "3% 0%",
    
    } as const,
    personalInfoHeading: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    } as React.CSSProperties,
    editDetailsText:{
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    settingBaseWrapper: {
        backgroundColor: "#3900BD",
        borderRadius: "5px",
        width: "auto",
        height: "255px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    } as const,
        textStyle: {
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#fff',
        marginTop: '19px', 
    },
    fullNameTextWrapper: {
        backgroundColor: "#212121",
        borderRadius: "5px",
        width: "auto",
        height: "110px",
    },
    lineStyle: {
        width: 'auto',
        height: '1px',
        backgroundColor: '#393929',
        margin: '0px 24px',
    },
    settingDetailsWrapper: {
        width:"50px", 
        height:"50px", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
    },
    detailsInnerBoxWrapper: {
        padding: "24px 24px", width:"230px", height:"54px", display: 'flex', alignItems: 'flex-start', gap: '20px'
    },
    editDetails: {
        color: "#FFFFFF",
        borderRadius: "10px",
        width: "100%",
        margin: "5% 0%",

    },
    profileIcon:{
        width: "45px",
        cursor: "pointer",
        height: "45px",
        borderRadius:"120px",
        marginLeft:"14px",
        marginRight:"44px",
    },
    profileIconMember: {
        width: "45px",
        cursor: "pointer",
        height: "45px",
        borderRadius:"120px",
        marginLeft:"10px",
        marginRight:"22px"
    },
    dialogContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px',
        width:'5000px !important',
    },
    dialogContent:{
        width: '600px',
        backgroundColor: 'blue',  
    },

  }