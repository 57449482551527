import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number,
  selectOption: string,
  errors: boolean,
  errorMsg: string,
  featuredCommunity: any
  communitiesList: any
  zilionInfoBoxDetails: {
    data: any[];
    member: number;
    creators: number;
    community_posts: number;
    communities: number;
    landing_details: any[]
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeStep: 0,
      selectOption: 'free',
      errors: false,
      errorMsg: '',
      featuredCommunity: [],
      communitiesList: [],
      zilionInfoBoxDetails: {
        data: [],
        member: 0,
        creators: 0,
        community_posts: 0,
        communities: 0,
        landing_details: []
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ); if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.featuredContentDataID) {
          this.handleFeaturedContentData(responseJson);
        }
        if (apiRequestCallId === this.individualCategoryDataID) {
          this.handleIndividualCategoryData(responseJson);
        }
        if (apiRequestCallId === this.zilionInfoBoxDetailsDataID) {
          this.handleZilionBoxInfoBoxDetailsData(responseJson)
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getFeaturedContent()
    this.getIndividualCategoryContent()
    this.getZilionInfoBoxDetails()
  }


  featuredContentDataID: any;
  individualCategoryDataID: any;
  zilionInfoBoxDetailsDataID: any;

  getFeaturedContent() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.featuredContentDataID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeaturedContentEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getIndividualCategoryContent() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      "category_ids": [1, 2, 3, 4, 5, 6]
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.individualCategoryDataID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getIndividualContentEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getZilionInfoBoxDetails() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.zilionInfoBoxDetailsDataID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getZilionInfoBoxDetailsEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFeaturedContentData = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      const list: any[] = ((responseJson?.data) || []).slice([0], [19])
      this.setState({ featuredCommunity: [...list] })
    }
  }

  handleIndividualCategoryData = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      const individualLists: any[] = [];
      ((responseJson?.data) || []).forEach((data: any, index: number) => {
        const list: any[] = ((data?.communities) || []).slice([0], [19])
        individualLists.push({ ...data, communities: list })
      })

      this.setState({ communitiesList: [...individualLists] })
    }
  }

  handleZilionBoxInfoBoxDetailsData = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      const obj = {
        data: ((responseJson?.data) ?? []).slice([0], [4]),
        member: responseJson.landing_views.member_count,
        creators: responseJson.landing_views.creators_count,
        community_posts: responseJson.landing_views.post_count,
        communities: responseJson.landing_views.community_count,
        landing_details: responseJson.landing_details
      }
      this.setState({ zilionInfoBoxDetails: obj })
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  onSignUp = (type?: 'creator' | 'member') => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), type === 'creator' ? "SignUpCreator" : "SignUpMember");

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }
  // Customizable Area End
}
