import React from "react";
import { makeStyles,createStyles } from "@material-ui/core";

interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(createStyles({
  loader: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "transparent",
    color: "transparent",
    boxShadow: '0 -18px 0 0 #3E01B9, 12.727926px -12.727926px 0 0 #3E01B9,18px 0 0 0 #3E01B9, 12.727926px 12.727926px 0 0 rgba(92,0,185,0),0 18px 0 0 rgba(92,0,185,0),-12.727926px 12.727926px 0 0 rgba(92,0,185,0),-18px 0 0 0 rgba(92,0,185,0),-12.727926px -12.727926px 0 0 rgba(92,0,185,0)',
    animation: "$spin 1.5s infinite linear",
  },
  "@keyframes spin": {
    "0%,100%": {
      boxShadow: [
        "0 -18px 0 0 #3E01B9, 12.727926px -12.727926px 0 0 #3E01B9",
        "18px 0 0 0 #3E01B9, 12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "0 18px 0 -5px rgba(92,0,185,0)",
        "-12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "-18px 0 0 -5px rgba(92,0,185,0)",
        "-12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
      ],
    },
    "12.5%": {
      boxShadow: [
        "0 -18px 0 -5px rgba(92,0,185,0)",
        "12.727926px -12.727926px 0 0 #3E01B9, 18px 0 0 0 #3E01B9",
        "12.727926px 12.727926px 0 0 #3E01B9, 0 18px 0 -5px rgba(92,0,185,0)",
        "-12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "-18px 0 0 -5px rgba(92,0,185,0)",
        "-12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
      ],
    },
    "25%": {
      boxShadow: [
        "0 -18px 0 -5px rgba(92,0,185,0)",
        "12.727926px -12.727926px 0 -5px rgba(92,0,185,0), 18px 0 0 0 #3E01B9",
        "12.727926px 12.727926px 0 0 #3E01B9, 0 18px 0 0 #3E01B9",
        "-12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "-18px 0 0 -5px rgba(92,0,185,0)",
        "-12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
      ],
    },
    "37.5%": {
      boxShadow: [
        "0 -18px 0 -5px rgba(92,0,185,0)",
        "12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
        "18px 0 0 -5px rgba(92,0,185,0), 12.727926px 12.727926px 0 0 #3E01B9",
        "0 18px 0 0 #3E01B9, -12.727926px 12.727926px 0 0 #3E01B9",
        "-18px 0 0 -5px rgba(92,0,185,0)",
        "-12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
      ],
    },
    "50%": {
      boxShadow: [
        "0 -18px 0 -5px rgba(92,0,185,0)",
        "12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
        "18px 0 0 -5px rgba(92,0,185,0)",
        "12.727926px 12.727926px 0 -5px rgba(92,0,185,0), 0 18px 0 0 #3E01B9",
        "-12.727926px 12.727926px 0 0 #3E01B9, -18px 0 0 0 #3E01B9",
        "-12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
      ],
    },
    "62.5%": {
      boxShadow: [
        "0 -18px 0 -5px rgba(92,0,185,0)",
        "12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
        "18px 0 0 -5px rgba(92,0,185,0)",
        "12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "0 18px 0 -5px rgba(92,0,185,0), -12.727926px 12.727926px 0 0 #3E01B9",
        "-18px 0 0 0 #3E01B9, -12.727926px -12.727926px 0 0 #3E01B9",
      ],
    },
    "75%": {
      boxShadow: [
        "0 -18px 0 0 #3E01B9",
        "12.727926px -12.727926px 0 -5px rgba(92,0,185,0)",
        "18px 0 0 -5px rgba(92,0,185,0)",
        "12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "0 18px 0 -5px rgba(92,0,185,0)",
        "-12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "-18px 0 0 0 #3E01B9, -12.727926px -12.727926px 0 0 #3E01B9",
      ],
    },
    "87.5%": {
      boxShadow: [
        "0 -18px 0 0 #3E01B9, 12.727926px -12.727926px 0 0 #3E01B9",
        "18px 0 0 -5px rgba(92,0,185,0)",
        "12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "0 18px 0 -5px rgba(92,0,185,0)",
        "-12.727926px 12.727926px 0 -5px rgba(92,0,185,0)",
        "-18px 0 0 -5px rgba(92,0,185,0)",
        "-12.727926px -12.727926px 0 0 #3E01B9",
      ],
    },
  },
}))

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
      <div className={classes.loader}>
      </div>
  ) : (
    <div />
  );
}
