
import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Link,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import LogInController, { Props } from "../src/LogInController.web";
import Carousel from "./Carousel.web";
import { Logo } from "./assets";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import './LogIn.web.css';
import ErrorIcon from "@material-ui/icons/Error";
import { OTPVerificationWithStyles } from "../../forgot-password/src/OTP/ForgotPasswordOTP.web";
// Customizable Area End

export default class LogIn extends LogInController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  onLoginForm() {
    return (
      <>
        {this.state.loginToggle ?
          <Formik
            data-test-id="FormikID"
            initialValues={{
              Phone: "",
            }}
            validationSchema={Yup.object().shape({
              Phone: Yup.string()
                .required('Phone number is required'),
            })}
            onSubmit={(values) => {
              this.postPhoneData(values)
            }}
          >
            {({ errors, values, handleBlur, isSubmitting, isValid, dirty }) => (
              <Form >
                <Grid container>
                      <Grid item xs={12}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        style={{ gap: "10px" }}
                      >
                        <label htmlFor="Phone">
                          Your Phone Number
                        </label>
                        <Field
                          name="Phone"
                          render={({ field, form }: any) => (
                            <>
                              <PhoneInput
                                containerClass="custom-phone-input-container"
                                inputClass={this.state.phoneErrors ? 'custom-phone-input-error' : 'custom-phone-input'}
                                country={"us"}
                                defaultCountry="us"
                                {...field}
                                data-test-id="txtInputPhonenumber"
                                test-Id="phonecheck"
                                value={values.Phone}
                                placeholder=" Phone Number"
                                onChange={(value) =>
                                  form.setFieldValue("Phone", value)
                                }
                                onBlur={handleBlur("Phone")}
                                inputComponent={TextField}
                                inputProps={{
                                  variant: "outlined",
                                  fullWidth: true,
                                  error: form.errors.Phone,
                                  helperText: form.errors.Phone,
                                }}
                              />
                            </>
                          )}
                        />
                      </Box>
                      <ErrorMessage name="Phone">
                  {(msg) => (
                    <Box sx={{ display: "flex" }}>
                      <ErrorIcon
                        style={{
                          color: "red",
                          height: "18px",
                          width: "18px",
                          marginRight: "4px",
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "left",
                          width: "216px",
                          fontSize: "12px",
                        }}
                      >
                        {msg}
                      </Typography>
                    </Box>
                  )}
                </ErrorMessage>
                      </Grid>

                      <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                       
                            {this.state.phoneErrors &&
                          <Box sx={{ display: "flex" }}>
                              <ErrorIcon style={{ color: "red", height: "18px", width: "18px", marginRight:"4px"}} />
                              <Typography style={{ textAlign: "left", width: "216px", fontSize: "12px" }}>
                                {this.state.loginDataErr}
                              </Typography>
                          </Box>}
                      </Box>
                    </Grid>

                  <Grid item xs={12} style={{ marginTop: "50px" }}>
                    <Box
                      display={"flex"}
                      style={{ gap: "50px", textAlign: "center" }}
                      flexDirection={"column"}
                      justifyContent={"center"}
                    >
                      <Button
                        type="submit"
                        style={
                          (isValid && dirty) || isSubmitting ? webStyle.loginbtn : webStyle.disableBtn
                        }
                        size="large"
                      >
                        Login
                      </Button>

                      <Typography style={webStyle.account}>
                        Don't have an account? &nbsp;
                        <Link
                          href="/SignUp"
                          style={{
                            textDecoration: "underline",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          Register Now
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          :
          <Formik
            data-test-id="FormikID"
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
              password: Yup.string().required("Password is required")
            })}
            onSubmit={(values) => {
              this.postData(values)
            }}
          >
            {({ errors, values, touched, isSubmitting, isValid, dirty }) => (
              <Form >
                <Grid container>
                  <>
                    <Grid item xs={12}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      style={{ gap: "10px" }}
                    >
                      <label style={webStyle.label} htmlFor="email">
                        Your Email Address
                      </label>
                      <Field
                        data-test-id="txtInputEmail"
                        type="email"
                        name="email"
                        variant="filled"
                        as={TextField}
                        fullWidth
                        placeholder="Write your Email Here"
                        helperText={
                          <ErrorMessage name="email">
                  {(msg) => (
                    <Box sx={{ display: "flex" }}>
                      <ErrorIcon
                        style={{
                          color: "red",
                          height: "18px",
                          width: "18px",
                          marginRight: "4px",
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "left",
                          width: "216px",
                          fontSize: "12px",
                        }}
                      >
                        {msg}
                      </Typography>
                    </Box>
                  )}
                </ErrorMessage>
                        }
                        InputProps={{
                          disableUnderline: true,
                          style:{
                            color:this.state.emailErrors ? "red" : "inherit",
                          }
                        }}
                      />
                    </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        style={{ gap: "10px",marginTop:"10px" ,marginBottom:"8px"}}
                      >
                        <label style={webStyle.label} htmlFor="email">
                          Your Password
                        </label>
                        <Field
                          data-test-id="txtInputPassword"
                          type={this.state.showPassword ? "text" : "password"}
                          name="password"
                          variant="filled"
                          as={TextField}
                          fullWidth
                          placeholder="Choose a strong password"
                          helperText={
                            <ErrorMessage name="password">
                  {(msg) => (
                    <Box sx={{ display: "flex" }}>
                      <ErrorIcon
                        style={{
                          color: "red",
                          height: "18px",
                          width: "18px",
                          marginRight: "4px",
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "left",
                          width: "216px",
                          fontSize: "12px",
                        }}
                      >
                        {msg}
                      </Typography>
                    </Box>
                  )}
                </ErrorMessage>
                          }
                          InputProps={{
                            disableUnderline: true,
                            style:{
                              color:this.state.emailErrors ? "red" : "inherit",
                            },
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      </Box>

                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                       
                            {this.state.emailErrors ?
                          <Box sx={{ display: "flex" }}>
                              <ErrorIcon style={{ color: "red", height: "18px", width: "18px", marginRight:"4px"}} />
                              <Typography style={{ textAlign: "left", width: "216px", fontSize: "12px" }}>
                                {this.state.loginDataErr}
                              </Typography>
                          </Box>
                        : (
                          <label htmlFor="remember" style={webStyle.remember_text}>
                            <Field type="checkbox" id="remember" name="remember" style={{ marginRight: "6px", width: "24px", height: "17px" }} />
                            Remember me
                          </label>
                        )}

                        <Link
                          href="/forgotPasswordWeb"
                          style={webStyle.forgot}
                          data-test-id="ForgotPasswordID"
                        >
                          Forgot Password?
                        </Link>
                      </Box>
                    </Grid>
                  </>

                  <Grid item xs={12} style={{ marginTop: "80px" }}>
                    <Box
                      display={"flex"}
                      style={{ gap: "50px", textAlign: "center" }}
                      flexDirection={"column"}
                      justifyContent={"center"}
                    >
                      <Button
                        data-test-id="LoginID"
                        type="submit"
                        style={
                          (isValid && dirty) || isSubmitting ? webStyle.loginbtn : webStyle.disableBtn
                        }
                        size="large"
                      >
                        Login
                      </Button>

                      <Typography style={webStyle.account}>
                        Don't have an account? &nbsp;
                        <Link
                          href="/SignUp"
                          style={{
                            textDecoration: "underline",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          Register Now
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
      </>
    );
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start

      <Grid container style={{ height: "100%" }}>
        <Grid item xs={6}>
          <Carousel navigation={undefined} id={""} accountType={this.state.accountType}/>
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: "20px" }}>
          <Box style={webStyle.Loginblock}>
            <Box style={webStyle.LoginblockLogo} >
              <img src={Logo} alt="" style={{ width: "72px", height: "85px" }} />
            </Box>
            <Box width="80%" margin="auto">
              {this.state.otpScreen ? 
              <OTPVerificationWithStyles 
              onSubmit={this.Back} 
              id={"otpverification"}
              handleBack={this.handleOtpBack} 
              phone={this.state.phoneNumber} 
              method="login" 
              navigation={this.props.navigation} /> 
                : 
              <>
                <Box>
                  <ArrowBackIosIcon
                    onClick={this.Back}
                    style={{ cursor: "pointer", alignSelf: "end" }}
                  />
                  <Typography variant="h4" style={webStyle.title}>
                    Login
                  </Typography>
                  <Typography style={webStyle.description}>
                    Welcome back! Use credentials to login{" "}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Box display={"flex"} flexDirection={"row"} mb={3}>
                    <Button
                      variant="contained"
                      data-test-id="btnEmail"
                      onClick={this.onLginToggleOut}
                      style={{
                        background:
                          this.state.loginToggle === false
                            ? "#3E01B9"
                            : "#E8E8E8",
                        color:
                          this.state.loginToggle === false ? "#fff" : "#000",
                        width: "100%",
                        height: "45px",
                        textTransform: "capitalize",
                        fontWeight: 600,
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      Using Email
                    </Button>
                    <Button
                      onClick={this.onLginToggleIn}
                      data-test-id="btnPhone"
                      style={{
                        background:
                          this.state.loginToggle === true
                            ? "#3E01B9"
                            : "#E8E8E8",
                        color:
                          this.state.loginToggle === true ? "#fff" : "#000",
                        width: "100%",
                        height: "45px",
                        textTransform: "capitalize",
                        fontWeight: 600,
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        borderRadius: "0px 8px 8px 0px"
                      }}
                      variant="contained"
                    >
                      {" "}
                      Using Phone
                    </Button>
                  </Box>
                  <Box>{this.onLoginForm()}</Box>
                </Box>
              </>
              }
            </Box>
          </Box>
        </Grid>

      </Grid>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  showErr: {
    color: "red",
  },
  noErr: {
    color: "#000000"

  },
  screen: {
    display: "flex",
  },
  closeError: {
    color: "red",
    fontSize: "50px",
  },
  LoginblockLogo: {
    display: "flex",
    justifyContent: "end",
    marginTop: "42px",
    marginRight: "42px"
  },
  Loginblock: {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,
  loginbtn: {
    background: "#3E01B9" as "#3E01B9",
    borderRadius: "10px" as "10px",
    width: "100%" as "100%",
    height: "64px" as "64px",
    color: "#E8E8E8" as "#E8E8E8",
    fontFamily: "Montserrat" as "Montserrat",
    fontStyle: "normal" as "normal",
    fontWeight: 600 as 600,
    fontSize: "18px" as "18px",
    textTransform: "capitalize" as "capitalize",
  },
  disableBtn: {
    background: "#E8E8E8" as "#E8E8E8",
    borderRadius: "10px" as '10px',
    width: "100%" as "100%",
    height: "64px" as "64px",
    color: "#C7C7C7" as "#C7C7C7",
    fontFamily: "Montserrat" as "Montserrat",
    fontStyle: "normal" as "normal",
    fontWeight: 600 as 600,
    fontSize: "18px" as '18px',
    textTransform: "capitalize" as 'capitalize',
  },

  remember_text:{
    fontWeight: 500 as 500, 
    fontSize: "16px" as "16px", 
    color: "#050505" as "#050505", 
    display: "flex" as "flex",
    fontFamily: "Montserrat" as "Montserrat",

  },
  label: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#323548",
  },
  required: {
    color: "red",
    marginTop: "10px",
    fontFamily: "Montserrat",
  } as React.CSSProperties,
  input: {
    marginTop: "10px",
    width: "100%",
  },
  title: {
    lineHeight: "2",
    fontSize: "36px",
    fontFamily: "Montserrat",
    color: "#323548",
    fontWeight: 700,
    marginTop: "40px"
  },
  description: {
    color: "#323548",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px",
  },
  forgot: {
    textDecoration: "underline",
    fontFamily: "Montserrat",
    fontWeight: 650,
    fontSize: "16px",
  },
  account: {
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#323548",
    fontStyle: "normal",
    fontFamily: "Montserrat" as "Montserrat" ,
  } as React.CSSProperties,
};
// Customizable Area End 
