import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const configJSON = require("./config.js");
import fetch from 'cross-fetch';
import { Message } from "../../../framework/src/Message";
const baseURL = require("../../../framework/src/config.js").baseURL;
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {

  // Customizable Area Start
  password: string
  showPassword: boolean;
  confirmPassword: boolean,
  open: boolean;
  termsvisible: boolean;
  termsData: any;
  privacyvisible: boolean;
  privacyData: any;
  errors: boolean,
  errorMsg: string,
  maskedEmail: string,
  emailSignupStage: 'initial' | 'edit',
  verifiedEmailModal: boolean
  signUpMethod: 'email' | 'phone',
  otpVerification: boolean,
  phoneNumber: string,
  accountType: 'creator'| 'member'
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

class SignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  createAccountEmailId: any;
  createAccountPhoneId: any;
  termsConditionsApiId: any;
  privacyApiId: any;
  // Customizable Area End    

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [

      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    // Customizable Area End
    this.state = {

      // Customizable Area Start
      errors: false,
      errorMsg: '',
      password: "",
      showPassword: false,
      confirmPassword: false,
      open: false,
      termsvisible: false,
      termsData: [],
      privacyvisible: false,
      privacyData: [],
      maskedEmail: '',
      emailSignupStage: 'initial',
      verifiedEmailModal: false,
      signUpMethod: 'email',
      otpVerification: false,
      phoneNumber: '',
      accountType: 'member'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }



  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.createAccountEmailId:
            this.handleMailResp(responseJson);
            break;
          case this.createAccountPhoneId:
            this.handlePhoneResp(responseJson);
            break
          case this.termsConditionsApiId:
            this.handleTermsResp(responseJson);
            break
          case this.privacyApiId:
            this.handlePrivacyResp(responseJson);
            break
          default:
            break
        }
      }
    }
  }

  handleMailResp = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      this.onHandleVerifyModalOpen();
      // verify email pop up show
    }
  }


  handlePhoneResp = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({ errors: true, errorMsg: err });
    } else {
      setStorageData("otpToken", responseJson.meta.otp_token);
      this.setState({ otpVerification: true })
    }
  }

  handleTermsResp = (responseJson: any) => {
    if (responseJson.errors) {
      // handle error
    } else {
      this.setState({ termsData: responseJson.data['attributes']['description'] || '' })
    }
  }


  handlePrivacyResp = (responseJson: any) => {
    if (responseJson.errors) {
      // handle error
    } else {
      this.setState({ privacyData: responseJson.data['attributes']['description'] || '' })
    }
  }




  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.termsCondition();
    this.privacyCondition();
    this.checkForAccountType();
    const emailConfirmed = new URLSearchParams(location.search).get('email_confirmed');
    if (emailConfirmed === 'true') {
      this.setState({ verifiedEmailModal: true })
      setTimeout(() => {
        this.props.navigation.navigate('LogIn')
      }, 3000)
    }
    // Customizable Area End
  }

  checkForAccountType=()=>{
    const type = new URLSearchParams(location.search).get('type') === 'creator'? 'creator': 'member';
    this.setState({accountType: type})
  }


  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  Back = () => {
    if (this.state.emailSignupStage === 'edit') {
      this.onHandleVerifyModalOpen()
    } else {
      this.props.navigation.goBack()
    }

  };


  handleClickShowConfirmPassword = () => {
    this.setState({ confirmPassword: !this.state.confirmPassword });
  };


  onHandleClose = () => {
    this.setState({ open: false });
  }

  onHandleVerifyModalOpen = () => {
    this.setState({ open: true });
  }



  postData = async (values: any) => {
    this.setState({
      maskedEmail: this.getMaskedEmail(values.email),
      errors: false,
      errorMsg: ''
    })
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          full_name: values.name,
          email: values.email,
          password: values.password,
          password_confirmation: values.Cpassword,
          account_type: this.state.accountType
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountEmailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodPost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  postPhoneData = async (values: any) => {
    this.setState({
      phoneNumber: values.Phone,
      errors: false,
      errorMsg: ''
    })

    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const httpBody = {
      data: {
        type: "sms_account",
        attributes: {
          full_phone_number: values.Phone,
          full_name: values.name,
          account_type: this.state.accountType
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountPhoneId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodPost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  onTermsOpen = () => {
    this.setState({ termsvisible: true });
  }
  onTermsClose = () => {
    this.setState({ termsvisible: false });

  }
  termsCondition = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsConditionsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsConditionsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onPrivacyOpen = () => {
    this.setState({ privacyvisible: true });

  }
  onPrivacyClose = () => {
    this.setState({ privacyvisible: false });


  }
  privacyCondition = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.privacyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleEmailClick = () => {
    this.setState({
      signUpMethod: 'email',
    });
  }

  handleMobileClick = () => {
    this.setState({
      signUpMethod: 'phone',
    });
  }

  getMaskedEmail = (email: string) => {
    let str = email.split('');
    let finalArr: string[] = [];
    let len = str.indexOf('@');
    str.forEach((item, pos) => {
      (pos >= 3 && pos < len) ? finalArr.push('*') : finalArr.push(str[pos]);
    })
    return finalArr.join('')
  }

  handleEditSignupEmail = () => {
    this.setState({
      emailSignupStage: 'edit'
    })
    this.onHandleClose();
  }

  handleOtpBack = () => {
    this.setState({
      otpVerification: false
    })
  }
  // Customizable Area End
}

export default SignupController;