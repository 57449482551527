import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import {
  Box,
  Button,
  Typography,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
  createStyles,
  Theme,
  IconButton,
  withStyles,
} from "@material-ui/core";
import { Close, Email, Edit, EmailVerifiedIcon } from './assets'
import Loader from "../../../components/src/Loader.web";






interface Props {
  navigation: any;
  id: string;
  history?: any;

  // Customizable Area Start
  onHandleOpen: any;
  onHandleClose: any;
  imgs?: string;
  title: string;
  description: string;
  btnName?: string;
  icon: any
  classes: any
  dialogType?: 'commonDialog' | 'VerifyModal' | 'EmailVerifiedModal'
  maskedEmail?: string,
  handleEditSignupEmail?: () => void
  // Customizable Area End
}

interface S {
  open: boolean
}

interface SS { }


class Dialoge extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.SendVerifiedEmailModal = this.SendVerifiedEmailModal.bind(this);
    this.CommonDialog = this.CommonDialog.bind(this);
    this.EmailVerifiedModal = this.EmailVerifiedModal.bind(this);
    this.state = {
      open: false
    };
  }

  EmailVerifiedModal() {
    return (
      <Modal
        open={this.props.onHandleOpen}
        onClose={this.props.onHandleClose}
        data-test-id="EmailVerifiedPopup"
      >
        <Box className={this.props.classes?.dialogModalContainer}>
          <Box className={this.props.classes?.dialogMainContent}>
            <img
              src={EmailVerifiedIcon}
              alt="email verified icon"
              className={this.props.classes?.emailIcon}
              style={{marginTop:'64px'}}
            ></img>
            <Typography className={this.props.classes?.dialogModalTitle}>
              Email Verified!
            </Typography>
            <Typography className={this.props.classes?.dialogModalDescription}>
              You will be redirected to the Login page in a few seconds..
            </Typography>

            <Typography className={this.props.classes?.dialogModalFooter}>
              <Loader loading/>
            </Typography>
          </Box>
        </Box>
      </Modal>)
  }

  SendVerifiedEmailModal() {
    return (
      <Modal
        open={this.props.onHandleOpen}
        onClose={this.props.onHandleClose}
        data-test-id="verifyPopup"
      >
        <Box className={this.props.classes?.dialogModalContainer}>
          <Box style={{ textAlign: "right" }}>
            <img
              src={Close}
              onClick={this.props.onHandleClose}
              className={this.props.classes?.crossIcon}
              alt="close"
            ></img></Box>
          <Box className={this.props.classes?.dialogMainContent}>
            <img
              src={Email}
              alt="email icon"
              className={this.props.classes?.emailIcon}
            ></img>
            <Typography className={this.props.classes?.dialogModalTitle}>
              Verify your email address
            </Typography>
            <Typography className={this.props.classes?.dialogModalDescription}>
              An email has been sent. To start your using Zilion, We need to verify your email
            </Typography>
            <Box className={this.props.classes?.dialogEmailContainer}>
              <Typography className={this.props.classes?.maskedEmailAddress}>
                {this.props.maskedEmail}
              </Typography>
              <IconButton onClick={() => {
                this.props?.handleEditSignupEmail && this.props.handleEditSignupEmail()
              }}>
                <img src={Edit} alt="edit icon"></img>
              </IconButton>
            </Box>
            <Typography className={this.props.classes?.dialogModalFooter}>
              Problem receving mail?. Make sure spelling is correct or check
              spam mails.
            </Typography>
          </Box>
        </Box>
      </Modal>)
  }

  CommonDialog() {
    return (


      <Dialog
        open={this.props.onHandleOpen}
        onClose={this.props.onHandleClose}
        aria-labelledby="responsive-dialog-title"
      >

        <DialogContent>
          <DialogContentText style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            {this.props.imgs && (
              <Box textAlign={"center"}>
                <img src={this.props.imgs} alt="" style={webStyle.successIcon} />
              </Box>
            )}
            {this.props.icon && (
              <Box textAlign={"center"}>
                <Typography>
                  {
                    this.props.icon
                  }
                </Typography>
              </Box>
            )}
            <Box textAlign={"center"}>
              <Typography style={webStyle.title}>
                {
                  this.props.title
                }
              </Typography>
              <Box style={webStyle.description}>
                <div dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        {this.props.btnName &&
          <DialogActions style={{ marginBottom: "20px" }}>
            <Button
              onClick={this.props.onHandleClose}
              type="submit"
              style={webStyle.closebtn}
              size="large"
            >
              {this.props.btnName}
            </Button>
          </DialogActions>}
      </Dialog>



    );
  }
  render() {
    if (this.props.dialogType === 'VerifyModal') {
      return <this.SendVerifiedEmailModal />
    }
    if (this.props.dialogType === 'EmailVerifiedModal') {
      return <this.EmailVerifiedModal />
    }
    return <this.CommonDialog />

  }
}
const webStyle = {
  title: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "36px",
    color: "#323548",
    lineHeight: "30px"
  },
  description: {
    marginTop: "10px",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#323548",
    lineHeight: "24px"
  },
  closebtn: {
    background: "#3E01B9",
    borderRadius: "10px",
    width: "100%",
    height: "64px",
    color: "#E8E8E8",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
  },
  successIcon: {
    width: "121px",
    height: "115px",
    margin: "auto"
  },

}

const muiStyles = (theme: Theme) =>
  createStyles({
    screen: {
      display: "flex",
    },
    label: {
      fontFamily: 'Montserrat',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      color: "#323548"

    },
    required: {
      color: "red",
      marginTop: "10px"
    },
    input: {
      marginTop: '10px',
      width: "100%"
    },
    title: {
      fontSize: "36px",
      fontFamily: 'Montserrat',
      color: "#323548",
      fontWeight: 700
    },
    description: {
      color: "#323548",
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: "18px",
      marginTop: "24px"

    },
    dialogModalContainer: {
      background: "#E8E8E8",
      color: "#323548",
      fontFamily: "Montserrat",
      width: "670px",
      border: "none",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
      maxHeight: '568px'
    },
    crossIcon: {
      width: "40px",
      marginTop: "20px",
      marginRight: "20px",
      cursor: "pointer"
    },
    emailIcon: { width: "106px" },
    
    dialogMainContent: {
      marginBottom: "70px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: "0px 90px 0px 90px",
    },
    dialogModalTitle: {
      fontSize: "32px",
      fontWeight: 700,
    },
    dialogModalDescription: {
      fontSize: "20px",
      fontWeight: 400,
      marginTop: "20px",
      opacity: 0.5,
    },
    dialogEmailContainer: {
      display: "flex",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      flexShrink: 0,
      marginTop: "30px",
      borderRadius: "12px",
      background: "#F0F0F0",
    },
    maskedEmailAddress: {
      color: "#989AA3",
      textAlign: "right",
      fontSize: "20px",
      fontWeight: 400,
    },
    dialogModalFooter: {
      marginTop: "28px",
      fontSize: "18px",
      fontWeight: 400,
    },
    "@media (max-width: 900px)": {
      dialogModalContainer: {
        width: "70%",
      },
      dialogModalTitle: {
        fontSize: "28px",
        fontWeight: 650,
      },
      dialogModalDescription: {
        fontSize: "18px"
      },
      dialogModalFooter: {
        fontSize: "16px",
      },
    },

    "@media (max-width: 720px)": {
      title: {
        fontSize: "24px"
      },
      description: {
        fontSize: "16px"

      },
      label: {
        fontSize: "14px"

      },
      dialogModalContainer: {
        width: "70%",
      },
      dialogModalTitle: {
        fontSize: "24px",
        fontWeight: 600,
      },
      dialogModalDescription: {
        fontSize: "16px",
      },
      dialogMainContent: {
        padding: "0px 12px 0px 12px",
      },
      dialogEmailContainer: {
        padding: "2px",
        gap: "0.5px",
      },
      maskedEmailAddress: {
        fontSize: "14px",
      },
      dialogModalFooter: {
        fontSize: "14px",
      },
    },
    "@media (max-width: 400px)": {
      dialogModalContainer: {
        width: "100%",
        height: "100%",
        top: "40%",
        left: "0%",
        borderRadius: "20px 20px 0 0",
        transform: "none",
        maxHeight: '100%'
      },
    },
  });
export default withStyles(muiStyles)(Dialoge);

// Customizable Area End
